import { Component } from '@angular/core';
import { IResultadoGeneral } from './../models/resultadogeneral';
import { Router } from '@angular/router';
import { ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { ConfirmationService, MessageService } from 'primeng/api';
import { saveAs } from 'file-saver';
import ICredencialUsuario from '../models/credencial';
import { AuthService } from '../services/auth.service';
import { ICiudad } from '../models/ciudad';
import { InombreCompletoChofer } from '../models/nombreCompletoChofer';
import { IMes } from '../models/mes';
import { IrptEstatusForaneo } from '../models/rptEstatusForaneo';
import { ApoyoestatusforaneoService } from '../services/apoyoestatusforaneo.service';
import { IEstatusForaneo } from '../models/estatusforaneo';
import { Ibonoaguinaldo } from '../models/bonoaguinaldo';
import { BonoaguinaldoService } from '../services/bonoaguinaldo.service';

import { ICiudadPrincipalHM } from '../models/ciudadprincipal';

import autoTable from 'jspdf-autotable';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
@Component({
  selector: 'app-bonoaguinaldo',
  templateUrl: './bonoaguinaldo.component.html',
  styleUrls: ['./bonoaguinaldo.component.scss']
})
export class BonoaguinaldoComponent {

  @ViewChild('dt1') dt!: Table;
  lstCiudad: ICiudadPrincipalHM[] = [];
  lstChoferes: InombreCompletoChofer[] = [];
  lstchofCopy: InombreCompletoChofer[] = [];
  lstEstatusForaneo: IEstatusForaneo[] = [];
  lstBonoAguinaldo: Ibonoaguinaldo [] = [];


  mssgListaCiudades: string = '';


  lstrptDescansoFaltas: IrptEstatusForaneo[] = [];
  selectedCiudad: ICiudad = {
    id: 0,
    nombre: '',
    clave_Estado: '',
    id_ciudad_principal: 0
  };

  num_anio: number= 0;
  years: number[] = [];
  loading: boolean = false;
  //variables usadas para la fecha, fueron casteadas ya que presentaban errorees
  fecha_hoy: any = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
  dia: any = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
  fecha_filtrada: any = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
  fecha_dia: any = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
  fecha_fin: any = null;
  id_ciudad_hm: number = 0;
  id_ciudad_principal: number = 0;
  dspCliente: boolean = false;
  texto_operacion: string = '';
  num_operacion: number = 0;
  clv_operacion: number = 0;
  habilitar_agregar: boolean = false;


  //--------
  imp_aguinaldo_total : number = 0;
  imp_dias_extra_total : number = 0;
  imp_total_genral : number = 0;

  resultado: IResultadoGeneral = {
    clv_estatus: 0,
    messageDetails: ''
  };

  dsblstCiudades: boolean = true;
  dsblstColaboradores: boolean = true;
  dsblstCiudadesAdscritas: boolean = true;
  // variables de reportes--------------------------
  dbsReportes: boolean = false;
  fecha_fin_filtro: any = null;
  ButtonExportExcel: boolean = false;
  //Fecha de Dias Atras
  fecha_del_dia: any = null;
  btnHabilitar: boolean = false;
  mostrar_texto_fechas: number = 0;
  fecha_final_dia!: Date;
  //Fecha para restar 3 dias
  fecha_minus3 !: Date;
  fecha_dia_real: any = null;
  checkboxValue: boolean = false;
  checked: boolean = false;
  selectedMesReporte1: IMes = {
    num_mes: -1,
    nombre: ""
  }

  anioReporte1: any;
  loading_reporte1: boolean = false;
  meses: IMes[] = [
    { num_mes: 1, nombre: "Enero" },
    { num_mes: 2, nombre: "Febrero" },
    { num_mes: 3, nombre: "Marzo" },
    { num_mes: 4, nombre: "Abril" },
    { num_mes: 5, nombre: "Mayo" },
    { num_mes: 6, nombre: "Junio" },
    { num_mes: 7, nombre: "Julio" },
    { num_mes: 8, nombre: "Agosto" },
    { num_mes: 9, nombre: "Septiembre" },
    { num_mes: 10, nombre: "Octubre" },
    { num_mes: 11, nombre: "Noviembre" },
    { num_mes: 12, nombre: "Diciembre" }];


  //Permisos
  user: ICredencialUsuario = {};
  constructor(private router: Router,
    private apoyoestatusforaneoService: ApoyoestatusforaneoService,

    private bonoaguinaldoService: BonoaguinaldoService,
    private confirmationService: ConfirmationService, private messageService: MessageService,
    private auth: AuthService) {

    this.initializeYears();
    this.num_anio = new Date().getFullYear();
    this.num_anio_rpt = new Date().getFullYear();
  }

  ngOnInit(): void {
    this.cargaInicial();
  }


  initializeYears(): void {
    const currentYear = new Date().getFullYear();
    for (let year = currentYear; year >= currentYear - 5; year--) {
      this.years.push(year);
    }
  }


  // -----------filtra y carga la informacion ----




  // -----------filtra y carga la informacion ----
  FiltrarFechaDiariaRuta() {
    this.loading = true;
    if (this.id_ciudad_hm == null || typeof this.id_ciudad_hm === 'undefined') {
      this.mostrarDialogoInformativoSinInformacion('<b>Informativo</b> La fecha y Ciudad deben seleccionarse');
      this.loading = false;
    } else {
      this.bonoaguinaldoService.getListadoBonoAguinaldo(this.num_anio, this.id_ciudad_hm).subscribe(
        (resp) => {
          this.lstBonoAguinaldo = resp;
          if (this.lstBonoAguinaldo.length === 0) {
            this.loading = false;
            this.mostrarDialogoInformativoSinInformacion('<b>No Existe</b> Registros para la Fecha ' + this.fecha_filtrada + ' seleccionada');
          } else {
            // Reiniciar variables de sumatoria
            this.imp_aguinaldo_total = 0;
            this.imp_dias_extra_total = 0;
            this.imp_total_genral = 0;
            // Iterar sobre la lista y sumar los valores de los campos
            this.lstBonoAguinaldo.forEach((item) => {
              this.imp_aguinaldo_total += item.imp_aguinaldo;
              this.imp_dias_extra_total += item.imp_dias_extra;
              this.imp_total_genral += item.imp_total;
            });
            // Finalizar el loading después de 800 ms2
            setTimeout(() => {
              this.loading = false;
            }, 800);
          }
        },
        (error) => {
          this.mostrarDialogoInformativoSinInformacion('<b>ERROR</b> en Cargar Movimientos ');
          this.loading = false;
        }
      );
    }
  }




  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }



  // cargar lista inicial de las ciudades para los dropdown  get
  cargaInicial() {
    this.dsblstCiudades = true;
    this.loading = true;
    this.bonoaguinaldoService.getListadoCiudadesPrincipales().subscribe((resp) => {
      this.lstCiudad = resp;
      this.lstCiudad.push({ id: 0, nombre: 'TODAS LAS CIUDADES', clave_estado: ''})
      setTimeout(() => {
        this.loading = false;
        this.dsblstCiudades = false;
      }, 1000);
    },
      (error) => {
        this.loading = false;
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Ciudades');
      });

  }


  saveAsExcelFile(buffer: any, fileName: string): void {
    //var FileSaver = require('file-saver');
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName + "_exportar_" + dateFormat + EXCEL_EXTENSION);
  }



  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '. <b>Contacte al Administrador.</b>';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }


  // ----------------reportes  ------------
  abrirModalReportes() {
    this.dbsReportes = true;
    this.inicializarFechasFormateadas();
  }


  cancelarModalReportes() {
    this.dbsReportes = false;
    // Inicio Reporte 1
    this.selectedMesReporte1 = {
      num_mes: 0,
      nombre: ''
    }
    this.anioReporte1 = null;
    this.selectedCiudad = {
      id: -1,
      nombre: '',
      clave_Estado: '',
      id_ciudad_principal: 0
    };
    this.fecha_fin = null;

    // this.id_ciudad_principal = 0;
    // this.num_anio = new Date().getFullYear();
    this.num_anio_rpt = new Date().getFullYear();
    this.id_ciudad_principal_rpt = 0;
    this.loading_reporte1 = false;

  }

  inicializarFechasFormateadas() {
    //hacer globales
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    this.fecha_dia = dateFormat;
    this.fecha_del_dia = new Date(dateFormat + 'T00:00:00');
    //Fecha que no se modifica
    this.fecha_final_dia = new Date(dateFormat + 'T00:00:00');
    //Fecha para restar los 3 dias
    let minus3days = new Date(this.fecha_final_dia);
    this.fecha_minus3 = new Date(minus3days.setDate(new Date(minus3days).getDate() - 4));
    //Fecha del dia real, no se mueve
    this.fecha_dia_real = dateFormat;
    //Reportes
    let num_mes: number = +getMonth;
    this.meses.map((mes) => {
      if (mes.num_mes == num_mes) {
        this.selectedMesReporte1.nombre = mes.nombre;
        this.selectedMesReporte1.num_mes = mes.num_mes;
      }
    });
    this.anioReporte1 = getYear;
  }

  num_anio_rpt:number=0;
  id_ciudad_principal_rpt:number=0;
  // metodo para exportar a excel


  exportarExcel(num_anio_rpt: number, id_ciudad_principal_rpt : number ) {

    let ciudad = this.lstCiudad.find(x =>x.id === this.id_ciudad_principal )
    this.loading_reporte1 = true;
    if ( num_anio_rpt == null || id_ciudad_principal_rpt == null) {
      this.mostrarDialogoInformativo('<b>Aviso</b> Es necesario seleccionar <b>el año y ciudad </b> de Operación a exportar');
      this.loading_reporte1 = false;
    } else {
      this.bonoaguinaldoService.rptBonoAguinaldo(this.num_anio_rpt, this.id_ciudad_principal_rpt)
        .subscribe((resp: Ibonoaguinaldo[]) => {
          if (resp.length === 0 || typeof resp === 'undefined') {
            this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion del Catalogo para exportar(Excel)');
            this.loading_reporte1 = false;
          } else {
            import("xlsx").then(xlsx => {
              const worksheet = xlsx.utils.json_to_sheet(resp);
              const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
              const excelBuffer: any = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array"
              });

              this.saveAsExcelFile(excelBuffer, "ReporteAguinaldo" + '_' + num_anio_rpt+ '_' +ciudad?.nombre );
            });
            setTimeout(() => {
              this.ButtonExportExcel = false;
              this.loading_reporte1 = false;
            }, 1000);
          }
        },
          (error) => {
            this.ButtonExportExcel = false;
            this.loading_reporte1 = false;
            this.fecha_fin = null;
            this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: 'Error en emitir el reporte de Estatus de Facturación.' });
          });
    }
  }



  mostrarDialogoInformativo(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '.';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
        this.messageService.clear();
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
        this.messageService.clear();
      },
      key: "sinInformacionDialog"
    });
    this.loading = false;
    this.messageService.clear();
  }

//--------------------------------------------------genrar el pdf --------------------------------------------------------------------------------
generarPDF() {
  const doc = new jsPDF();

  // Carga y añade el logo
  const logo = new Image();
  logo.src = 'assets/images/logo_centrado.png';
  logo.onload = () => {
      doc.addImage(logo, 'PNG', 70, 5, 60, 25);

      // 160: Posición X de la esquina superior izquierda de la imagen en la página.
      // 10: Posición Y de la esquina superior izquierda de la imagen en la página.
      // 50: Ancho de la imagen en el documento.
      // 17: Alto de la imagen en el documento.

      autoTable(doc, {
        startY: 30, // Posición vertical inicial del encabezado
        margin: { left: 10 }, // Margen izquierdo del encabezado
        head: [['HAPPY BRIEF']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
        tableWidth: 190, // Ancho de la tabla del encabezado
        styles: {
            fontSize: 10, // Tamaño de letra del encabezado
            cellPadding: 2, // Espaciado interno de las celdas
            overflow: 'linebreak', // Manejo de desbordamiento de texto
        },
        headStyles: {
            fillColor: [156,194,229], // Color de fondo del encabezado
            textColor: [255, 255, 255], // Color de texto del encabezado
            fontStyle: 'bold', // Estilo de fuente del encabezado
            halign: 'center' // Alineación horizontal del contenido del encabezado
        }
      });



//---------------------------------------------1x1------------------------------tabla 1
//-----------inicia en 10
//-----------finaliza en 70
autoTable(doc, {
  startY: 45, // Posición vertical inicial del encabezado
  margin: { left: 10 }, // Margen izquierdo del encabezado
  head: [['Cliente']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 20, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 6, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [46,117,181], // Color de fondo del encabezado
      textColor: [255, 255, 255], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});

// Crear cuerpo de la tabla
autoTable(doc, {
  startY: 45, // Posición vertical inicial del cuerpo
  margin: { left: 30 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [['Cuerpo']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 35, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});

//----------------------------------------1x2---------------------------
autoTable(doc, {
  startY: 55, // Posición vertical inicial del encabezado
  margin: { left: 10 }, // Margen izquierdo del encabezado
  head: [['Proyecto']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 20, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 6, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [46,117,181], // Color de fondo del encabezado
      textColor: [255, 255, 255], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});

// Crear cuerpo de la tabla
autoTable(doc, {
  startY: 55, // Posición vertical inicial del cuerpo
  margin: { left: 30 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [['Cuerpo']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 35, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});

//----------------------------------------1x3---------------------------
autoTable(doc, {
  startY: 65, // Posición vertical inicial del encabezado
  margin: { left: 10 }, // Margen izquierdo del encabezado
  head: [['Corporativo']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 20, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 6, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [46,117,181], // Color de fondo del encabezado
      textColor: [255, 255, 255], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});

// Crear cuerpo de la tabla
autoTable(doc, {
  startY: 65, // Posición vertical inicial del cuerpo
  margin: { left: 30 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [['Cuerpo']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 35, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});


//----------------------------------------1x4---------------------------
autoTable(doc, {
  startY: 75, // Posición vertical inicial del encabezado
  margin: { left: 10 }, // Margen izquierdo del encabezado
  head: [['Ciudades']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 20, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 6, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [46,117,181], // Color de fondo del encabezado
      textColor: [255, 255, 255], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});

// Crear cuerpo de la tabla
autoTable(doc, {
  startY: 75, // Posición vertical inicial del cuerpo
  margin: { left: 30 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [['Cuerpo']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 35, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});


//---------------------22222222222222222222222222222222222222222222222222222222222222222222
//---------------------------------------------2x1------------------------------tabla 1
//-----------inicia en 10
//-----------finaliza en 70
autoTable(doc, {
  startY: 45, // Posición vertical inicial del encabezado
  margin: { left: 75 }, // Margen izquierdo del encabezado
  head: [['Venta Estimada']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 25, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 6, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [46,117,181], // Color de fondo del encabezado
      textColor: [255, 255, 255], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});

// Crear cuerpo de la tabla
autoTable(doc, {
  startY: 45, // Posición vertical inicial del cuerpo
  margin: { left: 100 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [['Cuerpo']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 30, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});
//----------------------------------------2x2---------------------------
autoTable(doc, {
  startY: 55, // Posición vertical inicial del encabezado
  margin: { left: 75 }, // Margen izquierdo del encabezado
  head: [['Fecha Inicio']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 25, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 6, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [46,117,181], // Color de fondo del encabezado
      textColor: [255, 255, 255], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});

// Crear cuerpo de la tabla
autoTable(doc, {
  startY: 55, // Posición vertical inicial del cuerpo
  margin: { left: 100 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [['Cuerpo']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 30, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});

//----------------------------------------2x3---------------------------
autoTable(doc, {
  startY: 65, // Posición vertical inicial del encabezado
  margin: { left: 75 }, // Margen izquierdo del encabezado
  head: [['Fecha Termino']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 25, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 6, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [46,117,181], // Color de fondo del encabezado
      textColor: [255, 255, 255], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});

// Crear cuerpo de la tabla
autoTable(doc, {
  startY: 65, // Posición vertical inicial del cuerpo
  margin: { left: 100 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [['Cuerpo']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 30, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});

//----------------------------------------2x4---------------------------

autoTable(doc, {
  startY: 75, // Posición vertical inicial del encabezado
  margin: { left: 75 }, // Margen izquierdo del encabezado
  head: [['Soporte Cobranza']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 55, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 6, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [46,117,181], // Color de fondo del encabezado
      textColor: [255, 255, 255], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});

// Crear cuerpo de la tabla
autoTable(doc, {
  startY: 75, // Posición vertical inicial del cuerpo
  margin: { left: 130 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [['Cuerpo']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 70, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});

//--------333333333333333333333333333333333333333333333333

//---------------------------------------------3x1------------------------------tabla 1
//-----------inicia en 10
//-----------finaliza en 70
autoTable(doc, {
  startY: 45, // Posición vertical inicial del encabezado
  margin: { left: 150 }, // Margen izquierdo del encabezado
  head: [['Fecha']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 20, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 6, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [46,117,181], // Color de fondo del encabezado
      textColor: [255, 255, 255], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});

// Crear cuerpo de la tabla
autoTable(doc, {
  startY: 45, // Posición vertical inicial del cuerpo
  margin: { left: 170 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [['Cuerpo']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 30, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});
//----------------------------------------3x2---------------------------
autoTable(doc, {
  startY: 55, // Posición vertical inicial del encabezado
  margin: { left: 140 }, // Margen izquierdo del encabezado
  head: [['Periodo de Facturacion']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 30, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 6, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [46,117,181], // Color de fondo del encabezado
      textColor: [255, 255, 255], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});

// Crear cuerpo de la tabla
autoTable(doc, {
  startY: 55, // Posición vertical inicial del cuerpo
  margin: { left: 170 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [['Cuerpo']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 30, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});

//----------------------------------------3x3---------------------------
autoTable(doc, {
  startY: 65, // Posición vertical inicial del encabezado
  margin: { left: 140 }, // Margen izquierdo del encabezado
  head: [['Dias de Credito']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 30, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 6, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [46,117,181], // Color de fondo del encabezado
      textColor: [255, 255, 255], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});

// Crear cuerpo de la tabla
autoTable(doc, {
  startY: 65, // Posición vertical inicial del cuerpo
  margin: { left: 170 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [['Cuerpo']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 30, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});

//aqui termina la primera matriz matriz sw 1x2


autoTable(doc, {
  startY: 85, // Posición vertical inicial del encabezado
  margin: { left: 10 }, // Margen izquierdo del encabezado
  head: [['Contacto de abastecimiento']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 40, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 6, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [46,117,181], // Color de fondo del encabezado
      textColor: [255, 255, 255], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});

// Crear cuerpo de la tabla
autoTable(doc, {
  startY: 85, // Posición vertical inicial del cuerpo
  margin: { left: 50 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [['Cuerpo']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 50, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});


//----------------------------1x2
autoTable(doc, {
  startY: 85, // Posición vertical inicial del encabezado
  margin: { left: 130}, // Margen izquierdo del encabezado
  head: [['Correo']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 25, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 6, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [46,117,181], // Color de fondo del encabezado
      textColor: [255, 255, 255], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});

// Crear cuerpo de la tabla
autoTable(doc, {
  startY: 85, // Posición vertical inicial del cuerpo
  margin: { left: 155 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [['Cuerpo']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 45, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});
//---------------------------------
autoTable(doc, {
  startY: 95, // Posición vertical inicial del encabezado
  margin: { left: 10 }, // Margen izquierdo del encabezado
  head: [['Caracteristicas del proyecto']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 40, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 6, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [46,117,181], // Color de fondo del encabezado
      textColor: [255, 255, 255], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});

// Crear cuerpo de la tabla
autoTable(doc, {
  startY: 95, // Posición vertical inicial del cuerpo
  margin: { left: 50 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [['Cuerpo']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 150, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});


// ----------------------------------------- matrices de 3 campossssssssssssssssssssssssss
//-------primera fila

autoTable(doc, {
  startY: 105, // Posición vertical inicial del encabezado
  margin: { left: 10 }, // Margen izquierdo del encabezado
  head: [['Tipo de servicio']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 20, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 9, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [46,117,181], // Color de fondo del encabezado
      textColor: [255, 255, 255], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});



autoTable(doc, {
  startY: 105, // Posición vertical inicial del encabezado
  margin: { left: 30 }, // Margen izquierdo del encabezado
  head: [['PM']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 20, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 5, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [222,234,246], // Color de fondo del encabezado
      textColor: [0, 0, 0], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});

autoTable(doc, {
  startY: 110, // Posición vertical inicial del cuerpo
  margin: { left: 30 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [['Cuerpo']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 20, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 5, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});

//-----------------segunda tabla de la primera fila---------
autoTable(doc, {
  startY: 120, // Posición vertical inicial del encabezado
  margin: { left: 10 }, // Margen izquierdo del encabezado
  head: [['Configuracion']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 20, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 9, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [46,117,181], // Color de fondo del encabezado
      textColor: [255, 255, 255], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});

// Crear cuerpo de la tabla

autoTable(doc, {
  startY: 120, // Posición vertical inicial del encabezado
  margin: { left: 30 }, // Margen izquierdo del encabezado
  head: [['LV']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 20, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 5, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [222,234,246], // Color de fondo del encabezado
      textColor: [0, 0, 0], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});

autoTable(doc, {
  startY: 125, // Posición vertical inicial del cuerpo
  margin: { left: 30 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [['Cuerpo']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 20, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 5, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'center' // Alineación horizontal del contenido del cuerpo
  }
});

//---------------------------segunda columna
//------------------------priemra taabla
autoTable(doc, {
  startY: 105, // Posición vertical inicial del encabezado
  margin: { left: 60 }, // Margen izquierdo del encabezado
  head: [['MEDIA MILLA']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 20, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 5, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [222,234,246], // Color de fondo del encabezado
      textColor: [0, 0, 0], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});


autoTable(doc, {
  startY: 110, // Posición vertical inicial del cuerpo
  margin: { left: 60 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [['Cuerpo']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 20, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 5, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'center' // Alineación horizontal del contenido del cuerpo
  }
});

//-------------- segunda tabla ------------

autoTable(doc, {
  startY: 120, // Posición vertical inicial del encabezado
  margin: { left: 60 }, // Margen izquierdo del encabezado
  head: [['3.5']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 20, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 5, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [222,234,246], // Color de fondo del encabezado
      textColor: [0, 0, 0], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});


autoTable(doc, {
  startY: 125, // Posición vertical inicial del cuerpo
  margin: { left: 60 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [['Cuerpo']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 20, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 5, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'center' // Alineación horizontal del contenido del cuerpo
  }
});


//-------tercer columna
//-----primer tabla

autoTable(doc, {
  startY: 105, // Posición vertical inicial del encabezado
  margin: { left: 90 }, // Margen izquierdo del encabezado
  head: [['LH']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 20, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 5, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [222,234,246], // Color de fondo del encabezado
      textColor: [0, 0, 0], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});


autoTable(doc, {
  startY: 110, // Posición vertical inicial del cuerpo
  margin: { left: 90 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [['Cuerpo']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 20, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 5, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'center' // Alineación horizontal del contenido del cuerpo
  }
});

//-------------- segunda tabla ------------
//----tercer columna
autoTable(doc, {
  startY: 120, // Posición vertical inicial del encabezado
  margin: { left: 90 }, // Margen izquierdo del encabezado
  head: [['RABON']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 20, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 5, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [222,234,246], // Color de fondo del encabezado
      textColor: [0, 0, 0], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});


autoTable(doc, {
  startY: 125, // Posición vertical inicial del cuerpo
  margin: { left: 90 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [['Cuerpo']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 20, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 5, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'center' // Alineación horizontal del contenido del cuerpo
  }
});


//-------CUARTA COLUMNA

autoTable(doc, {
  startY: 120, // Posición vertical inicial del encabezado
  margin: { left: 120 }, // Margen izquierdo del encabezado
  head: [['TH']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 20, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 5, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [222,234,246], // Color de fondo del encabezado
      textColor: [0, 0, 0], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});


autoTable(doc, {
  startY: 125, // Posición vertical inicial del cuerpo
  margin: { left: 120 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [['Cuerpo']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 20, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 5, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'center' // Alineación horizontal del contenido del cuerpo
  }
});

//------------quinta columna
//-------CUARTA COLUMNA

autoTable(doc, {
  startY: 120, // Posición vertical inicial del encabezado
  margin: { left: 150 }, // Margen izquierdo del encabezado
  head: [['S3FT']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 20, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 5, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [222,234,246], // Color de fondo del encabezado
      textColor: [0, 0, 0], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});


autoTable(doc, {
  startY: 125, // Posición vertical inicial del cuerpo
  margin: { left: 150 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [['S3FT']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 20, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 5, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'center' // Alineación horizontal del contenido del cuerpo
  }
});

//-------------fila 9
autoTable(doc, {
  startY: 135, // Posición vertical inicial del encabezado
  margin: { left: 10}, // Margen izquierdo del encabezado
  head: [['Indicadores de desempeño']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 40, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 6, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [46,117,181], // Color de fondo del encabezado
      textColor: [255, 255, 255], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});

// Crear cuerpo de la tabla
autoTable(doc, {
  startY: 135, // Posición vertical inicial del cuerpo
  margin: { left: 50 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [['Cuerpo']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 150, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});


//-----------------------------fila 10

autoTable(doc, {
  startY: 145, // Posición vertical inicial del encabezado
  margin: { left: 10}, // Margen izquierdo del encabezado
  head: [['Penalizacion Relacionada']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 40, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 6, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [46,117,181], // Color de fondo del encabezado
      textColor: [255, 255, 255], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});

// Crear cuerpo de la tabla
autoTable(doc, {
  startY: 145, // Posición vertical inicial del cuerpo
  margin: { left: 50 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [['Cuerpo']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 150, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});

//-----------------------------------fila 11

autoTable(doc, {
  startY: 155, // Posición vertical inicial del encabezado
  margin: { left: 10 }, // Margen izquierdo del encabezado
  head: [['comercial']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 20, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 6, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [46,117,181], // Color de fondo del encabezado
      textColor: [255, 255, 255], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});

// Crear cuerpo de la tabla
autoTable(doc, {
  startY: 155, // Posición vertical inicial del cuerpo
  margin: { left: 30 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [['Cuerpo']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 20, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});

//-----------------fila 12
autoTable(doc, {
  startY: 165, // Posición vertical inicial del encabezado
  margin: { left: 10 }, // Margen izquierdo del encabezado
  head: [['Aplicacion']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 20, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 6, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [46,117,181], // Color de fondo del encabezado
      textColor: [255, 255, 255], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});


autoTable(doc, {
  startY: 165, // Posición vertical inicial del cuerpo
  margin: { left: 30 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [['Cuerpo']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 20, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});


//----------------fila 12 columna 2-------
autoTable(doc, {
  startY: 155, // Posición vertical inicial del encabezado
  margin: { left: 75 }, // Margen izquierdo del encabezado
  head: [['Portal operativo']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 25, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 6, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [46,117,181], // Color de fondo del encabezado
      textColor: [255, 255, 255], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});

// Crear cuerpo de la tabla
autoTable(doc, {
  startY: 155, // Posición vertical inicial del cuerpo
  margin: { left: 100 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [['Cuerpo']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 30, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});


//-------------------------

autoTable(doc, {
  startY: 165, // Posición vertical inicial del encabezado
  margin: { left: 60 }, // Margen izquierdo del encabezado
  head: [['area geografica']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 25, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 6, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [46,117,181], // Color de fondo del encabezado
      textColor: [255, 255, 255], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});

autoTable(doc, {
  startY: 165, // Posición vertical inicial del encabezado
  margin: { left: 85 }, // Margen izquierdo del encabezado
  head: [['local']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 20, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 6, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [222,234,246], // Color de fondo del encabezado
      textColor: [0, 0, 0], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});

autoTable(doc, {
  startY: 165, // Posición vertical inicial del cuerpo
  margin: { left: 105 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [['Cuerpo']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 30, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});

//----------------
autoTable(doc, {
  startY: 155, // Posición vertical inicial del encabezado
  margin: { left: 140 }, // Margen izquierdo del encabezado
  head: [['manual de eoperaciones']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 40, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 6, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [46,117,181], // Color de fondo del encabezado
      textColor: [255, 255, 255], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});

// Crear cuerpo de la tabla
autoTable(doc, {
  startY: 155, // Posición vertical inicial del cuerpo
  margin: { left: 180 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [['Cuerpo']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 20, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});

//-----------------------


autoTable(doc, {
  startY: 165, // Posición vertical inicial del encabezado
  margin: { left: 150 }, // Margen izquierdo del encabezado
  head: [['Foranea']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 20, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 6, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [222,234,246], // Color de fondo del encabezado
      textColor: [0, 0, 0], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});

autoTable(doc, {
  startY: 165, // Posición vertical inicial del cuerpo
  margin: { left: 170 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [['Cuerpo']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 30, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});




//------------------------lista--------de ciudades --------------
//                                   CIUDADES

autoTable(doc, {
  startY: 185, // Posición vertical inicial del encabezado
  margin: { left: 10 }, // Margen izquierdo del encabezado
  head: [['Ciudad']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 20, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 8, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [46,117,181], // Color de fondo del encabezado
      textColor: [255, 255, 255], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});

//--------la paz

autoTable(doc, {
  startY: 195, // Posición vertical inicial del encabezado
  margin: { left: 10 }, // Margen izquierdo del encabezado
  head: [['La paz']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 20, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 6, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [46,117,181], // Color de fondo del encabezado
      textColor: [255, 255, 255], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});


autoTable(doc, {
  startY: 201, // Posición vertical inicial del encabezado
  margin: { left: 10 }, // Margen izquierdo del encabezado
  head: [['Culiacan']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 20, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 6, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [46,117,181], // Color de fondo del encabezado
      textColor: [255, 255, 255], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});

autoTable(doc, {
  startY: 207, // Posición vertical inicial del encabezado
  margin: { left: 10 }, // Margen izquierdo del encabezado
  head: [['Monterrey']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 20, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 6, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [46,117,181], // Color de fondo del encabezado
      textColor: [255, 255, 255], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});

autoTable(doc, {
  startY: 213, // Posición vertical inicial del encabezado
  margin: { left: 10 }, // Margen izquierdo del encabezado
  head: [['Monterrey']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 20, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 6, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [46,117,181], // Color de fondo del encabezado
      textColor: [255, 255, 255], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});


autoTable(doc, {
  startY: 219, // Posición vertical inicial del encabezado
  margin: { left: 10 }, // Margen izquierdo del encabezado
  head: [['CDMC local']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 20, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 6, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [46,117,181], // Color de fondo del encabezado
      textColor: [255, 255, 255], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});



autoTable(doc, {
  startY: 225, // Posición vertical inicial del encabezado
  margin: { left: 10 }, // Margen izquierdo del encabezado
  head: [['CDMC foraneo']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 20, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 6, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [46,117,181], // Color de fondo del encabezado
      textColor: [255, 255, 255], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});



//--------------------------- FINALIZA COLUMNA DE CIUDADES



//-------------encabezado de la tabla
//-------------LV ENCABEZADO

autoTable(doc, {
  startY: 185, // Posición vertical inicial del encabezado
  margin: { left: 35 }, // Margen izquierdo del encabezado
  head: [['LV']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 18, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 8, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [46,117,181], // Color de fondo del encabezado
      textColor: [255, 255, 255], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});


//--------------------FILAS VACIAS PARA LV
//--------fila vacia LV x1
autoTable(doc, {
  startY: 195, // Posición vertical inicial del cuerpo
  margin: { left: 35 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' 1x1']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 18, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});



//--------fila vacia LV x2
autoTable(doc, {
  startY: 201, // Posición vertical inicial del cuerpo
  margin: { left: 35 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' 1x2']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 18, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});

//--------fila vacia LV x3
autoTable(doc, {
  startY: 207, // Posición vertical inicial del cuerpo
  margin: { left: 35 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' 1x3']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 18, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});

//--------fila vacia LV x4

autoTable(doc, {
  startY: 213, // Posición vertical inicial del cuerpo
  margin: { left: 35 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' 1x4']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 18, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});
//--------fila vacia LV x5
autoTable(doc, {
  startY: 219, // Posición vertical inicial del cuerpo
  margin: { left: 35 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' 1x5']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 18, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});

//--------fila vacia LV x6

autoTable(doc, {
  startY: 225, // Posición vertical inicial del cuerpo
  margin: { left: 35 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' 1x6']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 18, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});

//-----finaliza LV

//           --               3.5
autoTable(doc, {
  startY: 185, // Posición vertical inicial del encabezado
  margin: { left: 53 }, // Margen izquierdo del encabezado
  head: [['3.5']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 18, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 8, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [46,117,181], // Color de fondo del encabezado
      textColor: [255, 255, 255], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});

//--------campos vacios de 3.5
//--------fila vacia 3.5 x1
autoTable(doc, {
  startY: 195, // Posición vertical inicial del cuerpo
  margin: { left: 53 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' 2x1']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 18, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});

//--------fila vacia 3.5 x2
autoTable(doc, {
  startY: 201, // Posición vertical inicial del cuerpo
  margin: { left: 53 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' 2x2']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 18, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});

//--------fila vacia  3.5 x3
autoTable(doc, {
  startY: 207, // Posición vertical inicial del cuerpo
  margin: { left: 53 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' 2x3']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 18, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});
//--------fila vacia 3-5 x4
autoTable(doc, {
  startY: 213, // Posición vertical inicial del cuerpo
  margin: { left: 53 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' 2x4']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 18, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});
//--------fila vacia  3.5 x5
autoTable(doc, {
  startY: 219, // Posición vertical inicial del cuerpo
  margin: { left: 53 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' 2x5']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 18, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});
//--------fila vacia 3.5 x6
autoTable(doc, {
  startY: 225, // Posición vertical inicial del cuerpo
  margin: { left: 53 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' 2x6']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 18, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});

//---finaliza 3.5

//----           --------------RABON
autoTable(doc, {
  startY: 185, // Posición vertical inicial del encabezado
  margin: { left: 71 }, // Margen izquierdo del encabezado
  head: [['RABON']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 18, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 8, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [46,117,181], // Color de fondo del encabezado
      textColor: [255, 255, 255], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});

//campos vacios para RABON

//--------fila vacia RABON x1
autoTable(doc, {
  startY: 195, // Posición vertical inicial del cuerpo
  margin: { left: 71 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' 3x1']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 18, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});
//--------fila vacia RABON x2
autoTable(doc, {
  startY: 201, // Posición vertical inicial del cuerpo
  margin: { left: 71 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' 3x2']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 18, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});
//--------fila vacia RABON x3
autoTable(doc, {
  startY: 207, // Posición vertical inicial del cuerpo
  margin: { left: 71 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' 3x3']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 18, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});
//--------fila vacia RABON x4
autoTable(doc, {
  startY: 213, // Posición vertical inicial del cuerpo
  margin: { left: 71 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' 3x4']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 18, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});
//--------fila vacia RABON x5
autoTable(doc, {
  startY: 219, // Posición vertical inicial del cuerpo
  margin: { left: 71 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' 3x5']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 18, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});
//--------fila vacia RABON x6
autoTable(doc, {
  startY: 225, // Posición vertical inicial del cuerpo
  margin: { left: 71 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' 3x6']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 18, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});

//--------Finaliza RABON


//-----------------------TH

autoTable(doc, {
  startY: 185, // Posición vertical inicial del encabezado
  margin: { left: 89 }, // Margen izquierdo del encabezado
  head: [['TH']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 18, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 8, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [46,117,181], // Color de fondo del encabezado
      textColor: [255, 255, 255], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});

//----filas vacias para TH----
//--------fila vacia TH x1
autoTable(doc, {
  startY: 195, // Posición vertical inicial del cuerpo
  margin: { left: 89 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' 4x1']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 18, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});
//--------fila vacia TH x2
autoTable(doc, {
  startY: 201, // Posición vertical inicial del cuerpo
  margin: { left: 89 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' 4x2']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 18, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});
//--------fila vacia TH x3
autoTable(doc, {
  startY: 207, // Posición vertical inicial del cuerpo
  margin: { left: 89 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' 4x3']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 18, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});
//--------fila vacia TH x4
autoTable(doc, {
  startY: 213, // Posición vertical inicial del cuerpo
  margin: { left: 89 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' 4x4']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 18, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});
//--------fila vacia TH x5
autoTable(doc, {
  startY: 219, // Posición vertical inicial del cuerpo
  margin: { left: 89 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' 4x5']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 18, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});
//--------fila vacia TH x6
autoTable(doc, {
  startY: 225, // Posición vertical inicial del cuerpo
  margin: { left: 89 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' 4x6']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 18, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});

//----------------------------------fin de TH

//------------------------S3FT
autoTable(doc, {
  startY: 185, // Posición vertical inicial del encabezado
  margin: { left: 107 }, // Margen izquierdo del encabezado
  head: [['S3FH']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 18, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 8, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [46,117,181], // Color de fondo del encabezado
      textColor: [255, 255, 255], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});

//--------fila vacia S3FH x1
autoTable(doc, {
  startY: 195, // Posición vertical inicial del cuerpo
  margin: { left: 107 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' 5x1']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 18, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});
//--------fila vacia S3FH x2
autoTable(doc, {
  startY: 201, // Posición vertical inicial del cuerpo
  margin: { left: 107 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' 5x2']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 18, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});
//--------fila vacia S3FH x3
autoTable(doc, {
  startY: 207, // Posición vertical inicial del cuerpo
  margin: { left: 107 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' 5x3']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 18, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});
//--------fila vacia S3FH x4
autoTable(doc, {
  startY: 213, // Posición vertical inicial del cuerpo
  margin: { left: 107 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' 5x4']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 18, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});
//--------fila vacia S3FH x5
autoTable(doc, {
  startY: 219, // Posición vertical inicial del cuerpo
  margin: { left: 107 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' 5x5']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 18, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});
//--------fila vacia S3FH x6
autoTable(doc, {
  startY: 225, // Posición vertical inicial del cuerpo
  margin: { left: 107 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' 5x6']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 18, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});
//------fin de S3FH



//----------------------------------------precio
autoTable(doc, {
  startY: 185, // Posición vertical inicial del encabezado
  margin: { left: 128 }, // Margen izquierdo del encabezado
  head: [['PRECIO']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 20, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 8, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [46,117,181], // Color de fondo del encabezado
      textColor: [255, 255, 255], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});
//------campos vacios para precio

//--------fila vacia precio x1
autoTable(doc, {
  startY: 195, // Posición vertical inicial del cuerpo
  margin: { left: 128 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' precio x1']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 20, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});
//--------fila vacia precio x2
autoTable(doc, {
  startY: 201, // Posición vertical inicial del cuerpo
  margin: { left: 128 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' precio x2']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 20, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});
//--------fila vacia precio x3
autoTable(doc, {
  startY: 207, // Posición vertical inicial del cuerpo
  margin: { left: 128 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' precio x3']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 20, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});
//--------fila vacia precio x4
autoTable(doc, {
  startY: 213, // Posición vertical inicial del cuerpo
  margin: { left: 128 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' precio x4']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 20, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});
//--------fila vacia precio x5
autoTable(doc, {
  startY: 219, // Posición vertical inicial del cuerpo
  margin: { left: 128 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' precio x5']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 20, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});
//--------fila vacia precio x6
autoTable(doc, {
  startY: 225, // Posición vertical inicial del cuerpo
  margin: { left: 128 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' precio x6']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 20, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});

// ----------------fin de precio


//-----------USUARIO
autoTable(doc, {
  startY: 185, // Posición vertical inicial del encabezado
  margin: { left: 151 }, // Margen izquierdo del encabezado
  head: [['USUARIO']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 24, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 8, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [46,117,181], // Color de fondo del encabezado
      textColor: [255, 255, 255], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});

//--------fila vacia USUARIO x1
autoTable(doc, {
  startY: 195, // Posición vertical inicial del cuerpo
  margin: { left: 151 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' USUARIO x1']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 24, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});
//--------fila vacia USUARIO x2
autoTable(doc, {
  startY: 201, // Posición vertical inicial del cuerpo
  margin: { left: 151 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' USUARIO x2']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 24, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});
//--------fila vacia USUARIO x3
autoTable(doc, {
  startY: 207, // Posición vertical inicial del cuerpo
  margin: { left: 151 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' USUARIO x3']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 24, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});
//--------fila vacia USUARIO x4
autoTable(doc, {
  startY: 213, // Posición vertical inicial del cuerpo
  margin: { left: 151 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' USUARIO x4']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 24, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});
//--------fila vacia USUARIO x5
autoTable(doc, {
  startY: 219, // Posición vertical inicial del cuerpo
  margin: { left: 151 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' USUARIO x5']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 24, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});
//--------fila vacia USUARIO x6
autoTable(doc, {
  startY: 225, // Posición vertical inicial del cuerpo
  margin: { left: 151 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' USUARIO x6']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 24, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});
//-------------fin de usuarios --

//-------------------------------inicio de celular
autoTable(doc, {
  startY: 185, // Posición vertical inicial del encabezado
  margin: { left: 178 }, // Margen izquierdo del encabezado
  head: [['CELULAR']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 22, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 8, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [46,117,181], // Color de fondo del encabezado
      textColor: [255, 255, 255], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});


//--------fila vacia CELULAR x1
autoTable(doc, {
  startY: 195, // Posición vertical inicial del cuerpo
  margin: { left: 178 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' CELULAR x1']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 22, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});
//--------fila vacia CELULAR x2
autoTable(doc, {
  startY: 201, // Posición vertical inicial del cuerpo
  margin: { left: 178 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' CELULAR x2']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 22, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});
//--------fila vacia CELULAR x3
autoTable(doc, {
  startY: 207, // Posición vertical inicial del cuerpo
  margin: { left: 178 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' CELULAR x3']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 22, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});
//--------fila vacia CELULAR x4
autoTable(doc, {
  startY: 213, // Posición vertical inicial del cuerpo
  margin: { left: 178 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' CELULAR x4']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 22, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});
//--------fila vacia CELULAR x5
autoTable(doc, {
  startY: 219, // Posición vertical inicial del cuerpo
  margin: { left: 178 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' CELULAR x5']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 22, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});
//--------fila vacia CELULAR x6
autoTable(doc, {
  startY: 225, // Posición vertical inicial del cuerpo
  margin: { left: 178 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' CELULAR x6']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 22, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 6, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'left' // Alineación horizontal del contenido del cuerpo
  }
});


//-----------cometarios generales

autoTable(doc, {
  startY: 240, // Posición vertical inicial del encabezado
  margin: { left: 10 }, // Margen izquierdo del encabezado
  head: [['COMENTARIOS GENERALES AL PROYECTO']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 190, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 5, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [46,117,181], // Color de fondo del encabezado
      textColor: [255, 255, 255], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});


//-----tabla vacia
autoTable(doc, {
  startY: 245, // Posición vertical inicial del cuerpo
  margin: { left: 10 }, // Margen izquierdo del cuerpo (ajusta el espacio entre el encabezado y el cuerpo)
  body: [[' ...']], // Contenido del cuerpo (puedes cambiarlo según tus necesidades)
  tableWidth: 190, // Ancho de la tabla del cuerpo
  styles: {
      fontSize: 20, // Tamaño de letra del cuerpo
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  bodyStyles: {
      fillColor: [255, 255, 255], // Color de fondo del cuerpo
      textColor: [0, 0, 0], // Color de texto del cuerpo
      halign: 'center' // Alineación horizontal del contenido del cuerpo
  }
});


autoTable(doc, {
  startY: 265, // Posición vertical inicial del encabezado
  margin: { left: 10 }, // Margen izquierdo del encabezado
  head: [['IMAGEN DEL PLAN DE TRABAJO']], // Contenido del encabezado (puedes cambiarlo según tus necesidades)
  tableWidth: 190, // Ancho de la tabla del encabezado
  styles: {
      fontSize: 10, // Tamaño de letra del encabezado
      cellPadding: 2, // Espaciado interno de las celdas
      overflow: 'linebreak', // Manejo de desbordamiento de texto
  },
  headStyles: {
      fillColor: [46,117,181], // Color de fondo del encabezado
      textColor: [255, 255, 255], // Color de texto del encabezado
      fontStyle: 'bold', // Estilo de fuente del encabezado
      halign: 'center' // Alineación horizontal del contenido del encabezado
  }
});


      // Guarda el documento
      doc.save('reporte_personalizado.pdf');
  };
}



}

