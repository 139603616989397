<p-toast position="top-right" key="msj"></p-toast>
<!--<p-messages></p-messages>-->
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="Aceptar" rejectLabel="Cancelar"
  rejectButtonStyleClass="p-button-text">
</p-confirmDialog>

<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
  rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar" [rejectVisible]="false">
</p-confirmDialog>
<div class="d-flex justify-content-center">
  <div class="container-fluid" style="width: 95%;">
      <div class="row mt-5">
          <div class="col-md-12">
              <div class="card card-shadow sinborde">
                  <div class="card-header sinborde" style="background: none; ">
                      <div class="cardicono rounded">
              <i class="pi pi-id-card mt-3" style="font-size: 2rem; color: #fff;"></i>
            </div>
            <header style="left: 11%; top: 5%;">
              <div style="display:inline"> Gestion de</div>

              <div *ngIf="dia.length > 0" style="color:rgb(22, 48, 220);display:inline;" class="ml-3">Aguinaldo </div>
              <!-- - {{dia|date:'dd/MM/yyyy'}}
                 -->
            </header>
            <div class="row d-flex justify-content-end">
              <div class="col-md-12">

                <!-- -------------fecha del filtro---------------- -->
                <div class="form-group row mt-5">

                  <div class="col-md-3 ml-4">
                    <div class="row d-flex justify-content-start">
                      <div class="col-md-6">
                        <label class="col-form-label color-font text-right"
                          style="font-weight: 600; font-family: 'Poppins', sans-serif;">
                          <span class="input-group-addon ml-5"> <i class="pi pi-calendar color-icon mr-4"
                              style="font-size: 2rem; color: #3B82F6;"></i>
                          </span>
                          Año
                        </label>
                      </div>
                      <div class="col-sm-6 mt-3">
                        <select class="form-control" [(ngModel)]="num_anio">
                          <option *ngFor="let year of years" [value]="year">{{ year }}</option>
                        </select>

                      </div>
                    </div>
                  </div>


                  <!-- filtro de ciudades -->
                  <div class="col-md-3 mr-3 mt-2">
                    <p-dropdown [(ngModel)]="id_ciudad_hm" [options]="lstCiudad" optionLabel="nombre" optionValue="id"
                      placeholder="Filtrar por Ciudad" [showClear]="true" [disabled]="dsblstCiudades">
                    </p-dropdown>
                  </div>

                  <!-- ----------boton principal para el filtro  inicial------------------- -->
                  <div class="col-md-1 ">
                    <button pButton pRipple type="button" (click)="FiltrarFechaDiariaRuta();" label="Filtrar"
                      icon="pi pi-filter" iconPos="left"></button>
                  </div>
                  <!-- ----------boton principal de los reportes en excel------------------- -->
                  <div class="  col-md-1 ml-6 ">
                    <button type="button" pButton pRipple class="p-button-success " pTooltip="Exportar Catalogo a EXCEL"
                      tooltipPosition="right" (click)="abrirModalReportes()">
                      <i class="pi pi-file-excel"></i> Exportar
                    </button>
                  </div>

                  <!-- <div class="col-md-2">
                    <button (click)="generarPDF()" class="pdf-button">
                      <i class="pi pi-file-export"></i>
                      Imprimir PDF
                    </button>
                  </div> -->
                </div>

              </div>
            </div>
            <div class="card-content">
              <div class="row mt-3">
                <!-- informacio  -->
                <p-table #dt1 [value]="lstBonoAguinaldo" [tableStyle]="{'min-width': '50rem'}"
                  styleClass="p-datatable-sm p-datatable-striped" [scrollable]="true" scrollHeight="480px" scrollDirection="horizontal"
                responsiveLayout="scroll"
                  [globalFilterFields]="['nom_completo','desc_puesto','desc_ciudad_hm','fec_ingreso','desc_tipo_transporte']"
                  [paginator]="true" [rows]="30" [rowsPerPageOptions]="[10,15,20,30,50]" [showCurrentPageReport]="true"
                  [resizableColumns]="false" currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros"
                  [loading]="loading">
                  <ng-template pTemplate="caption">
                    <div class="row d-flex justify-content-end">

                      <div class="col-md-auto">
                        <span class="p-input-icon-left">
                          <i class="pi pi-search" style="margin-right: 5px;"></i>
                          <input pInputText pTooltip="Buscar por: Nombre del colaborador, Ciudad o Evento" type="text"
                            (input)="applyFilterGlobal($event, 'contains')" placeholder="Buscar"
                            style="margin-left: 5px;" />
                        </span>
                      </div>
                    </div>

                  </ng-template>

                  <!-- tabulado -->
                  <ng-template pTemplate="header">
                    <tr [ngClass]="{'texto_sm':true}">
                      <th style="width: 200px; min-width: 200px;"  pFrozenColumn pSortableColumn="nom_completo" class="text-center">Colaborador<p-sortIcon field="nom_completo"></p-sortIcon></th>
                      <th style="width: 90px; min-width: 90px;"    pFrozenColumn class="text-center">Ciudad</th>

                      <th style="width: 90px; min-width: 90px;"    pFrozenColumn pSortableColumn="desc_puesto" class="text-center">Puesto<p-sortIcon field="desc_puesto"></p-sortIcon></th>
                      <th style="width: 110px; min-width: 100px;"  pFrozenColumn  pSortableColumn="desc_tipo_transporte" class="text-center">Transporte<p-sortIcon field="desc_tipo_transporte"></p-sortIcon></th>

                      <th style="width: 90px; min-width: 90px;"  class="text-center">SueldoDiario</th>
                      <th style="width: 90px; min-width: 90px;" pSortableColumn="fec_ingreso" class="text-center">Ingreso<p-sortIcon field="fec_ingreso"></p-sortIcon></th>
                      <th style="width: 90px; min-width: 90px;"  class="text-center">Díaslaborados</th>
                      <th style="width: 90px; min-width: 90px;" class="text-center">DíasAguinaldo</th>
                      <th style="width: 90px; min-width: 90px;" class="text-center">DíasPagar</th>
                      <th style="width: 90px; min-width: 90px;"  pSortableColumn="num_dias_pagar" class="text-center">ImpAguinaldo<p-sortIcon field="num_dias_pagar"></p-sortIcon></th>
                      <th style="width: 60px; min-width: 60px;"  class="text-center">Año</th>
                      <th style="width: 90px; min-width: 90px;"  class="text-center">DíasExtAguinaldo</th>
                      <th style="width: 90px; min-width: 90px;" pSortableColumn="dias_a_pagar_extra" class="text-center">DíasExtPagar<p-sortIcon field="dias_a_pagar_extra"></p-sortIcon></th>
                      <th style="width: 90px; min-width: 90px;" pSortableColumn="importe_dias_extras" class="text-center">ImpDíasExt<p-sortIcon field="importe_dias_extras"></p-sortIcon></th>
                      <th style="width: 90px; min-width: 90px;" pSortableColumn="total_a_pagar" class="text-center">Total<p-sortIcon field="total_a_pagar"></p-sortIcon></th>
                    </tr>

                  </ng-template>
                  <ng-template pTemplate="body" let-data let-index>
                    <tr [ngClass]="{'row-accessories': data.clv_activo ===0,'texto_sm':true}">
                      <td style="width: 200px; min-width: 200px;" pFrozenColumn class="justify-content-left"><b>{{ data.nom_completo }}</b></td>
                      <td style="width: 90px; min-width: 90px;" pFrozenColumn class="text-left pl-3" ><b>{{ data.desc_ciudad_principal }}</b></td>

                      <td style="width: 90px; min-width: 90px;" pFrozenColumn class="text-left pl-3" [ngStyle]="{'color': data.desc_puesto ==='DRIVER'? 'rgba(60,  148, 224)' : 'rgba(60, 224, 122  )'}"><b>{{ data.desc_puesto }}</b></td>
                      <td style="width: 110px; min-width: 100px;" pFrozenColumn class="text-center" [ngStyle]="{'color': data.desc_tipo_transporte === 'PESADO' ? 'rgba(172, 109, 14 )' : 'rgb(162, 172, 14)'}"><b>{{ data.desc_tipo_transporte }}</b></td>

                      <td style="width: 90px; min-width: 90px;" class=" text-center"><b>{{ data.imp_sueldo_diario | currency: 'USD' }}</b></td>
                      <td style="width: 90px; min-width: 90px;" class="text-center"><b>{{ data.fec_ingreso | date: 'dd/MM/yyyy' }}</b></td>
                      <td style="width: 90px; min-width: 90px;" class="text-center"><b>{{ data.num_dias_laborados }}</b></td>
                      <td style="width: 90px; min-width: 90px;" class="text-center"><b>{{ data.num_dias_aguinaldo }}</b></td>
                      <td style="width: 60px; min-width: 60px;" class="text-center" style="color: rgb(14, 172, 14);"><b>{{ data.num_dias_pagar }}</b></td>
                      <td style="width: 90px; min-width: 90px;" class="text-center"><b>{{ data.imp_aguinaldo  | currency: 'USD'  }}</b></td>
                      <td style="width: 90px; min-width: 90px;" class="text-center" style="color: rgb(228, 154, 18)" ><b>{{ data.num_anio }}</b></td>
                      <td style="width: 90px; min-width: 90px;" class="text-center"><b>{{ data.num_dias_extra }}</b></td>
                      <td style="width: 90px; min-width: 90px;" class="text-center"><b>{{ data.num_dias_extra_calc }}</b></td>
                      <td style="width: 90px; min-width: 90px;" class="text-center"  style="color: rgb(165, 119, 34)"><b>{{ data.imp_dias_extra | currency: 'USD' }}</b></td>
                      <td style="width: 90px; min-width: 90px;" class="text-center" style="color: rgb(22, 48, 220)"><b>{{ data.imp_total | currency: 'USD' }}</b></td>


                    </tr>
                  </ng-template>
                  <ng-template pTemplate="footer">
                    <tr>
                      <td colspan="2" class="text-right" ></td>
                        <td colspan="3" class="text-right" style="color: rgb(169, 12, 12);">Total aguinaldo anual</td>
                        <td style="color: rgb(13, 164, 13);">{{ imp_aguinaldo_total | currency: 'USD'}}</td>

                        <td colspan="3" class="text-right" style="color: rgb(169, 12, 12);">Total aguinaldo dias extra anual</td>
                        <td style="color:  rgb(13, 164, 13);">{{imp_dias_extra_total | currency: 'USD' }}</td>

                        <td colspan="4" class="text-right" style="color: rgb(169, 12, 12);">Total general anual</td>
                        <td style="color:  rgb(13, 164, 13);">{{imp_total_genral | currency: 'USD'}}</td>
                  </tr>
                </ng-template>
                </p-table>

                <!-- pie de pagina -->
              </div>
              <div class=" mt-3 d-flex justify-content-center">
                <!-- <p class="text-left informativo" style="display: inline; padding-left: 10px; border-left: 5px solid rgb(49, 98, 189); font-weight: normal; font-size: 0.8rem;"><b>ACTIVO</b> el apoyo foráneo se encuentra en proceso</p>
                <p class="text-left informativo"  style="display: inline; padding-left: 10px; border-left: 5px solid rgb(226, 189, 39); font-weight: normal; font-size: 0.8rem;"><b>INACTIVO</b> el apoyo foráneo se ha cancelado, y no se contempla para nomina</p>
                <p class="text-left informativo"  style="display: inline; padding-left: 10px; border-left: 5px solid rgb(28, 163, 35); font-weight: normal; font-size: 0.8rem;">  <b>FINALIZADA</b> el apoyo foráneo se ha finalizado de forma correcta</p>
                <p class="text-left informativo"  style="display: inline; padding-left: 10px; border-left: 5px solid rgb(224, 82, 60); font-weight: normal; font-size: 0.8rem;">  <b>SIN FINALIZAR</b> el apoyo foráneo no ah finalizado</p> -->
                <p class="text-left informativo"  style="display: inline; padding-left: 10px; border-left: 5px solid rgb(224, 82, 60); font-weight: normal; font-size: 0.8rem;"><b>La pagina no cuenta con opciones de edicion</b> </p>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>



    <!-- p-dialog de reportes excel ------------ -->
    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dbsReportes"
      [style]="{'width': '60vw','height':'620px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
      (onHide)="cancelarModalReportes()">
      <ng-template pTemplate="header">
        Generar Reporte De Excel
      </ng-template>
      <ng-template pTemplate="content">
        <div class="mt-5">
          <hr class="style-two">
          <div class="row">
            <div class="col-md-3">
              <p class="texto-pesado">Reporte De Bono Aguinaldo</p>
            </div>
            <div class="col-md-2">
              <select class="form-control" style="width: 100%;" [(ngModel)]="num_anio_rpt">
                <option *ngFor="let year of years" [value]="year">{{ year }}</option>
              </select>

            </div>
            <div class="col-md-3">

              <p-dropdown [(ngModel)]="id_ciudad_principal_rpt" [options]="lstCiudad" optionLabel="nombre" optionValue="id"
              placeholder="Filtrar por Ciudad" [showClear]="true" [disabled]="dsblstCiudades">
            </p-dropdown>


            </div>
            <div class="col-md-3 ml-6">
              <button type="button" class="btn btn-outline-success" style="width: 100%;"
                (click)="exportarExcel(num_anio_rpt, id_ciudad_principal_rpt)" [disabled]="ButtonExportExcel">
                Exportar <i class="pi pi-file-export"></i>
              </button>
            </div>
          </div>
          <div class="row d-flex justify-content-center">
            <div class="col-2 text-center">
              <i class="pi pi-spin pi-spinner" *ngIf="loading_reporte1"></i>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
          (click)="cancelarModalReportes();"></button>
      </ng-template>
    </p-dialog>
  </div>
