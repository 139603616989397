import { ServiciobrindadoService } from './../services/serviciobrindado.service';
import { TabuladoService } from './../services/tabulado.service';
import { ConfunidadService } from './../services/confunidad.service';
import { Component, ViewChild, OnInit } from '@angular/core';
import { ITabuladoSueldo } from '../models/tabuladosueldo';
import { Table } from 'primeng/table';
import { ICiudadHM } from '../models/ciudadhm';
import { CiudadhmService } from '../services/ciudadhm.service';
import { ConfirmEventType, ConfirmationService, MessageService } from 'primeng/api';
import { ProyectoService } from '../services/proyecto.service';
import { IProyecto } from '../models/proyecto';
import { IConfiguracionUnidad } from '../models/confUnidad';
import { ClienteService } from '../services/cliente.service';
import { ICliente } from '../models/cliente';
import { IServicioBrindado } from '../models/serviciobrindado';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IPuestochofer } from '../models/puestochofer';
import { PuestosService } from '../services/puestos.service';
import { IErrorCode } from '../models/errorcode';
import { IRuta } from '../models/ruta';
import { IActivo } from '../models/activo';
import { IDataEnviarOperacion } from '../models/data_enviar_operacion';
import { IDataEnviarNoOrdinario } from '../models/data_enviar_noordinario';
import { ITabuladoReestructura } from '../models/tabulado_reestructura';
import { IDataExtensionRuta } from '../models/data_extension_ruta';
import { IResultadoGeneral } from '../models/resultadogeneral';
import { IRespuestaResultado } from '../models/respuesta_resultado';
import ICredencialUsuario from '../models/credencial';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-salarios',
  templateUrl: './salarios.component.html',
  styleUrls: ['./salarios.component.scss']
})
export class SalariosComponent implements OnInit {
  @ViewChild('dt1') dt!: Table;
  @ViewChild('dt2') dt2!: Table;
  anio: any = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
  dspDialogo: boolean = false;
  dspDialogoExtra: boolean = false;
  texto_operacion: string = '';
  nombre_registro: string = '';
  editarRuta: boolean = true;
  lstTabulado: ITabuladoSueldo[] = [];
  //
  lstTabuladoN: ITabuladoReestructura[] = [];
  //
  loading: boolean = false;
  dataForm: FormGroup = new FormGroup({
    idx: new FormControl(null),
    id_servicio: new FormControl(8, [Validators.required]),
    id_proyecto: new FormControl(null, [Validators.required]),
    id_cliente: new FormControl(null, [Validators.required]),
    clave_configuracionUnidad: new FormControl(null, [Validators.required]),
    id_tipo_puesto: new FormControl(null, [Validators.required]),
    id_ciudad_HM: new FormControl(null, [Validators.required]),
    imp_sueldo: new FormControl(null, [Validators.required]),
    observacion: new FormControl(''),
    num_anio: new FormControl(this.anio, [Validators.required]),
    id_ruta: new FormControl(0)
  });
  //Catalogos
  lstCiudad: ICiudadHM[] = [];
  selectedCiudad: ICiudadHM = {
    id: 0,
    nombre: '',
    clave_estado: '',
    id_ciudad_principal: 0,
    clave_tipoTransporte: 0
  };
  lstProyectos: IProyecto[] = [];
  lstConfUnidad: IConfiguracionUnidad[] = [];
  lstClientes: ICliente[] = [];
  lstServicioBrindado: IServicioBrindado[] = [];
  lstServicioBrindado_form: IServicioBrindado[] = [];
  lstPuestosChofer: IPuestochofer[] = [];
  clv_operacion: number = 0;
  clv_respuesta: IErrorCode = {
    clv_status: false,
    message: '',
    errorCode: ''
  };
  clv_resp: IRespuestaResultado = {
    clv_estatus: 0,
    messageDetails: ''
  }

  //Rutas
  lstRutas: IRuta[] = [];
  selectedRuta: IRuta = {
    clave_ruta: 0,
    idx: 0,
    desc_ruta: '',
    clave_configuracionUnidad: 0,
    id_proyecto: 0,
    id_ciudad: 0,
    id_cliente: 0,
    id_tipo_transporte: 0,
    desc_origen: '',
    desc_destino: '',
    desc_ciudad: '',
    desc_cliente: '',
    fec_registro: '',
    clv_disponible: 0,
    fecha_registro_especial: '',
    clv_especial: 0,
    nombre_responsable: '',
    desc_proyecto: '',
    desc_tipo_transporte: '',
    clv_foranea: 0,
    imp_viaticos_driver: 0,
    imp_viaticos_hospedaje: 0,
    imp_viaticos_auxiliar: 0
  };
  //dialogo rutas
  dsbRutas: boolean = false;
  desc_ruta: string = '';

  //REESTRUCTURA DE TABULADOR
  gestionForm: FormGroup = new FormGroup({
    id_servicio: new FormControl(0, [
      Validators.required
    ])
  });
  noOrdinarioForm: FormGroup = new FormGroup({
    idx: new FormControl(0, [
      Validators.required
    ]),
    id_servicio: new FormControl(0, [
      Validators.required
    ]),
    id_ciudad_HM: new FormControl(0, [
      Validators.required
    ]),
    imp_sueldo_diver: new FormControl(0, [
      Validators.required
    ]),
    imp_sueldo_auxiliar: new FormControl(0, [
      Validators.required
    ]),
    observacion: new FormControl(0, [
      Validators.required
    ]),
    num_anio: new FormControl(0, [
      Validators.required
    ]),
    clv_activo: new FormControl(0, [
      Validators.required
    ]),
    clave_configuracionUnidad: new FormControl(0, [
      Validators.required])
  });
  operacionForm: FormGroup = new FormGroup({
    idx: new FormControl(null),
    id_ruta: new FormControl(null, [Validators.required]),
    id_servicio: new FormControl(8, [Validators.required]),
    id_proyecto: new FormControl({ value: null, disabled: true }, [Validators.required]),
    id_cliente: new FormControl({ value: null, disabled: true }, [Validators.required]),
    clave_configuracionUnidad: new FormControl({ value: null, disabled: true }, [Validators.required]),
    id_ciudad_HM: new FormControl({ value: null, disabled: true }, [Validators.required]),
    num_dias_mas: new FormControl(1, [Validators.required]), //falta ver si va
    imp_sueldo_diver: new FormControl(0, [Validators.required]),
    imp_sueldo_auxiliar: new FormControl(0, [Validators.required]),
    imp_alimento_diver: new FormControl(0, [Validators.required]),
    imp_alimento_auxiliar: new FormControl(0, [Validators.required]),
    imp_apoyo_entregas: new FormControl({ value: 50, disabled: true }, [Validators.required]), //tiendas adicionales por cada tienda
    imp_apoyo_maniobra: new FormControl({ value: 350, disabled: true }, [Validators.required]),
    imp_ext_est_sueldo_diver: new FormControl(0, [Validators.required]),
    imp_ext_est_sueldo_auxiliar: new FormControl(0, [Validators.required]),
    imp_ext_est_alimento_diver: new FormControl(0, [Validators.required]),
    imp_ext_est_alimento_auxiliar: new FormControl(0, [Validators.required]),
    imp_hospedaje: new FormControl(0, [Validators.required]),
    observacion: new FormControl(''),
    num_anio: new FormControl(this.anio, [Validators.required]),
    clv_activo: new FormControl(1, []),
    num_dias_hospedaje: new FormControl(0, [Validators.required]), //dias de hospedaje
  });
  clv_ope: boolean = false;
  clv_noordinario: boolean = false;
  lstEstatus: IActivo[] = [{ id: 0, nombre: 'INACTIVO' }, { id: 1, nombre: 'ACTIVO' }];
  data_extension: IDataExtensionRuta = {
    desc_ruta: '',
    imp_apoyo_entregas: 0,
    imp_apoyo_maniobra: 0,
    imp_ext_est_sueldo_diver: 0,
    imp_ext_est_sueldo_auxiliar: 0,
    imp_ext_est_alimento_diver: 0,
    imp_ext_est_alimento_auxiliar: 0,
    imp_hospedaje: 0,
    num_dias_hospedaje: 0,
    num_dias_mas: 0
  };
  //validaciones boton al guardar
  btnHabilitar: boolean = false;
  btnHabilitarNoOrdinarios: boolean = false;
  user: ICredencialUsuario = {};
  constructor(private ciudadHMService: CiudadhmService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private proyectoService: ProyectoService,
    private confunidadService: ConfunidadService,
    private clienteService: ClienteService,
    private tabuladoService: TabuladoService,
    private serviciobrindadoService: ServiciobrindadoService,
    private puestosService: PuestosService,
    private auth: AuthService) {
    this.cargarInformacionUsuario();


    this.gestionForm.controls['id_servicio'].valueChanges.subscribe(data => {
      if (data == null) {
        this.resetearFormularios();
        this.ocultarPantallasDeFormularios();
      }
    });

    this.operacionForm.controls['id_servicio'].valueChanges.subscribe(data => {
      if (data != 8) {
        this.operacionForm.controls['id_ciudad_HM'].setValue(null);
        this.operacionForm.controls['id_ruta'].setValue(0);
        this.operacionForm.controls['id_proyecto'].setValue(0);
        this.operacionForm.controls['id_cliente'].setValue(0);
        this.operacionForm.controls['clave_configuracionUnidad'].setValue(0);
        this.desc_ruta = '';
      }
    });
  }
  ngOnInit(): void {
    this.cargarListadodeCiudades();
    this.cargarListadoDeProyectos();
    this.cargarListadoDeConfUnidad();
    this.cargarListadoDeClientes();
    this.cargarListadodeServicioBrindado();
    this.cargarListadodePuestos();
    this.getListaDeRutas();
  }

  cargarInformacionUsuario() {
    this.user = this.auth.user;
  }



  filtrarListadoSubEstatus() {
    if (this.lstServicioBrindado_form.length > 0) {
      this.lstServicioBrindado_form.map((valor) => {
        // if (valor.id_servicio == 6) {
        //   valor.disabled = true;
        // } else {
        //   valor.disabled = false;
        // }
      });
    }
  }

  gestionPantallas() {
    let num_opcion = this.gestionForm.get('id_servicio')?.value == null ? 0 : this.gestionForm.get('id_servicio')?.value;
    switch (num_opcion) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 9: {
        //
        this.resetearFormularios();
        this.noOrdinarioForm.controls['id_servicio'].setValue(num_opcion);
        this.iniciarFormulario(num_opcion);
        this.clv_noordinario = true;
        this.clv_ope = false;
      } break;
      case 8: {
        //Operaciones
        this.resetearFormularios();
        this.operacionForm.controls['id_servicio'].setValue(num_opcion);
        this.iniciarFormulario(num_opcion);
        this.clv_noordinario = false;
        this.clv_ope = true;
        this.btnHabilitar = false;
      } break;
      default: {
        this.mostrarDialogoInformativo('Servicio No Encontrado. Contacte al Administrador.');
      } break;
    }
  }

  obtenerAnio(): any {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    return getYear;
  }

  iniciarFormulario(num_opcion: number) {
    let anio_en_curso: any = this.obtenerAnio();
    switch (num_opcion) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 9: {
        //Asistencia Pagada
        this.resetearFormularios();
        this.noOrdinarioForm.controls['id_servicio'].setValue(num_opcion);
        this.noOrdinarioForm.controls['idx'].setValue(0);
        this.noOrdinarioForm.controls['id_ciudad_HM'].setValue(null);
        this.noOrdinarioForm.controls['imp_sueldo_diver'].setValue(0);
        this.noOrdinarioForm.controls['imp_sueldo_auxiliar'].setValue(0);
        this.noOrdinarioForm.controls['observacion'].setValue(' ');
        this.noOrdinarioForm.controls['num_anio'].setValue(anio_en_curso);
        this.noOrdinarioForm.controls['clv_activo'].setValue(1);
        this.noOrdinarioForm.controls['clave_configuracionUnidad'].setValue(null);
      } break;
      case 8: {
        //Operaciones
        this.resetearFormularios();
        //
        this.operacionForm.get('num_anio')!.enable();
        this.operacionForm.get('clv_activo')!.enable();
        //
        this.operacionForm.controls['id_servicio'].setValue(num_opcion);
        //
        this.operacionForm.controls['id_ruta'].setValue(null);
        this.operacionForm.controls['id_cliente'].setValue(null);
        this.operacionForm.controls['id_proyecto'].setValue(null);
        this.operacionForm.controls['clave_configuracionUnidad'].setValue(null);
        this.operacionForm.controls['id_ciudad_HM'].setValue(null);
        //
        this.operacionForm.controls['num_dias_mas'].setValue(1);
        this.operacionForm.controls['imp_sueldo_diver'].setValue(0);
        this.operacionForm.controls['imp_sueldo_auxiliar'].setValue(0);
        this.operacionForm.controls['imp_alimento_diver'].setValue(0);
        this.operacionForm.controls['imp_alimento_auxiliar'].setValue(0);
        this.operacionForm.controls['imp_apoyo_entregas'].setValue(50);
        this.operacionForm.controls['imp_apoyo_maniobra'].setValue(350);
        this.operacionForm.controls['imp_ext_est_sueldo_diver'].setValue(0);
        this.operacionForm.controls['imp_ext_est_sueldo_auxiliar'].setValue(0);
        this.operacionForm.controls['imp_ext_est_alimento_diver'].setValue(0);
        this.operacionForm.controls['imp_ext_est_alimento_auxiliar'].setValue(0);
        this.operacionForm.controls['imp_hospedaje'].setValue(0);
        this.operacionForm.controls['observacion'].setValue(' ');

        this.operacionForm.controls['num_anio'].setValue(anio_en_curso);
        this.operacionForm.controls['clv_activo'].setValue(1);
        this.operacionForm.controls['num_dias_hospedaje'].setValue(0);
      } break;
      default: {
        this.mostrarDialogoInformativo('Servicio No Encontrado. Contacte al Administrador.');
      } break;
    }
  }

  iniciarFormularioConInfo(num_opcion: number, data: ITabuladoReestructura) {
    //Cuando se edita Informacion
    this.gestionForm.controls['id_servicio'].setValue(num_opcion);
    switch (num_opcion) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 9: {
        //Asistencia Pagada
        this.resetearFormularios();
        this.noOrdinarioForm.controls['id_servicio'].setValue(data.id_servicio);
        this.noOrdinarioForm.controls['idx'].setValue(data.idx);
        this.noOrdinarioForm.controls['id_ciudad_HM'].setValue(data.id_ciudad_HM);
        this.noOrdinarioForm.controls['imp_sueldo_diver'].setValue(data.imp_sueldo_diver);
        this.noOrdinarioForm.controls['imp_sueldo_auxiliar'].setValue(data.imp_sueldo_auxiliar);
        this.noOrdinarioForm.controls['observacion'].setValue(data.observacion + ' ');
        this.noOrdinarioForm.controls['num_anio'].setValue(data.num_anio.toString());
        this.noOrdinarioForm.controls['clv_activo'].setValue(data.clv_activo);
        this.noOrdinarioForm.controls['clave_configuracionUnidad'].setValue(data.clave_configuracionUnidad);
        //mostrar pantalla
        this.clv_noordinario = true;
        this.clv_ope = false;
      } break;
      case 8: {
        //Operaciones
        this.resetearFormularios();
        //
        this.operacionForm.get('num_anio')!.disable();
        this.operacionForm.get('clv_activo')!.disable();
        //
        this.operacionForm.controls['idx'].setValue(data.idx);
        this.operacionForm.controls['id_servicio'].setValue(data.id_servicio);
        //
        this.operacionForm.controls['id_ruta'].setValue(data.id_ruta);
        this.operacionForm.controls['id_cliente'].setValue(data.id_cliente);
        this.operacionForm.controls['id_proyecto'].setValue(data.id_proyecto);
        this.operacionForm.controls['clave_configuracionUnidad'].setValue(data.clave_configuracionUnidad);
        this.operacionForm.controls['id_ciudad_HM'].setValue(data.id_ciudad_HM);
        //
        this.operacionForm.controls['num_dias_mas'].setValue(data.num_dias_mas);
        this.operacionForm.controls['imp_sueldo_diver'].setValue(data.imp_sueldo_diver);
        this.operacionForm.controls['imp_sueldo_auxiliar'].setValue(data.imp_sueldo_auxiliar);
        this.operacionForm.controls['imp_alimento_diver'].setValue(data.imp_alimento_diver);
        this.operacionForm.controls['imp_alimento_auxiliar'].setValue(data.imp_alimento_auxiliar);
        this.operacionForm.controls['imp_apoyo_entregas'].setValue(data.imp_apoyo_entregas);
        this.operacionForm.controls['imp_apoyo_maniobra'].setValue(data.imp_apoyo_maniobra);
        this.operacionForm.controls['imp_ext_est_sueldo_diver'].setValue(data.imp_ext_est_sueldo_diver);
        this.operacionForm.controls['imp_ext_est_sueldo_auxiliar'].setValue(data.imp_ext_est_sueldo_auxiliar);
        this.operacionForm.controls['imp_ext_est_alimento_diver'].setValue(data.imp_ext_est_alimento_diver);
        this.operacionForm.controls['imp_ext_est_alimento_auxiliar'].setValue(data.imp_ext_est_alimento_auxiliar);
        this.operacionForm.controls['imp_hospedaje'].setValue(data.imp_hospedaje);
        this.operacionForm.controls['observacion'].setValue(data.observacion + ' ');
        this.operacionForm.controls['num_anio'].setValue(data.num_anio.toString());
        this.operacionForm.controls['clv_activo'].setValue(data.clv_activo);
        this.operacionForm.controls['num_dias_hospedaje'].setValue(data.num_dias_hospedaje);
        this.desc_ruta = data.desc_ruta;
        //mostrar pantalla
        this.clv_noordinario = false;
        this.clv_ope = true;
      } break;
      default: {
        this.mostrarDialogoInformativo('Servicio No Encontrado. Contacte al Administrador.');
      } break;
    }
  }

  resetearFormularios() {
    this.operacionForm.reset();
    this.noOrdinarioForm.reset();
  }

  ocultarPantallasDeFormularios() {
    this.clv_noordinario = false;
    this.clv_ope = false;
  }



  mostrarDialogoInformativo(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '.';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
        this.messageService.clear();
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
        this.messageService.clear();
      },
      key: "sinInformacionDialog"
    });
    this.loading = false;
    this.messageService.clear();
  }

  mostrarDiaologoRutas() {
    this.dsbRutas = true;
  }

  cancelarDialogoRutas() {
    this.dsbRutas = false;
  }

  onRowSelectRuta(data: IRuta) {
    this.operacionForm.controls['id_ruta'].setValue(data.clave_ruta);
    this.cargarInformacionRutaExistente(data);
    this.desc_ruta = data.desc_ruta;
    this.dsbRutas = false;
  }


  desactivarDeRuta() {
    this.dataForm.get('id_ciudad_HM')!.disable();
    this.dataForm.get('id_proyecto')!.disable();
    this.dataForm.get('id_cliente')!.disable();
    this.dataForm.get('clave_configuracionUnidad')!.disable();
  }

  getListaDeRutas() {
    this.tabuladoService.listarRutasNormales().subscribe((resp) => {
      this.lstRutas = resp.filter((data: IRuta) => data.clv_disponible == 1);
    },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error en <Listar Rutas>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  cargarInformacionRutaExistente(data: IRuta) {
    if (data !== null && data.clave_ruta > 0) {
      this.operacionForm.controls['id_ciudad_HM'].setValue(data.id_ciudad);
      this.operacionForm.controls['id_proyecto'].setValue(data.id_proyecto);
      this.operacionForm.controls['id_cliente'].setValue(data.id_cliente);
      this.operacionForm.controls['clave_configuracionUnidad'].setValue(data.clave_configuracionUnidad);
      this.desactivarDeRuta();
    } else {
      this.operacionForm.reset();
    }
  }


  getTargetDescription(ruta: IRuta) {
    return ('#Ruta:' + ruta.clave_ruta.toString() + ' -' + ruta.desc_ciudad);
  }

  lstRutasNormales:any;
  getRutas(num_anio: any){
    this.loading = true;
    this.tabuladoService.GetRutasNormalesList(num_anio).subscribe((resp) => {
      this.lstRutasNormales = resp;

      setTimeout(() => {
        this.loading = false;
      },
        600);
      // if (this.lstRutasNormales.length == 0) {
      //   let mensaje = 'No existen rutas del año a Consultar.';
      //   this.mostrarDialogoInformativoSinInformacion(mensaje);
      // }
    }, (error) => {
      this.loading = false;
      this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Listar Tabulado>', detail: 'Contacte al Administrador del Sitio' });
    });

  }
  cargarInformacionTabulado(num_anio: any) {

    this.loading = true;

    if (typeof num_anio == 'object') {
      num_anio = num_anio.toLocaleDateString('es-MX', { year: 'numeric' });
    }
    if (typeof num_anio === "string") {
      num_anio = num_anio;
    }
    if (num_anio == null || typeof num_anio == 'undefined') {
      let mensaje = 'Seleccione un Año para mostrar Información';
      this.mostrarDialogoInformativoSinInformacion(mensaje);
    } else {

      this.getRutas(num_anio);
      this.tabuladoService.getListadoTabuladoReestructura(num_anio , this.user.cod!).subscribe((resp) => {
        this.lstTabuladoN = resp;
        setTimeout(() => {
          this.loading = false;
        },
          600);
        if (this.lstTabuladoN.length == 0) {
          let mensaje = 'No existe información del Año a Consultar.';
          this.mostrarDialogoInformativoSinInformacion(mensaje);
          this.loading = false;
        }
      }, (error) => {
        this.loading = false;
        this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Listar Tabulado>', detail: 'Contacte al Administrador del Sitio' });
      });

    }
  }

  cargarListadodeServicioBrindado() {
    this.serviciobrindadoService.getListado().subscribe((resp) => {
      this.lstServicioBrindado = resp;
      this.lstServicioBrindado_form = resp;
      this.filtrarListadoSubEstatus();
    }, (error) => {
      this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Listar Servicio Brindado>', detail: 'Contacte al Administrador del Sitio' });
    });
  }

  cargarListadodePuestos() {
    this.puestosService.getListado().subscribe((resp) => {
      this.lstPuestosChofer = resp;
    }, (error) => {
      this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Listar Puestos>', detail: 'Contacte al Administrador del Sitio' });
    });
  }

  cargarListadodeCiudades() {
    this.ciudadHMService.getListadoCiudades().subscribe((resp) => {
      this.lstCiudad = resp;
    }, (error) => {
      this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Listar Ciudades>', detail: 'Contacte al Administrador del Sitio' });
    });
  }

  cargarListadoDeProyectos() {
    this.proyectoService.getListadoProyectos().subscribe((resp) => {
      this.lstProyectos = resp;
      this.lstProyectos.push({
        id_proyecto: 0,
        nombre: 'Sin Proyecto',
        fec_registro: '03-01-2023'
      });
    }, (error) => {
      this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Listar Proyectos>', detail: 'Contacte al Administrador del Sitio' });
    });
  }

  cargarListadoDeConfUnidad() {
    this.confunidadService.getListadoConfUnidades().subscribe((resp) => {
      this.lstConfUnidad = resp;
    }, (error) => {
      this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Listar Conf.Unidad>', detail: 'Contacte al Administrador del Sitio' });
    });
  }

  cargarListadoDeClientes() {
    this.clienteService.getListadoClientes().subscribe((resp) => {
      this.lstClientes = resp;
    }, (error) => {
      this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Listar Clientes>', detail: 'Contacte al Administrador del Sitio' });
    });
  }

  mostrarModalEditar(data: ITabuladoSueldo) {
    //this.dataForm.get('id_ruta')!.disable();
    this.texto_operacion = 'Editar Registro';
    //Editar
    this.clv_operacion = 2;
    this.dataForm.controls['idx'].setValue(data.idx);
    this.dataForm.controls['id_ruta'].setValue(data.id_ruta);
    this.dataForm.controls['id_servicio'].setValue(data.id_servicio);
    this.dataForm.controls['id_cliente'].setValue(data.id_cliente);
    this.dataForm.controls['id_proyecto'].setValue(data.id_proyecto);
    this.dataForm.controls['clave_configuracionUnidad'].setValue(data.clave_configuracionUnidad);
    this.dataForm.controls['id_tipo_puesto'].setValue(data.id_tipo_puesto);
    this.dataForm.controls['id_ciudad_HM'].setValue(data.id_ciudad_HM);
    this.dataForm.controls['imp_sueldo'].setValue(data.imp_sueldo);
    this.dataForm.controls['observacion'].setValue(data.observacion);
    this.dataForm.controls['num_anio'].setValue(data.num_anio.toString());
    this.dspDialogo = true;
  }

  mostrarModalEditarReestructura(data: ITabuladoReestructura) {
    this.editarRuta = data.id_servicio == 8 ? true : false;
    //this.dataForm.get('id_ruta')!.disable();
    this.texto_operacion = 'Editar Registro';
    //Editar
    this.clv_operacion = 2;
    this.iniciarFormularioConInfo(data.id_servicio, data);
    this.dspDialogo = true;
  }

  mostrarModalExtension(data: ITabuladoReestructura) {
    this.texto_operacion = 'Información del Tabulador';
    this.data_extension = {
      desc_ruta: data.desc_ruta,
      imp_apoyo_entregas: data.imp_apoyo_entregas,
      imp_apoyo_maniobra: data.imp_apoyo_maniobra,
      imp_ext_est_sueldo_diver: data.imp_ext_est_sueldo_diver,
      imp_ext_est_sueldo_auxiliar: data.imp_ext_est_sueldo_auxiliar,
      imp_ext_est_alimento_diver: data.imp_ext_est_alimento_diver,
      imp_ext_est_alimento_auxiliar: data.imp_ext_est_alimento_auxiliar,
      imp_hospedaje: data.imp_hospedaje,
      num_dias_hospedaje: data.num_dias_hospedaje,
      num_dias_mas: data.num_dias_mas
    };
    this.dspDialogoExtra = true;
  }

  cancelarMostrarExtension() {
    this.texto_operacion = '';
    this.data_extension = {
      desc_ruta: '',
      imp_apoyo_entregas: 0,
      imp_apoyo_maniobra: 0,
      imp_ext_est_sueldo_diver: 0,
      imp_ext_est_sueldo_auxiliar: 0,
      imp_ext_est_alimento_diver: 0,
      imp_ext_est_alimento_auxiliar: 0,
      imp_hospedaje: 0,
      num_dias_hospedaje: 0,
      num_dias_mas: 0
    }
    this.dspDialogoExtra = false;
  }


  cancelarModal() {
    //
    this.resetearFormularios();
    this.ocultarPantallasDeFormularios();
    //
    this.dspDialogo = false;
    this.dataForm.reset();
    this.gestionForm.reset();
    this.asigarFormDefault();
    this.texto_operacion = '';
    this.clv_operacion = 0;
    this.clv_respuesta = {
      clv_status: false,
      message: '',
      errorCode: ''
    };
    this.clv_resp = {
      clv_estatus: 0,
      messageDetails: ''
    };

    //para el boton de guardar al primer clic
    this.btnHabilitar = false;
  }

  asigarFormDefault() {
    this.dataForm.controls['id_servicio'].setValue(8);
    this.dataForm.controls['num_anio'].setValue(this.anio);
  }



  obtenerInformacionCiudad(id_ciudad_HM: number): ICiudadHM {
    let ciudad: ICiudadHM = {
      id: 0,
      nombre: '',
      clave_estado: '',
      id_ciudad_principal: 0,
      clave_tipoTransporte: 0
    };
    this.lstCiudad.map((dato) => {
      if (dato.id == id_ciudad_HM) {
        ciudad = dato;
      }
    });
    return ciudad;
  }

  guardarInformacion() {
    this.btnHabilitar = true;
    this.selectedCiudad = this.obtenerInformacionCiudad(this.operacionForm.get('id_ciudad_HM')?.value);
    let num_anio_cast: any = this.operacionForm.get('num_anio')?.value;
    if (typeof num_anio_cast == 'object') {
      num_anio_cast = num_anio_cast.toLocaleDateString('es-MX', { year: 'numeric' });
    }
    if (typeof num_anio_cast === "string") {
      num_anio_cast = num_anio_cast;
    }
    let data_enviar_op: IDataEnviarOperacion = {
      idx: 0,
      id_ruta: this.operacionForm.get('id_ruta')?.value,
      id_servicio: this.operacionForm.get('id_servicio')?.value,
      id_proyecto: this.operacionForm.get('id_proyecto')?.value,
      id_cliente: this.operacionForm.get('id_cliente')?.value,
      clave_configuracionUnidad: this.operacionForm.get('clave_configuracionUnidad')?.value,
      id_ciudad_HM: this.operacionForm.get('id_ciudad_HM')?.value,
      num_dias_mas: this.operacionForm.get('num_dias_mas')?.value,
      imp_sueldo_diver: this.operacionForm.get('imp_sueldo_diver')?.value,
      imp_sueldo_auxiliar: this.operacionForm.get('imp_sueldo_auxiliar')?.value,
      imp_alimento_diver: this.operacionForm.get('imp_alimento_diver')?.value,
      imp_alimento_auxiliar: this.operacionForm.get('imp_alimento_auxiliar')?.value,
      imp_apoyo_entregas: this.operacionForm.get('imp_apoyo_entregas')?.value,
      imp_apoyo_maniobra: this.operacionForm.get('imp_apoyo_maniobra')?.value,
      imp_ext_est_sueldo_diver: this.operacionForm.get('imp_ext_est_sueldo_diver')?.value,
      imp_ext_est_sueldo_auxiliar: this.operacionForm.get('imp_ext_est_sueldo_auxiliar')?.value,
      imp_ext_est_alimento_diver: this.operacionForm.get('imp_ext_est_alimento_diver')?.value,
      imp_ext_est_alimento_auxiliar: this.operacionForm.get('imp_ext_est_alimento_auxiliar')?.value,
      imp_hospedaje: this.operacionForm.get('imp_hospedaje')?.value,
      observacion: this.operacionForm.get('observacion')?.value == null ? '' : this.operacionForm.get('observacion')?.value,
      num_anio: num_anio_cast,
      clv_activo: this.operacionForm.get('clv_activo')?.value,
      num_dias_hospedaje: this.operacionForm.get('num_dias_hospedaje')?.value,
      cod_usuario: this.user.cod!
    };

    if (this.clv_operacion == 1) {
      //mandar llamar servicio agregar
      this.tabuladoService.agregarReestructuraOperaciones(data_enviar_op).subscribe((resp) => {
        this.clv_respuesta = resp;
        this.clv_resp = resp;
        if (this.clv_resp.clv_estatus == -1) {
          this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Tabulado ya existente', detail: 'La informacion se proceso de forma correcta' });
        } else {
          this.cargarInformacionTabulado(this.anio);
          this.getListaDeRutas();
          this.cancelarModal();
          this.messageService.add({ key: 'msj', severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se registro de forma correcta' });
        }
      }, (error) => {
        this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Guardar Tabulado>', detail: 'Contacte al Administrador del Sitio' });
      });
    } else if (this.clv_operacion == 2) {
      //editar
      data_enviar_op.idx = this.operacionForm.get('idx')?.value;
      this.tabuladoService.agregarReestructuraOperaciones(data_enviar_op).subscribe((resp) => {
        this.clv_respuesta = resp;
        this.clv_resp = resp;
        if (this.clv_resp.clv_estatus == -1) {
          this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Error en <Editar Tabulado>', detail: this.clv_resp.messageDetails });
        }
        else {
          this.cargarInformacionTabulado(this.anio);
          this.cancelarModal();
          this.messageService.add({ key: 'msj', severity: 'success', summary: 'Actualización Exitosa', detail: 'La informacion se actualizo de forma correcta' });
        }


      }, (error) => {
        this.btnHabilitar = false;
        this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Editar Tabulado>', detail: 'Contacte al Administrador del Sitio' });
      });
    }

  }

  guardarInformacionNoOrdinarios() {
    let num_anio_cast: any = this.noOrdinarioForm.get('num_anio')?.value;
    if (typeof num_anio_cast == 'object') {
      num_anio_cast = num_anio_cast.toLocaleDateString('es-MX', { year: 'numeric' });
    }
    if (typeof num_anio_cast === "string") {
      num_anio_cast = num_anio_cast;
    }
    let data_enviar: IDataEnviarNoOrdinario = {
      idx: this.noOrdinarioForm.get('idx')?.value,
      id_servicio: this.noOrdinarioForm.get('id_servicio')?.value,
      id_ciudad_HM: this.noOrdinarioForm.get('id_ciudad_HM')?.value,
      imp_sueldo_diver: this.noOrdinarioForm.get('imp_sueldo_diver')?.value,
      imp_sueldo_auxiliar: this.noOrdinarioForm.get('imp_sueldo_auxiliar')?.value,
      clave_configuracionUnidad: this.noOrdinarioForm.get('clave_configuracionUnidad')?.value,
      observacion: this.noOrdinarioForm.get('observacion')?.value == null ? '' : this.noOrdinarioForm.get('observacion')?.value,
      num_anio: num_anio_cast,
      clv_activo: this.noOrdinarioForm.get('clv_activo')?.value,
      cod_usuario: this.user.cod!
    };
    if (this.clv_operacion == 1) {
      //guardar
      this.tabuladoService.agregarReestructuraNoOrdinario(data_enviar).subscribe((resp) => {
        this.clv_respuesta = resp;
        this.clv_resp = resp;
        if (this.clv_resp.clv_estatus == -1) {
          this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Error en <Guardar Tabulado>', detail: resp.messageDetails });
        }
        else {
          this.cargarInformacionTabulado(this.anio);
          this.cancelarModal();
          this.messageService.add({ key: 'msj', severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se registro de forma correcta' });
        }

      }, (error) => {
        this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Guardar Tabulado>', detail: 'Contacte al Administrador del Sitio' });
      });
    } else if (this.clv_operacion == 2) {
      //editar
      this.tabuladoService.agregarReestructuraNoOrdinario(data_enviar).subscribe((resp) => {
        this.clv_respuesta = resp;
        this.clv_resp = resp;
        if (this.clv_resp.clv_estatus == -1) {
          this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Error en <Editar Tabulado>', detail: this.clv_resp.messageDetails });
        }
        else {
          this.cargarInformacionTabulado(this.anio);
          this.cancelarModal();
          this.messageService.add({ key: 'msj', severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se registro de forma correcta' });
        }
      }, (error) => {
        this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Editar Tabulado>', detail: 'Contacte al Administrador del Sitio' });
      });
    }
  }

  mostrarModalAgregar() {
    this.editarRuta = false;
    this.gestionForm.reset();
    this.dataForm.get('id_ruta')!.enable();
    this.texto_operacion = 'Nuevo Registro';
    this.dspDialogo = true;
    //Agregar
    this.clv_operacion = 1;
    //el boton guardar que se habilite para que funcione en el primer clic
    this.btnHabilitar = false;
  }

  confirmarEliminar(id: number, data: ITabuladoReestructura) {

    let clv_estatus_original: number = data.clv_activo;
    let texto_desactivar: string = data.clv_activo == 1 ? 'DESACTIVAR' : 'ACTIVAR';
    let texto_desactivar_header: string = data.clv_activo == 1 ? 'Desactivar' : 'Activar';
    this.confirmationService.confirm({
      message: 'Esta seguro que desea <b>' + texto_desactivar + '</b> el registro de Tabulador <b>' + (data.id_servicio == 8 ? data.desc_ruta : data.desc_servicio)
        + '</b>  (' + data.desc_ciudad_HM + '-' + data.num_anio + ') ?',
      header: 'Confirmación de ' + texto_desactivar_header,
      icon: 'pi pi-info-circle',
      accept: () => {
        data.clv_activo = data.clv_activo == 1 ? 0 : 1;
        this.guardarInformacionDesactivar(data);
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            data.clv_activo = clv_estatus_original;
            //this.messageService.add({ key: 'msj', severity: 'error', summary: 'Rechazado', detail: 'Se ha Rechazado la operación' });
            break;
          case ConfirmEventType.CANCEL:
            data.clv_activo = clv_estatus_original;
            //this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Se ha Cancelado la operación' });
            break;
        }
      }
    });
  }

  guardarInformacionDesactivar(data: ITabuladoReestructura) {
    this.tabuladoService.estatus(data).subscribe((resp) => {
      this.cargarInformacionTabulado(this.anio);
      this.messageService.add({ key: 'msj', severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se actualizo de forma correcta' });
    },
      (error) => {
        this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Editar>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  /*Filtro General para la informacion de tabla*/
  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  /*Filtro General para la informacion de tabla*/
  applyFilterGlobal2($event: any, stringVal: any) {
    this.dt2.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario;
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }
}
