<p-toast position="top-right" key="msj"></p-toast>
<!--<p-messages></p-messages>-->
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="Aceptar" rejectLabel="Cancelar"
  rejectButtonStyleClass="p-button-text">
</p-confirmDialog>

<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
  rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar" [rejectVisible]="false">
</p-confirmDialog>
<div class="d-flex justify-content-center">
  <div class="container-fluid" style="width: 95%;">
    <div class="row mt-5">
      <div class="col-md-12">
        <div class="card card-shadow sinborde">
          <div class="card-header sinborde" style="background: none; ">
            <div class="cardicono rounded">
              <i class="pi pi-id-card mt-3" style="font-size: 2rem; color: #fff;"></i>
            </div>
            <header style="left: 11%; top: 5%;">
              <div style="display:inline"> Gestion de</div>

              <div *ngIf="dia.length > 0" style="color:rgb(22, 48, 220);display:inline;" class="ml-3">Bono Por
                Recomendacion</div>
              <!-- - {{dia|date:'dd/MM/yyyy'}}
                 -->
            </header>
            <!-- <div class="row d-flex justify-content-end">
              <div class="col-md-12">


                <div class="form-group row mt-5">
                  <div class="col-md-3">
                    <div class="row d-flex justify-content-start">
                      <div class="col-md-5">
                        <label class="col-form-label color-font text-right"
                          style="font-weight: 600; font-family: 'Poppins', sans-serif;">
                          <span class="input-group-addon ml-5"> <i class="pi pi-calendar color-icon mr-4"
                              style="font-size: 2rem; color: #3B82F6;"></i>
                          </span>
                          Año
                        </label>
                      </div>
                      <div class="col-sm-7 mt-3">
                        <select class="form-control" [(ngModel)]="num_anio">
                          <option *ngFor="let year of years" [value]="year">{{ year }}</option>
                        </select>

                      </div>
                    </div>
                  </div>

                  <div class="col-md-3 mt-2">
                    <p-dropdown [options]="meses" autoWidth="false" [style]="{'width':'100%'}"
                      placeholder="Seleccione el mes*" optionValue="num_mes" [(ngModel)]="id_mes" optionLabel="nombre"
                      [showClear]="false">
                    </p-dropdown>

                  </div>

                  <div class="col-md-3 mr-3 mt-2">
                    <p-dropdown [(ngModel)]="id_ciudad_hm" [options]="lstCiudad" optionLabel="nombre" optionValue="id"
                      placeholder="Filtrar por Ciudad" [showClear]="false" [disabled]="dsblstCiudades">
                    </p-dropdown>
                  </div>


                  <div class="col-md-1 ">
                    <button pButton pRipple type="button" (click)="FiltrarFechaDiariaRuta();" label="Filtrar"
                      icon="pi pi-filter" iconPos="left"></button>
                  </div>

                  <div class="  col-md-1 ml-6 ">

                  </div>

                </div>

              </div>
            </div> -->



            <div class="row d-flex justify-content-around mt-5">
              <div class="col-md-1"></div>


              <!-- filtro de semanas -->
              <div class="col-md-3">
                <p-dropdown [options]="lstSemanas" autoWidth="false" [style]="{'width':'100%'}"
                  placeholder="Seleccione Semana*" [(ngModel)]="selectedSemana" optionLabel="nom_semana"
                  [showClear]="false">
                  <ng-template let-target pTemplate="item">
                    <div class="ui-helper-clearfix" style="position: relative;height:25px;"
                      pTooltip="{{getTargetDescription(target)}}" tooltipPosition="top">
                      <span style="font-size:14px;float:left;margin-top:4px">{{target.nom_semana}}</span>
                    </div>
                  </ng-template>
                </p-dropdown>
                <div *ngIf="selectedSemana!=null">
                  <div class="row">
                    <div style="color: green;" class="col-sm-6 text-left "><b>Inicio:
                        {{selectedSemana.fec_inicio | date: 'dd/MM/yyyy' }}</b></div>
                    <div style="color: green;" class="col-sm-6 text-right "><b>Fin:
                        {{selectedSemana.fec_fin | date: 'dd/MM/yyyy' }}</b></div>
                  </div>
                </div>
              </div>
              <!-- filtro de ciudades -->
              <div class="col-md-2  mt-2">
                <p-dropdown [(ngModel)]="id_ciudad_hm" [options]="lstCiudad" optionLabel="nombre" optionValue="id"
                  placeholder="Filtrar por Ciudad" [showClear]="false" [disabled]="dsblstCiudades">
                </p-dropdown>
              </div>

              <!-- ----------boton principal para el filtro  inicial------------------- -->
              <div class="col-md-1 ">
                <button pButton pRipple type="button" (click)="FiltrarFechaDiariaRuta();" label="Filtrar"
                  icon="pi pi-filter" iconPos="left"></button>
              </div>
              <!-- ----------boton principal de los reportes en excel------------------- -->


              <div class=" col-md-3 d-flex justify-content-end ">
                <div>
                  <div class="col-md-6">
                    <div class="row d-flex justify-content-around">
                      <div class="col-md-9">
                        <p-calendar [(ngModel)]="anio_filtro" view="year" [showIcon]="true" dateFormat="yy"
                          inputId="yearpicker" [style]="{'width':'100%','height':'80%'}"
                          pTooltip="Año de Semanas a Consultar" tooltipPosition="top"></p-calendar>
                      </div>
                      <div class="col-md-3">
                        <button pButton pRipple label="Cargar" class="custom-buttoncargar" icon="pi pi-filter"
                          (click)="cargarListadoDeSemanasAnio_cast(anio_filtro);"
                          pTooltip="Cargar Semanas del Año"></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-1"></div>

            </div>
            <div class="card-content">
              <div class="row mt-3">
                <!-- informacio  -->
                <p-table #dt1 [value]="lstBonoRecomendacion" [tableStyle]="{'min-width': '50rem'}"
                  styleClass="p-datatable-sm p-datatable-striped" [scrollable]="true" scrollHeight="480px"
                  scrollDirection="horizontal" responsiveLayout="scroll"
                  [globalFilterFields]="['nom_chofer','desc_puesto','desc_ciudad_hm','desc_tipo_transporte']"
                  [paginator]="true" [rows]="30" [rowsPerPageOptions]="[10,15,20,30,50]" [showCurrentPageReport]="true"
                  [resizableColumns]="false" currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros"
                  [loading]="loading">
                  <ng-template pTemplate="caption">
                    <div class="row d-flex justify-content-between">
                      <!-- Botón alineado a la izquierda -->
                      <div class="col-3">
                        <button type="button" *ngIf="mostraragregar" class="btn  btn-outline-primary form-control"
                          (click)="mostrarModalAgregar();" tooltipPosition="top">
                          Agregar Bono Por Recomendacion <i class="pi pi-plus-circle"></i>
                        </button>
                      </div>

                      <!-- Filtro alineado a la derecha -->
                      <div class="col-md-auto">
                        <span class="p-input-icon-left">
                          <i class="pi pi-search" style="margin-right: 5px;"></i>
                          <input pInputText pTooltip="Buscar por: Nombre del colaborador, Ciudad o Puesto" type="text"
                            (input)="applyFilterGlobal($event, 'contains')" placeholder="Buscar"
                            style="margin-left: 5px;" />
                        </span>
                      </div>
                    </div>

                  </ng-template>

                  <!-- tabulado -->
                  <ng-template pTemplate="header">
                    <tr>
                      <th class="text-center" pSortableColumn="nom_completo">cod. chofer<p-sortIcon
                          field="nom_completo"></p-sortIcon></th>
                      <th class="text-center" pSortableColumn="nom_completo">Acreedor<p-sortIcon
                          field="nom_completo"></p-sortIcon></th>
                      <th class="text-center" pSortableColumn="id_ciudad_hm"> Ciudad<p-sortIcon
                          field="id_ciudad_hm"></p-sortIcon></th>
                      <th class="text-center" pSortableColumn="desc_puesto">Puesto<p-sortIcon
                          field="desc_puesto"></p-sortIcon></th>
                      <th class="text-center">Semana</th>
                      <th class="text-center">Semana inicio </th>
                      <th class="text-center">Semana fin </th>
                      <th class="text-center" pSortableColumn="desc_puesto">Recomendado<p-sortIcon
                          field="desc_puesto"></p-sortIcon></th>
                      <th pSortableColumn="total_a_pagar">Total<p-sortIcon field="total_a_pagar"></p-sortIcon></th>
                      <th class="text-center">Estatus</th>
                      <th></th>
                      <th></th>
                    </tr>

                  </ng-template>
                  <ng-template pTemplate="body" let-data let-index>
                    <tr>
                      <td class="text-center"><b>{{data.cod_chofer }}</b></td>
                      <td class="justify-content-left"><b>{{data.nom_chofer }}</b></td>
                      <td class="text-center">{{ data.desc_ciudad_hm}}</td>
                      <td class="text-left pl-3"
                        [ngStyle]="{'color': data.desc_puesto ==='DRIVER'? 'rgba(60,  148, 224)' : 'rgba(60, 224, 122  )'}">
                        <b>{{ data.desc_puesto }}</b>
                      </td>
                      <td class="text-center">{{ data.id_semana }}</td>
                      <td class="text-center">{{data.semana_hm_inicio | date: 'dd/MM/yyyy'}}</td>
                      <td class="text-center">{{data.semana_hm_fin | date: 'dd/MM/yyyy'}}</td>
                      <td class="justify-content-left">{{data.nom_chofer_recomendado}}</td>
                      <td class="text-center" style="color: rgb(22, 48, 220)"><b>{{ data.bono_recomendacion | currency:
                          'USD' }}</b></td>
                      <td class="text-center" [ngStyle]="{'color': data.clv_activo === 2 ? 'green' : 'orange'}">
                        <b>{{ data.clv_activo === 1 ? 'Requiere Autorizar' : 'Bono aceptado' }}</b>
                      </td>
                      <td>
                        <div class="d-flex justify-content-around">
                          <div class="ml-2">
                            <button class="btn btn-success" pTooltip="Aprobar bono" (click)="AprobarBono(data)">
                              <i class="pi pi-check"></i>
                            </button>
                          </div>

                        </div>
                      </td>
                      <td>
                        <div class="d-flex justify-content-around">
                          <div class="ml-2">
                            <button [disabled]="data.clv_activo === 2" class="btn btn-danger" pTooltip="Eliminar Bono"
                              (click)="EliminarRegistro(data)">
                              <i class="pi pi-trash"></i>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="footer">
                    <tr>
                      <td colspan="2" class="text-right"></td>
                      <td colspan="9" class="text-right" style="color: rgb(169, 12, 12);">Total de bono por
                        recomendacion</td>
                      <td style="color: rgb(13, 164, 13);">{{ imp_total_genral | currency: 'USD'}}</td>
                    </tr>
                  </ng-template>
                </p-table>

                <!-- pie de pagina -->
              </div>
              <div class=" mt-3 d-flex justify-content-center">
                <!-- <p class="text-left informativo" style="display: inline; padding-left: 10px; border-left: 5px solid rgb(49, 98, 189); font-weight: normal; font-size: 0.8rem;"><b>ACTIVO</b> el apoyo foráneo se encuentra en proceso</p>
                <p class="text-left informativo"  style="display: inline; padding-left: 10px; border-left: 5px solid rgb(226, 189, 39); font-weight: normal; font-size: 0.8rem;"><b>INACTIVO</b> el apoyo foráneo se ha cancelado, y no se contempla para nomina</p>
                <p class="text-left informativo"  style="display: inline; padding-left: 10px; border-left: 5px solid rgb(28, 163, 35); font-weight: normal; font-size: 0.8rem;">  <b>FINALIZADA</b> el apoyo foráneo se ha finalizado de forma correcta</p>
                <p class="text-left informativo"  style="display: inline; padding-left: 10px; border-left: 5px solid rgb(224, 82, 60); font-weight: normal; font-size: 0.8rem;">  <b>SIN FINALIZAR</b> el apoyo foráneo no ah finalizado</p> -->
                <!-- <p class="text-left informativo"  style="display: inline; padding-left: 10px; border-left: 5px solid rgb(224, 82, 60); font-weight: normal; font-size: 0.8rem;"><b>La pagina no cuenta con opciones de edicion</b> </p> -->

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>


    <!-- pdialog de agregar  -->
    <p-dialog [resizable]="false" [draggable]="false" [scrollTop]="" (onHide)="cancelarVentanaModalPdialog()"
      [(visible)]="dspCliente" [style]="{width: '65vw','height':'650px' } " [transitionOptions]="'0ms'">
      <ng-template pTemplate="header">
        {{ texto_operacion}}
      </ng-template>
      <ng-template pTemplate="content">
        <div style="height: 700px; overflow-y: auto;">
          <div class="card-content ">
            <form class="form_cliente" [formGroup]="reactivebonoRecomendacion" (ngSubmit)="guardarBono()">
              <div class="ml-2">
                <div class="row mt-1 d-flex justify-content-start">
                  <div class="col-sm-6">
                    <span class="title-light">Seleccione la semana a pagar y el colaborador
                      <i class="pi pi-cog" style="size: 2rem;">
                      </i>
                    </span>
                  </div>
                </div>


                <div class="row mt-2 d-flex justify-content-around mt-3 ml-3">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-3 mt-2 text-center">
                        <label class="col-form-label color-font">
                          <span class="input-group-addon">
                            <i class="pi pi-calendar-plus color-icon mr-3" style="font-size: 2rem"></i>
                          </span>
                          Semana
                        </label>
                      </div>
                      <div class="col-md-9 mt-2 ">
                        <p-dropdown [options]="lstSemanas" autoWidth="false" [style]="{'width':'100%'}"
                          placeholder="Seleccione Semana*" formControlName="selectedSemana" optionLabel="nom_semana"
                          [showClear]="false">
                          <ng-template let-target pTemplate="item">
                            <div class="ui-helper-clearfix" style="position: relative;height:25px;"
                              pTooltip="{{getTargetDescription(target)}}" tooltipPosition="top">
                              <span style="font-size:14px;float:left;margin-top:4px">{{target.nom_semana}}</span>
                            </div>
                          </ng-template>
                        </p-dropdown>
                        <div *ngIf="reactivebonoRecomendacion.controls['selectedSemana'].value">
                          <div class="row">
                            <div style="color: green;" class="col-sm-6 text-left "><b>Inicio:
                                {{ reactivebonoRecomendacion.controls['fec_inicio'].value | date:'dd/MM/yyyy' }}</b>
                            </div>
                            <div style="color: green;" class="col-sm-6 text-right "><b>Fin:
                                {{ reactivebonoRecomendacion.controls['fec_fin'].value | date:'dd/MM/yyyy' }}</b></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-3">
                        <!-- Aquí colocas el contenido para el icono -->
                      </div>
                      <div class="col-md-9">
                        <!-- Aquí colocas el contenido para el dropdown -->
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row justify-content-around ml-3 mt-4">
                  <!-- colaboradores  que recibira el apoyo-->
                  <div class="row d-flex ">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-3 mt-2 text-center">
                          <label class="col-form-label color-font">
                            <span class="input-group-addon">
                              <i class="pi pi-user color-icon mr-3" style="font-size: 2rem"></i>
                            </span>
                            Acreedor
                          </label>
                        </div>
                        <div class="col-md-9">
                          <p-dropdown [options]="lstChoferes" optionLabel="nombre_completo" formControlName="cod_chofer"
                            autoWidth="false" [showClear]="false" [filter]="true" [filterBy]="'nombre_completo'"
                            placeholder="Seleccione Colaborador" [style]="{'width':'100%' , 'margin-top': '20px'}"
                            optionValue="cod_chofer" (onChange)="changedp($event)">

                          </p-dropdown>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-5">
                      <div class="row mt-2">
                        <div class="col-md-4 mt-3 text-center">
                          <label class="col-form-label color-font ">
                            <span class="input-group-addon">
                              <i class="pi pi-globe color-icon mr-3" style="font-size: 2rem"></i>
                            </span>
                            Ciudad
                          </label>
                        </div>
                        <div class="col-md-8">
                          <p-dropdown [options]="lstCiudad2" optionLabel="nombre" formControlName="id_ciudad_hm"
                            autoWidth="false" [showClear]="false"
                            placeholder="Seleccione Ciudad del colaborador acreedor"
                            [style]="{'width':'100%' , 'margin-top': '20px'}" optionValue="id"
                            pTooltip="{{mssgListaCiudades}}">
                          </p-dropdown>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- colaboradores  que fue recomendado -->
                  <div class="row d-flex ">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-3 mt-2 text-center">
                          <label class="col-form-label color-font">
                            <span class="input-group-addon">
                              <i class="pi pi-user color-icon mr-3" style="font-size: 2rem"></i>
                            </span>
                            Recomendado
                          </label>
                        </div>
                        <div class="col-md-9">
                          <p-dropdown [options]="lstChoferes" optionLabel="nombre_completo"
                            formControlName="cod_chofer_recomendado" autoWidth="false" [showClear]="false"
                            [filter]="true" [filterBy]="'nombre_completo'" placeholder="Seleccione Colaborador"
                            [style]="{'width':'100%' , 'margin-top': '20px'}" optionValue="cod_chofer"
                            (onChange)="changedprecomendado($event)">
                          </p-dropdown> <!-- Asegúrate de que esta etiqueta de cierre esté presente -->
                        </div>
                      </div>
                    </div>
                    <div class="col-md-5">
                      <div class="row mt-2">
                        <div class="col-md-4 mt-3 text-center">
                          <label class="col-form-label color-font ">
                            <span class="input-group-addon">
                              <i class="pi pi-globe color-icon mr-3" style="font-size: 2rem"></i>
                            </span>
                            Ciudad
                          </label>
                        </div>
                        <div class="col-md-8">
                          <p-dropdown [options]="lstCiudad2" optionLabel="nombre"
                            formControlName="id_ciudad_recomendado" autoWidth="false" [showClear]="false"
                            placeholder="Seleccione la Ciudad " [style]="{'width':'100%' , 'margin-top': '20px'}"
                            optionValue="id" pTooltip="{{mssgListaCiudades}}">
                          </p-dropdown>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row d-flex "></div>
                  <div class="row d-flex "></div>
                  <!-- --------------fin drodowns------------- -->
                  <div class="row d-flex ">
                    <div class="col-md-5  mt-6 justify-content-start">
                      <!-- <p><span style="font-weight: bold; ">Última&nbsp;Modificación:</span></p>
                  <p style="margin-top: 0px;  color: rgb(181, 17, 50);"> -->
                      <!-- <b>{{reactiveVacacionesForm.get('usuarioMod')?.value}}</b>
                  </p>-->
                    </div>

                    <div class="col-md-3 mt-7 justify-content-center">
                      <button class="btn btn-primary form-control" type="submit"
                        [disabled]="!reactivebonoRecomendacion.controls['cod_chofer'].valid  || !reactivebonoRecomendacion.controls['cod_chofer_recomendado'].valid || !reactivebonoRecomendacion.controls['selectedSemana'].valid"
                        pTooltip="Almacenar informacion" tooltipPosition="right">
                        <span class="text-white">Guardar</span>
                        <i class="pi pi-save text-white"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </ng-template>

      <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
          (click)="cancelarVentanaModalPdialog()"></button>
      </ng-template>
    </p-dialog>



    <!-- p-dialog de reportes excel ------------ -->
    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dbsReportes"
      [style]="{'width': '60vw','height':'620px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
      (onHide)="cancelarModalReportes()">
      <ng-template pTemplate="header">
        Generar Reporte De Excel
      </ng-template>
      <ng-template pTemplate="content">
        <div class="mt-5">
          <hr class="style-two">
          <div class="row">
            <div class="col-md-3">
              <p class="texto-pesado">Reporte De Prima Vacacional</p>
            </div>
            <div class="col-md-2">
              <select class="form-control" style="width: 100%;" [(ngModel)]="num_anio">
                <option *ngFor="let year of years" [value]="year">{{ year }}</option>
              </select>
            </div>
            <div class="col-md-3">
              <p-dropdown [(ngModel)]="id_ciudad_principal" [options]="lstCiudad" optionLabel="nombre" optionValue="id"
                placeholder="Filtrar por Ciudad" [showClear]="false" [disabled]="dsblstCiudades">
              </p-dropdown>
            </div>
            <div class="col-md-3 ml-6">
              <button type="button" class="btn btn-outline-success" style="width: 100%;"
                (click)="exportarExcel(num_anio, id_ciudad_principal)" [disabled]="ButtonExportExcel">
                Exportar <i class="pi pi-file-export"></i>
              </button>
            </div>
          </div>
          <div class="row d-flex justify-content-center">
            <div class="col-2 text-center">
              <i class="pi pi-spin pi-spinner" *ngIf="loading_reporte1"></i>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
          (click)="cancelarModalReportes();"></button>
      </ng-template>
    </p-dialog>
  </div>
