import { CajaahorroService } from './../services/cajaahorro.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ICajaAhorro } from '../models/caja_ahorro';
import { Table } from 'primeng/table';
import { AuthService } from '../services/auth.service';
import { ConfirmEventType, ConfirmationService, MessageService } from 'primeng/api';
import ICredencialUsuario from '../models/credencial';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CiudadhmService } from '../services/ciudadhm.service';
import { ICiudadHM } from '../models/ciudadhm';
import { ICajaAhorroEnviar } from '../models/caja_ahorro_enviar';
import { IColaboradoresActivos } from '../models/colaboradores_activos';
import { Iahorro } from '../models/listaahorro';
import { SemanaService } from '../services/semana.service';
import { ISemanasHappyMile } from '../models/semanas';
import { IEstatus } from '../models/estatus';
import { ICiudadPrincipalHM } from '../models/ciudadprincipal';
import { BonoaguinaldoService } from '../services/bonoaguinaldo.service';
import { IMes } from '../models/mes';
import * as saveAs from 'file-saver';

@Component({
  selector: 'app-cajaahorro',
  templateUrl: './cajaahorro.component.html',
  styleUrls: ['./cajaahorro.component.scss']
})
export class CajaahorroComponent implements OnInit {
  @ViewChild('dt1') dt!: Table;
  user: ICredencialUsuario = {};


  lstColaboradores: ICajaAhorro[] = [];
  lstahorro: Iahorro[] = [];
  lstTodosColaboradores: IColaboradoresActivos[] = [];
  lstSemanas: ISemanasHappyMile[] = [];
  lstCiudad: ICiudadHM[] = [];
  lstCiudadPrincipal: ICiudadPrincipalHM[] = [];
  lstCiudadPrincipalRpt: ICiudadPrincipalHM[] = [];
  id_ciudad_principal: ICiudadPrincipalHM = {
    id: 0,
    nombre: '',
    clave_estado: ''
  };
  id_ciudad_principal_rpt: number = 0;

  loading: boolean = false;
  fecha_dia: any = null;
  anio: any;
  dspDialogo: boolean = false;
  texto_operacion: string = '';
  nombre_registro: string = '';

  imp_total: number = 0;
  clv_pagado: number = 0;
  clv_his: number = 0;
  clv_operacion: number = 0;
  nombre_completo: string = '';
  cod_chofer: number = 0;
  dsphistorico: boolean = false;

  dsppagosemanal: boolean = false;

  dataForm: FormGroup = new FormGroup({
    id_caja_ahorro: new FormControl(null),
    cod_chofer: new FormControl(null, [Validators.required]),
    id_ciudad_hm: new FormControl({ value: null, disabled: true }, [Validators.required]),
    imp_ahorro: new FormControl(null, [Validators.required]),
    id_ciudad_principal: new FormControl()
  });

  selectedSemana: ISemanasHappyMile = {
    idx: 0,
    id_semana: 0,
    nom_semana: '',
    anio: 0,
    fec_inicio: '',
    fec_fin: '',
    clv_activo: 0
  };

  lstEstatus: IEstatus[] = [{
    id: 1,
    nombre: 'ACTIVO'
  },
  {
    id: 0,
    nombre: 'INACTIVO'
  }];



  years: number[] = [];

  fecha_final_dia!: Date;
  meses: IMes[] = [
    { num_mes: 0, nombre: "TODOS LOS MESES" },
    { num_mes: 1, nombre: "Enero" },
    { num_mes: 2, nombre: "Febrero" },
    { num_mes: 3, nombre: "Marzo" },
    { num_mes: 4, nombre: "Abril" },
    { num_mes: 5, nombre: "Mayo" },
    { num_mes: 6, nombre: "Junio" },
    { num_mes: 7, nombre: "Julio" },
    { num_mes: 8, nombre: "Agosto" },
    { num_mes: 9, nombre: "Septiembre" },
    { num_mes: 10, nombre: "Octubre" },
    { num_mes: 11, nombre: "Noviembre" },
    { num_mes: 12, nombre: "Diciembre" }];

  selectedMesReporte1: IMes = {
    num_mes: -1,
    nombre: ""
  }
  anioReporte1: any;

  imp_total_general_propeccion: number = 0;
  imp_total_general: number = 0;
  imp_interes: number = 0;
  dsppagar: boolean = false;
  id_semana: number = 0;
  cod_chofer_pagar: number = 0;
  nombre_completo_pagar: string = '';
  imp_ahorrado_pagar: number = 0;
  fecha_del_dia: any = null;
  fecha_minus3 !: Date;
  fecha_dia_real: any = null;
  loading_reporte1: boolean = false
  num_anio: number = 0;
  ButtonExportExcel: boolean = false
  dbsReportes: boolean = false;
  suma_importe: number = 0;

  constructor(private auth: AuthService, private confirmationService: ConfirmationService, private messageService: MessageService,
    private cajaahorroService: CajaahorroService,
    private semanaService: SemanaService,
    private bonoaguinaldoService: BonoaguinaldoService,
    private ciudadhmService: CiudadhmService) {
    this.cargarInformacionUsuario();
    this.dataForm.controls['cod_chofer'].valueChanges.subscribe(data => {

      if (data !== null) {

        this.obtenerInformacionChofer(data);
      } else {
        // this.dataForm.controls['id_ciudad_hm'].setValue(0);
        // this.dataForm.controls['id_ciudad_principal'].setValue(0);
      }
    });
  }

  initializeYears(): void {
    const currentYear = new Date().getFullYear();
    for (let year = currentYear; year >= currentYear - 5; year--) {
      this.years.push(year);
    }
  }
  obtenerInformacionChofer(cod_chofer: number): IColaboradoresActivos {
    let data: IColaboradoresActivos = {
      cod_chofer: 0,
      nombre_completo: '',
      id_ciudad_hm: 0,
      id_ciudad_principal: 0
    };
    if (this.lstTodosColaboradores.length > 0) {

      let data = this.lstTodosColaboradores.find((valor) => valor.cod_chofer == cod_chofer);
      if (data != undefined) {

        this.dataForm.controls['id_ciudad_hm'].setValue(data.id_ciudad_hm);
        this.dataForm.controls['id_ciudad_principal'].setValue(data.id_ciudad_principal);
      }
    }
    return data;
  }

  //  getTargetDescription(data: IColaboradoresActivos) {
  //   return this.lstCiudad.filter((x)=> x.id==data.id_ciudad_hm)[0].nombre;
  // }

  ngOnInit(): void {
    this.cargarListadodeCiudades();
    this.cargarListadodeColaboradores();
    this.inicializarFechas();
    this.cargarListadoDeSemanasAnio(this.anio);
    this.cargaInicial();
    this.initializeYears();
    this.num_anio = new Date().getFullYear();
  }

  cargarListadoDeSemanasAnio(anio:any) {
    let anio_cast: any = 0;
    if (anio instanceof Date) {
      // will execute
      anio_cast = anio.getFullYear();
    } else {
      anio_cast = anio;
    }
    this.lstSemanas = [];
    this.semanaService.listarSemanas(anio_cast).subscribe((resp) => {
      this.lstSemanas = resp;
      setTimeout(() => {
      },
        600);
    },
      (error) => {
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> al cargar Información. Contacte al Administrador.');
      });
  }
  getTargetDescription(ruta: ISemanasHappyMile) {
    let fec_inicio = new Date(ruta.fec_inicio);
    let fec_fin = new Date(ruta.fec_fin);
    return this.formatearFechas(fec_inicio) + ' | ' + this.formatearFechas(fec_fin);
  }
  formatearFechas(fecha: Date): string {
    let fecha_Cast = '';
    var getYear = new Date(fecha).toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date(fecha).toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date(fecha).toLocaleDateString('es-MX', { day: '2-digit' });
    fecha_Cast = getDay + '/' + getMonth + '/' + getYear;
    return fecha_Cast;
  }

  cargarListadodeCiudades() {
    this.ciudadhmService.getListadoCiudades().subscribe((resp) => {
      this.lstCiudad = resp;
    }, (error) => {
      this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Listar Ciudades>', detail: 'Contacte al Administrador del Sitio' });
    });
  }

  cargarListadodeColaboradores() {
    this.cajaahorroService.cargarInformacionColaboradoresActivos().subscribe((resp) => {
      this.lstTodosColaboradores = resp;
    }, (error) => {
      this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Listar Colaboradores>', detail: 'Contacte al Administrador del Sitio' });
    });
  }

  cargaInicial() {

    this.loading = true;
    this.bonoaguinaldoService.getListadoCiudadesPrincipales().subscribe((resp) => {
      this.lstCiudadPrincipal = resp.slice();
      this.lstCiudadPrincipalRpt= resp.slice();
      this.lstCiudadPrincipalRpt.push({ id: 0, nombre: 'TODAS LAS CIUDADES', clave_estado: '' })
      setTimeout(() => {
        this.loading = false;

      }, 1000);
    },
      (error) => {
        this.loading = false;
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Ciudades');
      });

  }

  cargarInformacionUsuario() {
    this.user = this.auth.user;
  }

  cargarInformacion(num_anio: any) {
    this.imp_total_general = 0
    this.imp_total_general_propeccion =0;
    this.imp_interes =0;
    let anio_cast: any = 0;
    if (num_anio instanceof Date) {
      // will execute
      anio_cast = num_anio.getFullYear();
    } else {
      anio_cast = num_anio;
    }
    if (anio_cast == null || typeof anio_cast == 'undefined') {
      let mensaje = 'Seleccione un Año para mostrar Información';
      this.mostrarDialogoInformativoSinInformacion(mensaje);
    } else {
      //this.anio = anio_cast;
      this.cargarListadoDeSemanasAnio(anio_cast)
      this.loading = true;
      this.cajaahorroService.cargarInformacionCajaAhorro(anio_cast).subscribe((resp) => {
        this.lstColaboradores = resp;
        this.lstTodosColaboradores=[];
        this.cargarListadodeColaboradores();
        if (this.lstColaboradores.length === 0) {
          this.loading = false;
          this.mostrarDialogoInformativoSincontacteadmi('<b>No Existe</b> Registros para la Fecha seleccionada');
        }
        else{
          this.imp_total_general = 0;
          this.imp_total_general_propeccion = 0;
          this.imp_interes = 0;
          // Iterar sobre la lista y sumar los valores de los campos
          this.lstColaboradores.forEach((resp) => {
            this.imp_total_general += resp.imp_ahorrado;
            this.imp_total_general_propeccion += resp.imp_prospeccion
            this.imp_interes += resp.imp_interes

          })
          setTimeout(() => {
            this.loading = false;
          }, 800);
        };
      },
        (error) => {
          this.loading = false;
          this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Cargar Caja de Ahorro>', detail: 'Contacte al Administrador del Sitio' });
        });
    }
  }

  guardarInformacion() {
    let choferSeleccionado = this.lstTodosColaboradores.find(chofer => chofer.cod_chofer === this.dataForm.controls['cod_chofer'].value);
    let nombreChofer = choferSeleccionado ? choferSeleccionado.nombre_completo : '';
    let data_enviar: ICajaAhorroEnviar = {
      cod_chofer: this.dataForm.get('cod_chofer')?.value,
      id_ciudad_hm: this.dataForm.get('id_ciudad_hm')?.value,
      id_ciudad_principal: this.dataForm.get('id_ciudad_principal')?.value,
      imp_ahorro: this.dataForm.get('imp_ahorro')?.value,
      fecha_registro: this.fecha_dia,
      clv_activo: 0,
      cod_usuario_registro: this.user.cod!
    };

    this.confirmationService.confirm({
      message: '¿Está seguro que desea guardar la informacion de la <b>CAJA DE AHORRO</b> del Colaborador <b>' + nombreChofer + '</b>?',
      header: 'Confirmación de edición de estatus',
      icon: 'pi pi-info-circle',
      accept: () => {
        if (this.clv_operacion == 1) {
          this.cajaahorroService.agregar(data_enviar).subscribe((resp) => {
            this.messageService.add({ key: 'msj', severity: 'success', summary: 'Registro Exitoso', detail: resp.messageDetails });
            this.cargarInformacion(this.anio);
            this.cargarListadodeColaboradores();
            this.cancelarModal();
          }, (error) => {
            if (error.error && error.error.message) {
              this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Agregar Caja de Ahorro>', detail: error.error.message });
            } else {
              this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Agregar Caja de Ahorro>', detail: 'Contacte al Administrador del Sitio' });
            }
          });
        } else if (this.clv_operacion == 2) {
          this.cajaahorroService.editar(data_enviar).subscribe((resp) => {
            this.messageService.add({ key: 'msj', severity: 'success', summary: 'Registro Exitoso', detail: 'La información se registró de forma correcta' });
            this.cargarInformacion(this.anio);
            this.cancelarModal();
          }, (error) => {
            if (error.error && error.error.message) {
              this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Editar Caja de Ahorro>', detail: error.error.message });
            } else {
              this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Editar Caja de Ahorro>', detail: 'Contacte al Administrador del Sitio' });
            }
          });
        }
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
          case ConfirmEventType.CANCEL:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
        }
      }
    });
  }


  corteSemanal() {
    this.dsppagosemanal = true;
    this.texto_operacion = 'Caja de ahorro semanal';
  }
  registrarAhorro(clv_operacion: number) {
    this.clv_operacion = 1;
    this.texto_operacion = 'Nuevo Registro';
    this.dataForm.get('cod_chofer')!.enable();
    this.dspDialogo = true;
  }

  mostrarModalEditar(clv_operacion: number, data: ICajaAhorro) {
    //let eve = event.value;
    //this.lstCiudad2 = [];
    let choferSeleccionado = this.lstColaboradores.find(chofer => chofer.cod_chofer === data.cod_chofer);
    if (choferSeleccionado) {

      this.dspDialogo = true;
      this.clv_operacion = 2;
      this.texto_operacion = 'Editar Registro';

      //Deshabilitar Cambiar de Empleado
      this.dataForm.get('cod_chofer')!.disable();
      this.dataForm.controls['cod_chofer'].setValue(choferSeleccionado.cod_chofer);
      this.dataForm.controls['imp_ahorro'].setValue(choferSeleccionado.imp_ahorro);
      this.dataForm.controls['id_caja_ahorro'].setValue(choferSeleccionado.id_caja_ahorro);
      this.dataForm.controls['id_ciudad_hm'].setValue(choferSeleccionado.id_ciudad_hm);
    }
  }


  confirmarcambiarestatus(id_caja_ahorro: number, data: ICajaAhorro) {
    let clv_estatus_original: number = data.clv_activo;
    let texto_desactivar: string = data.clv_activo == 1 ? 'DESACTIVAR' : 'ACTIVAR';
    let texto_desactivar_header: string = data.clv_activo == 1 ? 'Desactivar' : 'Activar';
    this.confirmationService.confirm({
      message: '¿Esta seguro que desea <b>' + texto_desactivar + '</b> el registro de Ahorro de <b>' + data.nombre_completo + '</b> ?',
      header: 'Confirmación de ' + texto_desactivar_header,
      icon: 'pi pi-info-circle',
      accept: () => {
        data.clv_activo = data.clv_activo == 1 ? 0 : 1;
        this.guardarInformacionDesactivar(data);
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            data.clv_activo = clv_estatus_original;
            break;
          case ConfirmEventType.CANCEL:
            data.clv_activo = clv_estatus_original;
            break;
        }
      }
    });
  }




  guardarInformacionDesactivar(data: ICajaAhorro) {
    let data_enviar: ICajaAhorroEnviar = {
      cod_chofer: data.cod_chofer,
      id_ciudad_hm: data.id_ciudad_hm,
      id_ciudad_principal: data.id_ciudad_principal,
      imp_ahorro: data.imp_ahorro,
      fecha_registro: data.fecha_registro.substring(0, 10),
      clv_activo: data.clv_activo,
      cod_usuario_registro: this.user.cod!
    };
    this.cajaahorroService.estatusAhorro(data_enviar).subscribe((resp) => {
      this.cargarInformacion(this.anio);
      this.messageService.add({ key: 'msj', severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se registro de forma correcta' });
    },
      (error) => {
        this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Editar Estatus>', detail: 'Contacte al Administrador del Sitio' });
      });
  }





  consultaHistorico() {

    if (!this.selectedSemana || !this.selectedSemana.id_semana || this.id_ciudad_principal.id == 0) {
      this.mostrarDialogoInformativoSincontacteadmi('<b>Informativo</b> Debe seleccionar una semana y ciudad para poder bajar la caja de ahorro');
    } else {
      this.confirmationService.confirm({
        message: '¿Está seguro de generar la <b>CAJA DE AHORRO</b> de la semana <b>' + this.selectedSemana.id_semana + '</b> de todos los colaboradores <b>Activos</b>?',
        header: 'Confirmación de edición de estatus',
        icon: 'pi pi-info-circle',
        accept: () => {
          const codUsuario = this.user.cod == undefined ? '0999' : this.user.cod;
          this.clv_his = 1;
          this.cajaahorroService.postbajarahorro(this.selectedSemana.id_semana, this.selectedSemana.anio, codUsuario, this.clv_his, this.id_ciudad_principal.id)
            .subscribe(
              (resp) => {
                if (resp.clv_estatus === 1) {
                  this.messageService.add({ key: 'msj', severity: 'success', summary: 'Registro Exitoso', detail: resp.messageDetails });
                  this.dsppagosemanal = false;
                  this.cargarInformacion(this.anio);
                }
                if (resp.clv_estatus === 3) {
                  setTimeout(() => { // Agregar un pequeño retraso con setTimeout
                    this.confirmationService.confirm({
                      message: 'La <b>CAJA DE AHORRO</b> de la Semana <b>' + this.selectedSemana.id_semana + '</b> ya fue bajada anteriormente. <b>¿Desea volver a bajarla?</b> De ser así, se borrarán todos los registros bajados anteriormente y se ingresarán todos los cambios',
                      header: 'Confirmación de edición de estatus',
                      icon: 'pi pi-info-circle',
                      accept: () => {
                        this.clv_his = 2;
                        this.cajaahorroService.postbajarahorro(this.selectedSemana.id_semana, this.selectedSemana.anio, codUsuario, this.clv_his, this.id_ciudad_principal.id)
                          .subscribe(
                            (resp) => {
                              this.messageService.add({ key: 'msj', severity: 'success', summary: 'Registro Exitoso', detail: resp.messageDetails });
                              this.cargarInformacion(this.anio);
                              this.dsppagosemanal = false;
                            },
                            (error) => {
                              console.error('Error al volver a bajar caja de ahorro:', error);
                              this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: 'Ocurrió un error al procesar la solicitud.' });
                            }
                          );
                        this.cargarInformacion(this.anio);
                      },
                      reject: () => {
                        this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
                      }
                    });
                  }, 800); // 100 milisegundos de retraso
                }

              },
              (error) => {
                console.error('Error al bajar caja de ahorro:', error);
                this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: 'Ocurrió un error al procesar la solicitud.' });
              }
            );
        },
        reject: () => {
          this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
        }
      });
    }
  }


  pagoAnual() {

    // this.confirmationService.confirm({
    //   message: 'Esta seguro que desea pagar la caja de ahorro del colaborador <b>anual</b> de <b>todos</b> los colaboradores a los cuales se les otorgara el porcentaje correspondiente ?',
    //   header: 'Confirmación de ' ,
    //   icon: 'pi pi-info-circle',
    //   accept: () => {
    //     this.cajaahorroService.pagoanual( this.anio,this.user.cod == undefined ? '0999' : this.user.cod).subscribe((resp)=>{
    //       this.messageService.add({ key: 'msj', severity: 'success', summary: 'Registro Exitoso', detail: resp.messageDetails });
    //       this.cargarInformacion(this.anio);
    //     })

    //   },
    //   reject: () => {
    //     this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
    //   }
    // });
  }

  cancelarModalanual() {
    this.dsppagosemanal = false

    this.id_ciudad_principal = { id: 0, clave_estado: '', nombre: '' };
    this.selectedSemana = {
      idx: 0,
      id_semana: 0,
      nom_semana: '',
      anio: 0,
      fec_inicio: '',
      fec_fin: '',
      clv_activo: 0
    };
  }

  pagar(data: ICajaAhorro) {
    this.dsppagar = true
    this.texto_operacion = 'Retirar caja de ahorro';
    this.cod_chofer_pagar = data.cod_chofer
    this.nombre_completo_pagar = data.nombre_completo
    this.imp_ahorrado_pagar = data.imp_ahorrado
    this.num_anio = new Date().getFullYear();
    setTimeout(() => {
      this.cajaahorroService.gethisahorro(data.cod_chofer).subscribe(
        (resp) => {
          this.lstahorro = resp;
          this.loading = false;
        },
        (error) => {
          console.error('Error al obtener historial de ahorro:', error);
          this.loading = false;
        }
      );
    }, 150);
  }



  cancelarpagar() {
    this.dsppagar = false
    this.num_anio = new Date().getFullYear();
    this.texto_operacion = '';
    this.cod_chofer_pagar = 0;
    this.nombre_completo_pagar = '';
    this.imp_ahorrado_pagar = 0;
    this.selectedSemana = {
      idx: 0,
      id_semana: 0,
      nom_semana: '',
      anio: 0,
      fec_inicio: '',
      fec_fin: '',
      clv_activo: 0
    };

    this.lstahorro =[];

  }

  pagarahorro() {

    let imp = this.lstahorro.find(chofer => chofer.cod_chofer === this.cod_chofer_pagar);


    // Encontrar la suma de imp_ahorrado donde id_semana <= this.selectedSemana.id_semana
    this.suma_importe = this.lstahorro
      .filter(chofer => chofer.id_semana <= this.selectedSemana.id_semana)
      .reduce((total, chofer) => total + chofer.imp_ahorro, 0);



    this.clv_pagado = 2
    this.confirmationService.confirm({
      message: 'Esta seguro que desea retirar la caja de ahorro del colaborador <b>' + this.nombre_completo_pagar + '</b> con un importe de $<b>' + this.suma_importe + 'MXN</b>?',
      header: 'Confirmación de ',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.cajaahorroService.postpagarahorro(this.cod_chofer_pagar, this.clv_pagado, this.num_anio, this.user.cod == undefined ? '0999' : this.user.cod, this.selectedSemana.id_semana).subscribe((resp) => {
          if (resp.clv_estatus === -2) {
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Advertencia',  detail: resp.messageDetails});
          } else {
            this.messageService.add({
              key: 'msj',severity: 'success', summary: 'Registro Exitoso',detail: resp.messageDetails});
          } this.cargarInformacion(this.num_anio);
          this.cancelarpagar();
        })
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
      }
    });
  }


  cancelarModal() {
    this.dspDialogo = false;
    this.texto_operacion = '';
    this.nombre_registro = '';
    this.dataForm.reset();
    this.clv_operacion = 0;
    this.dataForm.get('cod_chofer')!.enable();

    this.clv_his = 0;
  }

  cancelarVentanaModal() {
    this.dsphistorico = false;
    this.nombre_completo = '';
    this.cod_chofer = 0;
    this.lstahorro = [];

  }






  mostrardesgloce(data: Iahorro) {
    this.loading = true;
    let imp = this.lstColaboradores.find(chofer => chofer.cod_chofer === data.cod_chofer);
    if (imp) {
      this.imp_total = imp.imp_ahorrado;
    } else {
      this.imp_total = 0;
    }
    this.nombre_completo = data.nombre_completo;
    this.cod_chofer = data.cod_chofer;
    this.dsphistorico = true;

    setTimeout(() => {
      this.cajaahorroService.gethisahorro(data.cod_chofer).subscribe(
        (resp) => {
          this.lstahorro = resp;
          this.loading = false;
        },
        (error) => {
          console.error('Error al obtener historial de ahorro:', error);
          this.loading = false;
        }
      );
    }, 150);
  }



  /*Filtro General para la informacion de tabla*/
  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  cancelarMensajeConfirmacion() {
    this.messageService.clear('confirm');
    this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });

  }

  confirmarMensaje() {
    this.messageService.clear('confirm');
    this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
  }



  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario;
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }
  mostrarDialogoInformativoSincontacteadmi(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario;
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }

  inicializarFechas() {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    this.fecha_dia = dateFormat;
    this.anio = getYear;
  }



  abrirModalReportes() {

    this.num_anio = this.anio

    this.dbsReportes = true;
    this.inicializarFechasFormateadas();
  }
  cancelarModalReportes() {

    this.dbsReportes = false;
    // Inicio Reporte 1
    this.selectedMesReporte1 = {
      num_mes: 0,
      nombre: ''
    }
    this.anioReporte1 = null;
    this.num_anio = new Date().getFullYear();
    this.id_ciudad_principal_rpt = 0;
    this.selectedSemana = {
      idx: 0,
      id_semana: 0,
      nom_semana: '',
      anio: 0,
      fec_inicio: '',
      fec_fin: '',
      clv_activo: 0
    };
  }



  // metodo para exportar a excel
  exportarExcel( id_ciudad_principal_rpt: number) {
    // this.num_anio = this.fecha_filtrada;
    this.loading_reporte1 = true;
    if (this.num_anio == null || this.id_ciudad_principal_rpt == null ||this.selectedSemana.id_semana ==null ||this.selectedSemana.id_semana ==0) {

      this.mostrarDialogoInformativo('<b>Aviso</b> Es necesario seleccionar la <b> semana</b> de Operación a Filtrar');
      this.loading_reporte1 = false;
    } else {
      this.cajaahorroService.rptCaa(this.selectedSemana.anio, this.selectedSemana.id_semana, this.id_ciudad_principal_rpt)
        .subscribe((resp: ICajaAhorro[]) => {
          if (resp.length === 0 || typeof resp === 'undefined') {
            this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion del Catalogo para exportar(Excel)');
            this.loading_reporte1 = false;
          } else {
            import("xlsx").then(xlsx => {
              const worksheet = xlsx.utils.json_to_sheet(resp);
              const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
              const excelBuffer: any = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array"
              });

              this.saveAsExcelFile(excelBuffer, "ReporteCajaAhorro" + '_' + this.selectedSemana.anio + '_' + this.selectedSemana.nom_semana);
            });
            setTimeout(() => {
              this.ButtonExportExcel = false;
              this.loading_reporte1 = false;

            }, 1000);
          }
        },
          (error) => {
            this.ButtonExportExcel = false;
            this.loading_reporte1 = false;

            this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: 'Error en emitir el reporte de Estatus de Facturación.' });
          });
    }
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    //var FileSaver = require('file-saver');
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName + "_exportar_" + dateFormat + EXCEL_EXTENSION);
  }


  inicializarFechasFormateadas() {
    //hacer globales
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    this.fecha_dia = dateFormat;
    this.fecha_del_dia = new Date(dateFormat + 'T00:00:00');
    //Fecha que no se modifica
    this.fecha_final_dia = new Date(dateFormat + 'T00:00:00');
    //Fecha para restar los 3 dias
    let minus3days = new Date(this.fecha_final_dia);
    this.fecha_minus3 = new Date(minus3days.setDate(new Date(minus3days).getDate() - 4));
    //Fecha del dia real, no se mueve
    this.fecha_dia_real = dateFormat;
    //Reportes
    let num_mes: number = +getMonth;
    this.meses.map((mes) => {
      if (mes.num_mes == num_mes) {
        this.selectedMesReporte1.nombre = mes.nombre;
        this.selectedMesReporte1.num_mes = mes.num_mes;
      }
    });
    this.anioReporte1 = getYear;
  }




  mostrarDialogoInformativo(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '.';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
        this.messageService.clear();
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
        this.messageService.clear();
      },
      key: "sinInformacionDialog"
    });
    this.loading = false;
    this.messageService.clear();
  }


}
