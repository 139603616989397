import { Component } from '@angular/core';
import { IResultadoGeneral } from './../models/resultadogeneral';
import { ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { ConfirmEventType, ConfirmationService, MessageService } from 'primeng/api';
import { saveAs } from 'file-saver';
import ICredencialUsuario from '../models/credencial';
import { ICiudad } from '../models/ciudad';
import { InombreCompletoChofer } from '../models/nombreCompletoChofer';
import { IMes } from '../models/mes';
import { IrptEstatusForaneo } from '../models/rptEstatusForaneo';
import { IEstatusForaneo } from '../models/estatusforaneo';
import { BonoaguinaldoService } from '../services/bonoaguinaldo.service';
import { ICiudadPrincipalHM } from '../models/ciudadprincipal';
import { ISemanasHappyMile } from '../models/semanas';
import { SemanaService } from '../services/semana.service';
import { PrimavacacionalService } from '../services/primavacacional.service';
import { IPrima } from '../models/primavacacional';
import { IPostPrimaVacacional } from '../models/postprimavacacional';
import { AuthService } from '../services/auth.service';


@Component({
  selector: 'app-primavacacional',
  templateUrl: './primavacacional.component.html',
  styleUrls: ['./primavacacional.component.scss']
})
export class PrimavacacionalComponent {
  @ViewChild('dt1') dt!: Table;
  lstCiudad: ICiudadPrincipalHM[] = [];
  lstChoferes: InombreCompletoChofer[] = [];
  lstchofCopy: InombreCompletoChofer[] = [];
  lstEstatusForaneo: IEstatusForaneo[] = [];
  lstPrimaVacacional: IPrima [] = [];
  lstSemanas: ISemanasHappyMile[] = [];


  mssgListaCiudades: string = '';
  selectedSemana: ISemanasHappyMile = {
    idx: 0,
    id_semana: 0,
    nom_semana: '',
    anio: 0,
    fec_inicio: '',
    fec_fin: '',
    clv_activo: 0
  };

  lstrptDescansoFaltas: IrptEstatusForaneo[] = [];
  selectedCiudad: ICiudad = {
    id: 0,
    nombre: '',
    clave_Estado: '',
    id_ciudad_principal: 0
  };

  num_anio: number= 0;
  years: number[] = [];
  loading: boolean = false;

  //variables usadas para la fecha, fueron casteadas ya que presentaban errorees
  fecha_hoy: any = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
  dia: any = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
  fecha_filtrada: any = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
  fecha_dia: any = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
  anio: any = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
  fecha_fin: any = null;
  id_ciudad_hm: number = 0;
  id_ciudad_principal: number = 0;
  dspCliente: boolean = false;
  texto_operacion: string = '';
  num_operacion: number = 0;
  clv_operacion: number = 0;
  habilitar_agregar: boolean = false;
  imp_aguinaldo_total : number = 0;
  imp_dias_extra_total : number = 0;
  imp_total_genral : number = 0;

  resultado: IResultadoGeneral = {
    clv_estatus: 0,
    messageDetails: ''
  };

  dsblstCiudades: boolean = true;
  dsblstColaboradores: boolean = true;
  dsblstCiudadesAdscritas: boolean = true;
  // variables de reportes--------------------------
  dbsReportes: boolean = false;
  fecha_fin_filtro: any = null;
  ButtonExportExcel: boolean = false;
  //Fecha de Dias Atras
  fecha_del_dia: any = null;
  btnHabilitar: boolean = false;
  mostrar_texto_fechas: number = 0;
  fecha_final_dia!: Date;
  //Fecha para restar 3 dias
  fecha_minus3 !: Date;
  fecha_dia_real: any = null;
  checkboxValue: boolean = false;
  checked: boolean = false;
  selectedMesReporte1: IMes = {
    num_mes: -1,
    nombre: ""
  }

  anioReporte1: any;
  loading_reporte1: boolean = false;
  meses: IMes[] = [
    { num_mes: 1, nombre: "Enero" },
    { num_mes: 2, nombre: "Febrero" },
    { num_mes: 3, nombre: "Marzo" },
    { num_mes: 4, nombre: "Abril" },
    { num_mes: 5, nombre: "Mayo" },
    { num_mes: 6, nombre: "Junio" },
    { num_mes: 7, nombre: "Julio" },
    { num_mes: 8, nombre: "Agosto" },
    { num_mes: 9, nombre: "Septiembre" },
    { num_mes: 10, nombre: "Octubre" },
    { num_mes: 11, nombre: "Noviembre" },
    { num_mes: 12, nombre: "Diciembre" }];


  //Permisos
  user: ICredencialUsuario = {};
  constructor(

    private bonoaguinaldoService: BonoaguinaldoService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private semanaService: SemanaService,
    private primavacacionalService:PrimavacacionalService,
    private auth: AuthService
    ) {

    this.initializeYears();
    this.num_anio = new Date().getFullYear();

    this.cargarInformacionUsuario();
  }

  ngOnInit(): void {
    this.cargaInicial();
    this.inicializarFechas();
    this.cargarListadoDeSemanasAnio_cast(this.anio_filtro)
   // this.cargarListadoDeSemanasAnio();
  }

  cargarInformacionUsuario() {
    this.user = this.auth.user;
  }


  inicializarFechas() {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    // this.anio = getYear;
    this.anio_filtro = getYear;
  }
  initializeYears(): void {
    const currentYear = new Date().getFullYear();
    for (let year = currentYear; year >= currentYear - 5; year--) {
      this.years.push(year);
    }
  }


// metodos para eñ filtro para fechas
  getTargetDescription(ruta: ISemanasHappyMile) {
    let fec_inicio = new Date(ruta.fec_inicio);
    let fec_fin = new Date(ruta.fec_fin);
    return this.formatearFechas(fec_inicio) + ' | ' + this.formatearFechas(fec_fin);
  }

  formatearFechas(fecha: Date): string {
    let fecha_Cast = '';
    var getYear = new Date(fecha).toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date(fecha).toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date(fecha).toLocaleDateString('es-MX', { day: '2-digit' });
    fecha_Cast = getDay + '/' + getMonth + '/' + getYear;
    return fecha_Cast;
  }

  // cargarListadoDeSemanasAnio() {
  //   this.lstSemanas = [];
  //   this.semanaService.listarSemanas(this.anio).subscribe((resp) => {
  //     this.lstSemanas = resp;
  //     setTimeout(() => {
  //     },
  //       600);
  //   },
  //     (error) => {
  //       this.mostrarDialogoInformativoSinInformacion('<b>Error</b> al cargar Información. Contacte al Administrador.');
  //     });
  // }


  anio_filtro: any;
  cargarListadoDeSemanasAnio_cast(anio_filtro: any) {
    this.imp_total_genral =0;
    let anio_cast: any = 0;
    if (anio_filtro instanceof Date) {
      // will execute
      anio_cast = anio_filtro.getFullYear();
    } else {
      anio_cast = anio_filtro;
    }
    this.num_anio = anio_cast
    this.lstSemanas = [];
    this.lstPrimaVacacional=[];
    this.loading=true;
    this.semanaService.listarSemanas(anio_cast).subscribe((resp) => {
      this.lstSemanas = resp;
      setTimeout(() => {
        this.loading =false;
      },
        300);
    },
      (error) => {
        this.loading=false;
        this.mostrarDialogoInformativoSinInformacion('<b>Error al cargar Semanas HM</b>.');
      });
  }

  // -----------filtra y carga la informacion ----
  FiltrarFechaDiariaRuta() {
    this.imp_total_genral =0;
    this.loading = true;
    if (this.id_ciudad_hm == null || typeof this.id_ciudad_hm === 'undefined' || this.selectedSemana == null || typeof this.selectedSemana === 'undefined') {
      this.mostrarDialogoInformativoSinInformacion('<b>Informativo</b> Debe de seleccionar una semana para poder filtrar');
      this.loading = false;
    } else {
      this.primavacacionalService.getListadoPrimaVacacional(this.num_anio, this.id_ciudad_hm, this.selectedSemana.id_semana).subscribe(
        (resp) => {
          this.lstPrimaVacacional = resp;
          if (this.lstPrimaVacacional.length === 0) {
            this.loading = false;
            this.mostrarDialogoInformativoSinInformacion('<b>No Existe</b> registros para la Semana y Año proporcionados');
          } else {
            this.imp_total_genral = 0;
            // Iterar sobre la lista y sumar los valores de los campos
            this.lstPrimaVacacional.forEach((item) => {
              this.imp_total_genral += item.imp_total;
            });
            // Finalizar el loading después de 800 ms2
            setTimeout(() => {
              this.loading = false;
            }, 800);
          }
        },
        (error) => {
          this.mostrarDialogoInformativoSinInformacion('<b>ERROR</b> en Cargar Movimientos ');
          this.loading = false;
        }
      );
    }
  }




  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }



  // cargar lista inicial de las ciudades para los dropdown  get
  cargaInicial() {
    this.dsblstCiudades = true;
    this.loading = true;
    this.primavacacionalService.getListadoCiudadesPrincipales().subscribe((resp) => {
      this.lstCiudad = resp;
      this.lstCiudad.push({ id: 0, nombre: 'TODAS LAS CIUDADES', clave_estado: ''})
      setTimeout(() => {
        this.loading = false;
        this.dsblstCiudades = false;
      }, 1000);
    },
      (error) => {
        this.loading = false;
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Ciudades');
      });

  }


  saveAsExcelFile(buffer: any, fileName: string): void {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName + "_exportar_" + dateFormat + EXCEL_EXTENSION);
  }



  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '. <b>Contacte al Administrador.</b>';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }


  // ----------------reportes  ------------
  abrirModalReportes() {
    this.dbsReportes = true;
    this.inicializarFechasFormateadas();
  }


  cancelarModalReportes() {
    this.dbsReportes = false;
    // Inicio Reporte 1
    this.selectedMesReporte1 = {
      num_mes: 0,
      nombre: ''
    }
    this.anioReporte1 = null;
    this.selectedCiudad = {
      id: -1,
      nombre: '',
      clave_Estado: '',
      id_ciudad_principal: 0
    };
    this.fecha_fin = null;
  }


  AprobarPrima(data: IPrima) {
    let resultado: IResultadoGeneral;
    let data_enviar: IPostPrimaVacacional = {
      cod_chofer: data.cod_chofer,
      nom_completo: data.nom_completo,
      id_puesto: data.id_puesto,
      desc_puesto: data.desc_puesto,
      id_tipo_transporte: data.id_tipo_transporte,
      desc_tipo_transporte: data.desc_tipo_transporte,
      id_ciudad_hm: data.id_ciudad_hm,
      desc_ciudad_hm: data.desc_ciudad_hm,
      id_ciudad_principal: data.id_ciudad_principal,
      desc_ciudad_principal: data.desc_ciudad_principal,
      imp_sueldo_diario: data.imp_sueldo_diario,
      fec_registro: data.fec_registro,
      fec_registro_resp: data.fec_registro_resp,
      id_semana: data.id_semana,
      num_anio: data.num_anio,
      num_dias_ley: data.num_dias_ley,
      porc_prima: data.porc_prima,
      imp_total: data.imp_total,
      cod_usuario: this.user.cod == undefined ? '0999' : this.user.cod,
      clv_activo: data.clv_activo,
      clv_operacion: this.clv_operacion = 1,
    };

    this.confirmationService.confirm({
      message: '¿Está seguro que aprobar la <b>PRIMA VACACIONAL</b>  del Colaborador <b>' + data.nom_completo + '</b>?',
      header: 'Confirmación de edición de estatus',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.primavacacionalService.postPrimaVacacional(data_enviar).subscribe((resp) => {
          // Verificamos si la respuesta es una lista y tomamos el primer elemento
          const resultado = Array.isArray(resp) ? resp[0] : resp;
          if (resultado.clv_estatus == 1) {
              this.messageService.add({ key: 'msj', severity: 'success', summary: 'Exito', detail: 'Se registro correctamente' });
              this.FiltrarFechaDiariaRuta();
          } else if (resultado.clv_estatus == -2) {
              this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: resultado.messageDetails });
          }
      });
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
        }
      }
    });
  }



  EliminarRegistro(data: IPrima){

  let resultado: IResultadoGeneral;
    let data_enviar: IPostPrimaVacacional = {
      cod_chofer: data.cod_chofer,
      nom_completo: data.nom_completo,
      id_puesto: data.id_puesto,
      desc_puesto: data.desc_puesto,
      id_tipo_transporte: data.id_tipo_transporte,
      desc_tipo_transporte: data.desc_tipo_transporte,
      id_ciudad_hm: data.id_ciudad_hm,
      desc_ciudad_hm: data.desc_ciudad_hm,
      id_ciudad_principal: data.id_ciudad_principal,
      desc_ciudad_principal: data.desc_ciudad_principal,
      imp_sueldo_diario: data.imp_sueldo_diario,
      fec_registro: data.fec_registro,
      fec_registro_resp: data.fec_registro_resp,
      id_semana: data.id_semana,
      num_anio: data.num_anio,
      num_dias_ley: data.num_dias_ley,
      porc_prima: data.porc_prima,
      imp_total: data.imp_total,
      cod_usuario: this.user.cod == undefined ? '0999' : this.user.cod,
      clv_activo: data.clv_activo,
      clv_operacion: this.clv_operacion = 0,
    }
    this.confirmationService.confirm({
      message: '¿Está seguro que eliminar  <b>PRIMA VACACIONAL</b>  del Colaborador <b>' + data.nom_completo + '</b>?',
      header: 'Confirmación de edición de estatus',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.primavacacionalService.postPrimaVacacional(data_enviar).subscribe((resp) => {
          // Verificamos si la respuesta es una lista y tomamos el primer elemento
          const resultado = Array.isArray(resp) ? resp[0] : resp;
          if (resultado.clv_estatus == 1) {
              this.messageService.add({ key: 'msj', severity: 'success', summary: 'Exito', detail: 'Se registro correctamente' });
              this.FiltrarFechaDiariaRuta();
          } else if (resultado.clv_estatus == -2) {
              this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: resultado.messageDetails });
          }
      });
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
        }
      }
    });
  }



  inicializarFechasFormateadas() {
    //hacer globales
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    this.fecha_dia = dateFormat;
    this.fecha_del_dia = new Date(dateFormat + 'T00:00:00');
    //Fecha que no se modifica
    this.fecha_final_dia = new Date(dateFormat + 'T00:00:00');
    //Fecha para restar los 3 dias
    let minus3days = new Date(this.fecha_final_dia);
    this.fecha_minus3 = new Date(minus3days.setDate(new Date(minus3days).getDate() - 4));
    //Fecha del dia real, no se mueve
    this.fecha_dia_real = dateFormat;
    //Reportes
    let num_mes: number = +getMonth;
    this.meses.map((mes) => {
      if (mes.num_mes == num_mes) {
        this.selectedMesReporte1.nombre = mes.nombre;
        this.selectedMesReporte1.num_mes = mes.num_mes;
      }
    });
    this.anioReporte1 = getYear;
  }


  // metodo para exportar a excel
  exportarExcel(num_anio: number, id_ciudad_principal : number ) {
   // this.num_anio = this.fecha_filtrada;
    this.loading_reporte1 = true;
    if ( num_anio == null || id_ciudad_principal == null) {
      this.mostrarDialogoInformativo('<b>Aviso</b> Es necesario seleccionar <b>Fecha</b> de Operación a Filtrar');
    } else {
      this.bonoaguinaldoService.rptBonoAguinaldo(this.num_anio, this.id_ciudad_principal)
        .subscribe((resp: IPrima[]) => {
          if (resp.length === 0 || typeof resp === 'undefined') {
            this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion del Catalogo para exportar(Excel)');
            this.loading_reporte1 = false;
          } else {
            import("xlsx").then(xlsx => {
              const worksheet = xlsx.utils.json_to_sheet(resp);
              const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
              const excelBuffer: any = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array"
              });

              this.saveAsExcelFile(excelBuffer, "ReporteAguinaldo" + '_' + num_anio );
            });
            setTimeout(() => {
              this.ButtonExportExcel = false;
              this.loading_reporte1 = false;
            }, 1000);
          }
        },
          (error) => {
            this.ButtonExportExcel = false;
            this.loading_reporte1 = false;
            this.fecha_fin = null;
            this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: 'Error en emitir el reporte de Estatus de Facturación.' });
          });
    }
  }



  mostrarDialogoInformativo(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '.';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
        this.messageService.clear();
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
        this.messageService.clear();
      },
      key: "sinInformacionDialog"
    });
    this.loading = false;
    this.messageService.clear();
  }


}
