<p-toast position="top-right" key="msj"></p-toast>
<!--<p-messages></p-messages>-->
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="Aceptar" rejectLabel="Cancelar"
  rejectButtonStyleClass="p-button-text">
</p-confirmDialog>

<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
  rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar" [rejectVisible]="false">
</p-confirmDialog>
<div class="d-flex justify-content-center">
  <div class="container-fluid" style="width: 90%;">
      <div class="row mt-5">
          <div class="col-md-12">
              <div class="card card-shadow sinborde">
                  <div class="card-header sinborde" style="background: none; ">
                      <div class="cardicono rounded">
              <i class="pi pi-id-card mt-3" style="font-size: 2rem; color: #fff;"></i>
            </div>
            <header style="left: 11%; top: 5%;">
              <div style="display:inline"> Gestion de</div>
              <div *ngIf="dia.length > 0" style="color:rgb(22, 48, 220);display:inline;" class="ml-3">Vacaciones</div>
            </header>
            <div class="row d-flex justify-content-end">
              <div class="col-md-12">

                <!-- -------------fecha del filtro---------------- -->
                <div class="form-group row mt-5">
                  <div class="col-md-3">
                    <div class="row d-flex justify-content-start">
                      <div class="col-md-5">
                        <label class="col-form-label color-font text-right"
                          style="font-weight: 600; font-family: 'Poppins', sans-serif;">
                          <span class="input-group-addon ml-5"> <i class="pi pi-calendar color-icon mr-4"
                              style="font-size: 2rem; color: #3B82F6;"></i>
                          </span>
                          Año
                        </label>
                      </div>
                      <div class="col-sm-7 mt-3">
                        <select class="form-control" [(ngModel)]="num_anio">
                          <option *ngFor="let year of years" [value]="year">{{year}}</option>
                        </select>

                      </div>
                    </div>
                  </div>
                  <!-- filtro de ciudades -->
                  <div class="col-md-3 mr-3 mt-2">
                    <p-dropdown [(ngModel)]="id_ciudad_hm" [options]="lstCiudad" optionLabel="nombre" optionValue="id"
                      placeholder="Filtrar por Ciudad" [showClear]="false" [disabled]="dsblstCiudades">
                    </p-dropdown>
                  </div>
                  <!-- ----------boton principal para el filtro  inicial------------------- -->
                  <div class="col-md-1 ">
                    <button pButton pRipple type="button" (click)="FiltrarFechaDiariaRuta();" label="Filtrar"
                      icon="pi pi-filter" iconPos="left"></button>
                  </div>
                  <!-- ----------boton principal de los reportes en excel------------------- -->
                  <div class="  col-md-1 ml-6 ">
                    <button type="button" pButton pRipple class="p-button-success " pTooltip="Exportar Catalogo a EXCEL"
                      tooltipPosition="right" (click)="abrirModalReportes()">
                      <i class="pi pi-file-excel"></i> Exportar
                    </button>
                  </div>
                </div>


              </div>
            </div>
            <div class="card-content">
              <div class="row mt-3">
                <!-- informacio  -->
                <p-table #dt1 [value]="lstvacacionesMain" [tableStyle]="{'min-width': '50rem'}"
                  styleClass="p-datatable-sm p-datatable-striped" [scrollable]="true" scrollHeight="480px"
                  responsiveLayout="scroll"
                  [globalFilterFields]="['nom_completo','desc_puesto','desc_ciudad_hm']"
                  [paginator]="true" [rows]="30" [rowsPerPageOptions]="[10,15,20,30,50]" [showCurrentPageReport]="true"
                  [resizableColumns]="false" currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros"
                  [loading]="loading">
                  <ng-template pTemplate="caption">
                    <div class="row d-flex justify-content-end">

                      <div class="col-md-auto">
                        <span class="p-input-icon-left">
                          <i class="pi pi-search" style="margin-right: 5px;"></i>
                          <input pInputText pTooltip="Buscar por: Nombre del colaborador, Ciudad o puesto." type="text"
                            (input)="applyFilterGlobal($event, 'contains')" placeholder="Buscar"
                            style="margin-left: 5px;" />
                        </span>
                      </div>
                    </div>
                    <!-- <div class="row d-flex justify-content-end mt-2 mb-3">
                      <div class="col-md-6">
                      </div>
                      <div class="col-3">
                        <button *ngIf="habilitar_agregar && (validarClvOperacion() || fecha_filtrada == fecha_hoy)"
                          type="button" class="btn btn-primary form-control" (click)="mostrarModalAgregar();"
                          pTooltip="Agregar colaborador a apoyo foraneo" tooltipPosition="top">
                          Agregar apoyo foraneo <i class="pi pi-plus-circle"></i>
                        </button>
                      </div>

                    </div> -->
                  </ng-template>

                  <!-- tabulado -->
                  <ng-template pTemplate="header">
                    <tr>
                      <th pSortableColumn="cod_chofer" class="text-center">#Chofer<p-sortIcon field="cod_chofer"></p-sortIcon></th>
                      <th pSortableColumn="nom_completo" class="text-center">Colaborador<p-sortIcon field="nom_completo"></p-sortIcon></th>
                      <th pSortableColumn="desc_puesto" class="text-center">Puesto<p-sortIcon  field="desc_puesto"></p-sortIcon></th>
                      <th  class="text-center">Ciudad</th>
                      <th pSortableColumn="fec_inicio" class="text-center">Fec.Ingreso<p-sortIcon field="fec_inicio"></p-sortIcon></th>
                      <th pSortableColumn="num_anio" class="text-center">Anio<p-sortIcon field="num_anio"></p-sortIcon></th>
                      <th pSortableColumn="num_dias_ley" class="text-center">Dias por ley<p-sortIcon field="num_dias_ley"></p-sortIcon></th>
                      <th pSortableColumn="num_dias_usados" class="text-center">Días utilizados<p-sortIcon field="num_dias_usados"></p-sortIcon></th>
                      <th pSortableColumn="imp_dia" class="text-center">Días pendientes <p-sortIcon field="imp_dia"></p-sortIcon> </th>

                      <th pSortableColumn="clv_estatus" class="text-center"   style="min-width:80px ;max-width: 80px; ">Estatus<p-sortIcon  field="clv_estatus"></p-sortIcon></th>

                      <th class="text-center">Botón de operaciones</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-data let-index>
                    <tr [ngClass]="{'row-accessories': data.clv_activo ===0}">
                      <td class="text-center"  > <b>{{data.cod_chofer}}</b></td>
                      <td class="text-left pl-5"  ><b>{{data.nom_completo}}</b></td>
                      <td class="text-center" [ngStyle]="{'color': data.desc_puesto === 'DRIVER' ? 'rgb(13,110,253)' : 'orange'}"> <b>{{ data.desc_puesto }}</b>  </td>
                      <td class="text-center"  > <b>{{data.desc_ciudad_hm}}</b></td>
                      <td class="text-center" style="color: rgb(30, 146, 12)"><b>{{ data.fec_alta }}</b></td>
                      <td class="text-center" ><b>{{ data.num_anio}}</b></td>
                      <td class="text-center" style="color: rgb(15, 53, 166)"><b>{{ data.num_dias_ley}}</b></td>
                      <td class="text-center"  style="color: rgb(186, 31, 23)" ><b>{{data.num_dias_usados}}</b></td>
                      <td class="text-center" style="color: rgb(17, 129, 21)"><b>{{data.num_dias_pendientes}}</b></td>
                      <td class="text-center" style="min-width:80 ;max-width: 80;" >
                        <b  [style.color]="getStatusColor(data.clv_estatus)">{{getStatusDescription(data.clv_estatus)}}</b>
                      </td>

                      <td>
                        <!-- *ngIf="validarClvOperacionbtnEdit(data)" -->
                        <div class="d-flex justify-content-around">
                          <button               class="btn btn-primary" pTooltip="Agregar Periodo Vacacional"
                            tooltipPosition="left" (click)="mostrarModalAgregar( data.num_dias_pendientes ,data.nom_completo ,data.cod_chofer);">
                            <i class="pi pi-plus-circle"></i>
                          </button>
                          <div class="ml-2">
                            <button     class="btn btn-link" pTooltip="Gestion de periodos vacacionales"
                              (click)="operacionPeriodoVacacional(data.nom_completo, data.lstvacaciones , data.num_dias_pendientes, data.cod_chofer)">
                              <i class="pi pi-user"></i>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>

                <!-- pie de pagina -->
              </div>
              <div class=" mt-3 d-flex justify-content-center">


                <p class="text-left informativo"  style="display: inline; padding-left: 10px; border-left: 6px solid orange; font-weight: normal; font-size: 0.7rem;"><b>PENDIENTE AUTORIZAR</b> Se encuentra a la espera de autorizacion</p>
                <p class="text-left informativo"  style="display: inline; padding-left: 10px; border-left: 6px solid green;  font-weight: normal; font-size: 0.7rem;"><b>AUTORIZADO</b> Se autorizo el periodo vacacional de forma correcta</p>
                <p class="text-left informativo"  style="display: inline; padding-left: 10px; border-left: 6px solid blue;   font-weight: normal; font-size: 0.7rem;"><b>ACTIVO</b> El perido vacacional se encuentra activo</p>
                <p class="text-left informativo"  style="display: inline; padding-left: 10px; border-left: 6px solid black;  font-weight: normal; font-size: 0.7rem;"><b>FINALIZADO</b> El perido vacacional finalizo de forma correcta</p>
                <!-- <p class="text-left informativo"  style="display: inline; padding-left: 10px; border-left: 6px solid purple; font-weight: normal; font-size: 0.7rem;"><b>AUTORIZADO NO TOMAR VACACIONES</b> El colaborador trabajara durante su perido vacacional</p> -->
                <p class="text-left informativo"  style="display: inline; padding-left: 10px; border-left: 6px solid gray;   font-weight: normal; font-size: 0.7rem;"><b>SIN REGISTROS</b> No cuenta con registros</p>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>


    <!---pdialog de agregar periodo vacacvional--->

    <p-dialog [resizable]="false" [draggable]="false" [scrollTop]="" (onClose)="cancelarVentanaModal()" [(visible)]="dspCliente"
      [style]="{width: '45vw','height':'490px'}" [transitionOptions]="'0ms'">
      <ng-template pTemplate="header">
        {{ texto_operacion}}
      </ng-template>
      <ng-template pTemplate="content">
        <div class="card-content ">
          <form class="form_cliente" [formGroup]="reactiveVacacionesForm"
            (ngSubmit)="guardarPeriodoVacacional()">
            <div class="ml-1">
              <div class="row mt-1 d-flex justify-content-start">
                <div class="col-sm-6">
                  <span class="title-light">Seleccione el rango de fechas
                    <i class="pi pi-cog" style="size: 2rem;">
                    </i>
                  </span>
                </div>
              </div>

              <div class="row mt-1 d-flex justify-content-start">
                <div class="col-sm-10">
                  <span><b>Colaborador: </b>{{nombreCompleto}}

                  </span>
                </div>
              </div>
              <div class="row mt-1 d-flex justify-content-start">
                <div class="col-sm-6">
                    <span>
                        <b>Dias restantes:&nbsp;&nbsp;&nbsp;

                        <div style="color: rgb(26, 201, 26); display: inline-block;">{{diasRestantes}}</div></b>
                    </span>
                </div>
            </div>

              <div class="row justify-content-md-around">
                <div class="col-12">

                  <!-- fechas ---- -->
                  <div class="row mt-4 d-flex justify-content-around">
                    <div class="col-md-5">
                      <div class="row mt-3">
                        <div class="col-md-4 text-center">
                          <label class="col-form-label color-font">
                            <span class="input-group-addon">
                              <i class="pi pi-calendar color-icon mr-3" style="font-size: 2rem"></i>
                            </span>
                            Fecha&nbsp;Inicio
                          </label>
                        </div>
                        <div class="col-md-8">
                          <input type="date" formControlName="fecha" class="form-control sinborde"
                            pTooltip="Seleccione o teclee fecha" tooltipPosition="top"
                            [style]="{'width':'80%' , 'margin-top': '20px'}">
                        </div>
                      </div>
                    </div>


                    <div class="col-md-5">
                      <div class="row mt-3">
                        <div class="col-md-4 text-center">
                          <label class="col-form-label color-font">
                            <span class="input-group-addon">
                              <i class="pi pi-calendar color-icon mr-3" style="font-size: 2rem"></i>
                            </span>
                            Fecha&nbsp;Fin
                          </label>
                        </div>
                        <div class="col-md-8">
                          <input type="date" formControlName="fechaFin" class="form-control sinborde"
                            pTooltip="Seleccione o teclee fecha" tooltipPosition="top"
                            [style]="{'width':'80%' , 'margin-top': '20px'}">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
              <!-- --------------fin cambios------------- -->
              <div class="row d-flex ">
                <div class="col-md-5  mt-6 justify-content-start">
                  <p><span style="font-weight: bold; ">Última&nbsp;Modificación:</span></p>
                  <p style="margin-top: 0px;  color: rgb(181, 17, 50);">
                    <b>{{reactiveVacacionesForm.get('usuarioMod')?.value}}</b>
                  </p>
                </div>

                <!-- [disabled]="!reactiveVacacionesForm.valid || !reactiveVacacionesForm.dirty" -->
                <div class="col-md-3 mt-7 justify-content-center">
                  <button class="btn btn-primary form-control" type="submit"


                    pTooltip="Almacenar informacion" tooltipPosition="right">
                    <span class="text-white">Guardar</span>
                    <i class="pi pi-save text-white"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
          (click)="cancelarVentanaModalPdialog()"></button>
      </ng-template>
    </p-dialog>

    <!-- pdialog de  ver servicios  -->

    <p-dialog [resizable]="false" [draggable]="false" (onClose)="cancelarVentanaModal()" [(visible)]="dspOperacionesVacaciones"
    [style]="{width: '85vw','height':'650px'}" [transitionOptions]="'0ms'" styleClass="card-shadow">
    <ng-template pTemplate="header">
      Periodos Vacacionales de colaborador {{nombreCompleto}}
      <div *ngIf="dspOperacionesVacaciones" style="font-size: 18px;">
        <!--<p style="color: orange;display: inline;">{{nombre_data_Editable }}</p>&nbsp;-->
        <p style="color:black; display: inline; ">Fecha: {{dia | date: 'dd/MM/yyyy'}}</p>
      </div>
    </ng-template>
    <ng-template pTemplate="content">
      <div class="mt-5">
        <p-table #dt2 [value]="lstPeriodos" [tableStyle]="{'min-width': '50rem'}"
          styleClass="p-datatable-sm p-datatable-striped" [scrollable]="true" scrollHeight="550px"
          responsiveLayout="scroll"
          [globalFilterFields]="['num_economico','desc_placaUnidad','nombre_chofer','num_folio']" [paginator]="true"
          [rows]="10" [rowsPerPageOptions]="[10,25,50,100]" [showCurrentPageReport]="true" [resizableColumns]="false"
          currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros" [loading]="loadingOperaciones"
          sortField="num_economico">
          <ng-template pTemplate="caption">
            <div class="row mb-1 mt-1">

            <div class=" col-md-6 d-flex ustify-content-start  "> {{nombreCompleto}} </div>


             <div class=" col-md-6 d-flex justify-content-end  ">
              <button class="btn btn-primary" pTooltip="Agregar Periodo Vacacional"
              tooltipPosition="left" (click)="mostrarModalAgregar( diasRestantes, nombreCompleto , reactiveVacacionesForm.controls['chofer'].value );">
              <i class="pi pi-plus-circle"></i>&nbsp;&nbsp;&nbsp;Agregar periodo vacacional</button>
               </div>
           </div>
          </ng-template>

<!--
          <div class="d-flex justify-content-around">
            <button               class="btn btn-primary" pTooltip="Agregar Periodo Vacacional"
              tooltipPosition="left" (click)="mostrarModalAgregar( data.num_dias_pendientes ,data.nom_completo ,data.cod_chofer);">
              <i class="pi pi-plus-circle"></i>
            </button> -->


          <ng-template pTemplate="header">
            <tr>

              <th class="text-center" pSortableColumn="periodos" >Periodos<p-sortIcon field="periodos"></p-sortIcon></th>
              <th class="text-center" pSortableColumn="dias_vacaciones" >Días de vacaciones<p-sortIcon field="dias_vacaciones"></p-sortIcon></th>
              <th class="text-center" pSortableColumn="fecha_inicio" >Inicio<p-sortIcon field="fecha_inicio"></p-sortIcon></th>
              <th class="text-center" pSortableColumn="fecha_fin" >Fin<p-sortIcon field="fecha_fin"></p-sortIcon></th>
              <th class="text-center"  >Año</th>
              <th class="text-center" pSortableColumn="num_dias_periodo" >Dias por periodo<p-sortIcon field="num_dias_periodo"></p-sortIcon></th>
              <th class="text-center" pSortableColumn="total_dias_vacacionales" >Dias tomados a la fec<p-sortIcon field="total_dias_vacacionales"></p-sortIcon></th>
              <th class="text-center" pSortableColumn="dias_restantes_vacaciones" >Dias restantes<p-sortIcon field="dias_restantes_vacaciones"></p-sortIcon></th>
              <th class="text-center" >Autorizar</th>
              <th class="text-center" >Gestion de Periodo</th>
              <th class="text-center" >Gestion de estatus</th>
              <th class="text-center" >Finalizar vacaciones</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-data>
            <tr [ngClass]="{'row-accessories': data.clv_activo ===0}">

              <td class="text-center"><b>{{data.num_periodo}}</b></td>
              <td class="text-center" style="color:black "   ><b>{{data.num_dias_ley}}</b></td>
              <td class="text-center" style="color: rgb(13,110,253);"  ><b>{{data.fec_inicio | date: 'dd/MM/yyyy' }}</b></td>
              <td class="text-center" style="color: orange;"><b>{{data.fec_fin | date: 'dd/MM/yyyy' }}</b></td>
              <td class="text-center"  > <b>{{data.num_anio}}</b></td>
              <td class="text-center" style="color: rgb(33, 192, 22)"> <b>{{data.num_dias_periodo}}</b></td>
              <td class="text-center" style="color: rgb(255,193,7)" ><b>{{data.num_dias_usados}}</b></td>
              <td class="text-center" style="color: rgb(220,53,69)"><b>{{data.num_dias_pendientes}}</b></td>
              <td class="text-center">
                <b [style.color]="getStatusColor(data.clv_estatus)">{{getStatusDescription(data.clv_estatus)}}</b>
              </td>


              <td>
                <!-- botones de operaciones  -->
                <div class="d-flex justify-content-around">
                  <button  class="btn btn-primary" pTooltip="Editar Periodo Vacacional"
                    tooltipPosition="left" (click)="mostrarModalEditar(data);">
                    <i class="pi pi-pencil"></i>
                  </button>
                  <div class="ml-2">
                    <button *ngIf="btn_cancelar_mostrar(data.fec_inicio,data.clv_estatus)" class="btn btn-danger" pTooltip="Eliminar periodo vacacional"
                      (click)="confirmarEliminar(data);">
                      <i class="pi pi-delete-left"></i>
                    </button>
                  </div>
                </div>
              </td>
              <td>
                <div class="d-flex justify-content-around">
                  <div class="ml-2">
                    <button class="btn btn-success" pTooltip="Aprobar periodo vacacional"
                      (click)="confirmarVacaciones(data);">
                      <i class="pi pi-check"></i>
                    </button>
                  </div>
                  <!-- <div class="ml-2">
                    <button class="btn btn-warning" pTooltip="Pagar vacaiones"
                      (click)="PagarVacaciones(data);">
                      <i class="pi pi-wallet"></i>
                    </button>
                  </div> -->
                </div>
              </td>
              <td>
                <div class="d-flex justify-content-around">

                  <div class="ml-2">
                    <button class="btn btn-dark" pTooltip="Finalizar vacaciones"
                      (click)="FinalizarVacaciones(data);">
                      <i class="pi pi-times"></i>
                    </button>
                  </div>
                </div>

              </td>

            </tr>
          </ng-template>
        </p-table>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
        (click)="cancelarVentanaModal()"></button>
    </ng-template>

  </p-dialog>



    <!-- p-dialog de reportes excel ------------ -->
    <p-dialog [resizable]="false" [draggable]="false" (onClose)="cancelarVentanaModal()" [(visible)]="dbsReportes"
      [style]="{'width': '60vw','height':'620px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
      (onHide)="cancelarVentanaModal()">
      <ng-template pTemplate="header">
        Generar Reporte De Excel
      </ng-template>
      <ng-template pTemplate="content">
        <div class="mt-5">
          <hr class="style-two">
          <div class="row">
            <div class="col-md-3">
              <p class="texto-pesado">Reporte De vacaiones</p>
            </div>
            <div class="col-md-2">
              <select class="form-control" style="width: 100%;" [(ngModel)]="num_anio">
                <option *ngFor="let year of years" [value]="year">{{ year }}</option>
              </select>

            </div>
            <div class="col-md-3">

              <p-dropdown [(ngModel)]="id_ciudad_hm" [options]="lstCiudad" optionLabel="nombre" optionValue="id"
              placeholder="Filtrar por Ciudad" [showClear]="false" [disabled]="dsblstCiudades">
            </p-dropdown>


            </div>
            <div class="col-md-3 ml-6">
              <button type="button" class="btn btn-outline-success" style="width: 100%;"
                (click)="exportarExcel(num_anio, id_ciudad_hm)" [disabled]="ButtonExportExcel">
                Exportar <i class="pi pi-file-export"></i>
              </button>
            </div>
          </div>
          <div class="row d-flex justify-content-center">
            <div class="col-2 text-center">
              <i class="pi pi-spin pi-spinner" *ngIf="loading_reporte1"></i>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
          (click)="cancelarVentanaModal();"></button>
      </ng-template>
    </p-dialog>
  </div>

