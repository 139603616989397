<p-toast position="top-center" key="confirm" (onClose)="cancelarMensajeConfirmacion()" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div class="flex flex-column" style="flex: 1">
      <div class="text-center">
        <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
        <h4>{{message.summary}}</h4>
        <p>{{message.detail}}</p>
      </div>
      <div class="grid p-fluid">
        <div class="col-6">
          <button type="button" pButton (click)="cancelarMensajeConfirmacion()" label="Cancelar"
            class="p-button-secondary"></button>
        </div>
        <div class="col-6">
          <button type="button" pButton (click)="confirmarMensaje()" label="Aceptar" class="p-button-success"></button>
        </div>
      </div>
    </div>
  </ng-template>
</p-toast>
<p-toast position="top-right" key="msj"></p-toast>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="Aceptar" rejectLabel="Cancelar"
  rejectButtonStyleClass="p-button-text">
</p-confirmDialog>
<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
  rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar"
  [rejectVisible]="false"></p-confirmDialog>
<div class="d-flex justify-content-center">
  <div class="container-fluid" style="width: 98%;">
    <div class="row mt-5">
      <div class="col-md-12">
        <div class="card card-shadow sinborde">
          <div class="card-header sinborde" style="background: none; ">
            <div class="cardicono rounded">
              <i class="pi pi-calendar-plus mt-3" style="font-size: 2rem; color: #fff;"></i>
            </div>
            <header style="left: 11%; top: 5%;">
              <div style="display:inline">Consulta y Generación de</div>
              <div style="color:blueviolet;display:inline;" class="ml-3">NOMINA</div>
            </header>
            <div class="row d-flex justify-content-around mt-4">
              <div class="col-md-8">
                <div class="row d-flex justify-content-around">
                  <div class="col-md-4">
                    <p-dropdown [options]="lstSemanas" autoWidth="false" [style]="{'width':'100%'}"
                      placeholder="Seleccione Semana*" [(ngModel)]="selectedSemana" optionLabel="nom_semana"
                      [showClear]="true">
                      <ng-template let-target pTemplate="item">
                        <div class="ui-helper-clearfix" style="position: relative;height:25px;"
                          pTooltip="{{getTargetDescription(target)}}" tooltipPosition="top">
                          <span style="font-size:14px;float:left;margin-top:4px">{{target.nom_semana}}</span>
                        </div>
                      </ng-template>
                    </p-dropdown>
                    <div *ngIf="selectedSemana!=null">
                      <div class="row">
                        <div class="col-sm-6 text-left texto_verde">Inicio:
                          {{selectedSemana.fec_inicio | date: 'dd/MM/yyyy' }}</div>
                        <div class="col-sm-6 text-right texto_verde">Fin:
                          {{selectedSemana.fec_fin | date: 'dd/MM/yyyy' }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <p-dropdown [options]="lstCiudad" autoWidth="false" [style]="{'width':'100%'}"
                      placeholder="Seleccione Ciudad*" [(ngModel)]="selectedCiudad" optionLabel="nombre"
                      [showClear]="true">
                    </p-dropdown>
                  </div>
                  <div class="col-md-4">
                    <button pButton pRipple label="Filtrar" class="p-button-success" icon="pi pi-filter"
                      (click)="cargarInformacionNominaPorSemana();" pTooltip="Cargar Información"></button>
                  </div>
                </div>
              </div>
              <div class="col-md-4 d-flex justify-content-end">
                <div>
                  <div class="col-md-6">
                    <div class="row d-flex justify-content-around">
                      <div class="col-md-9">
                        <p-calendar [(ngModel)]="anio_filtro" view="year" [showIcon]="true" dateFormat="yy"
                          inputId="yearpicker" [style]="{'width':'100%','height':'80%'}"
                          pTooltip="Año de Semanas a Consultar"></p-calendar>
                      </div>
                      <div class="col-md-3">
                        <button pButton pRipple label="Cargar" class="p-button-help" icon="pi pi-filter"
                          (click)="cargarListadoDeSemanasAnio_cast(anio_filtro);"
                          pTooltip="Cargar Semanas del Año"></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <p-button label="Consultar Historico" icon="pi pi-list" iconPos="right" (click)="consultaSemanas();"
                    pTooltip="Consultar Semanas de Nomina" tooltipPosition="left">
                  </p-button>
                </div>
              </div>
            </div>
          </div>
          <div class="card-content">
            <!--Boton reporte-->
            <div class="col-md-2">
              <button type="button" class="btn btn-outline-success" style="width: 100%;" (click)="generarReporte1()">
                Exportar <i class="pi pi-file-export"></i>
              </button>
            </div>
            <div class="row mt-3">
              <p-table #dt1 [value]="lstNominaPorCiudad" [tableStyle]="{'min-width': '50rem'}" responsiveLayout="scroll"
                [scrollable]="true" scrollDirection="both" scrollHeight="flex" [style]="{'min-width': '50rem'}"
                [columns]="cols" styleClass="p-datatable-sm" [paginator]="true" [rows]="50"
                [rowsPerPageOptions]="[50,100,150,200]" [showCurrentPageReport]="true"
                currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros"
                [globalFilterFields]="['nom_chofer','nom_puesto']" [loading]="loading" selectionMode="single"
                rowGroupMode="subheader" groupRowsBy="clv_agrupado_categoria">
                <ng-template pTemplate="caption">
                  <div class="flex justify-content-end">
                    <span class="p-input-icon-left p-ml-auto">
                      <i class="pi pi-search"></i>
                      <input pInputText type="text" size="40" (input)="applyFilterGlobal($event, 'contains')"
                        placeholder="Buscar Nombre Chofer,Puesto" />
                    </span>
                  </div>
                </ng-template>
                <ng-template pTemplate="header">
                  <tr>
                    <th pFrozenColumn pSortableColumn="nom_chofer" style="min-width:200px ;max-width: 200px;">Nombre
                      <p-sortIcon field="nom_chofer"></p-sortIcon>
                    </th>
                    <th pFrozenColumn pSortableColumn="nom_puesto" style="min-width:200px ;max-width: 200px;"
                      class="text-center">Puesto
                      <p-sortIcon field="nom_puesto"></p-sortIcon>
                    </th>
                    <th *ngFor="let col of cols" style="min-width:75px; width: 75px;" [ngClass]="{'texto_sm':true}">
                      {{ col.header }}
                    </th>
                    <th style="min-width:150px ;max-width: 150px; " pSortableColumn="imp_total_normal">Normal<p-sortIcon field="imp_total_normal"></p-sortIcon></th>
                    <th style="min-width:200px ;max-width: 200px;">Descanso Laborado</th>
                    <th style="min-width:200px ;max-width: 200px;" pSortableColumn="imp_otros_ingresos">Otros
                      Ingresos<p-sortIcon field="imp_otros_ingresos">
                      </p-sortIcon></th>


                    <!-- Otros Ingresos Viaticos -->
                    <th style="min-width:200px ;max-width: 200px;" pSortableColumn="imp_otros_ingresos_viaticos">Otros
                      Ingresos Viaticos<p-sortIcon field="imp_otros_ingresos_viaticos">
                      </p-sortIcon></th>
                      <th style="min-width:150px ;max-width: 150px;" pSortableColumn="imp_dia_festivo">Dias Festivos<p-sortIcon field="imp_dia_festivo"></p-sortIcon></th>

                    <th style="min-width:200px ;max-width: 200px;">Prestamo Viaticos</th>
                    <!-- <th tyle="min-width:150px ;max-width: 15px;">Dia Festivo</th> -->
                    <!-- <th style="min-width:150px ;max-width: 150px;">Descuentos</th> -->
                    <th style="min-width:200px ;max-width: 200px;">Caja de Ahorro</th>
                    <th style="min-width:200px ;max-width: 200px;">Bono Semanal</th>
                    <th style="min-width:200px ;max-width: 200px;">Bono Permanencia</th>
                    <th style="min-width:150px ;max-width: 150px;">Bono Temporada</th>
                    <th style="min-width:150px ;max-width: 150px;" pSortableColumn="imp_beneficios"> Beneficios<p-sortIcon field="imp_beneficios"></p-sortIcon></th>
                    <th style="min-width:150px ;max-width: 150px;" pSortableColumn="imp_prestaciones">Prestaciones<p-sortIcon field="imp_prestaciones"></p-sortIcon></th>
                    <th style="min-width:150px ;max-width: 150px;" pSortableColumn="imp_apoyos"> Apoyos<p-sortIcon field="imp_apoyos"></p-sortIcon></th>
                    <th style="min-width:150px ;max-width: 150px;" pSortableColumn="imp_periodo"> Descuentos<p-sortIcon field="imp_periodo"></p-sortIcon></th>





                    <th class="text-right" pSortableColumn="imp_viaticos" pSortableColumn="imp_beneficios">Viaticos <p-sortIcon field="imp_viaticos"></p-sortIcon></th>
                    <th style="min-width:200px ;max-width: 200px;" pSortableColumn="imp_total_dispersion"  class="text-right">Total
                      Dispersión<p-sortIcon field="imp_total_dispersion"></p-sortIcon></th>
                    <th style="text-align: center;">Reporte PDF</th>
                    <th style="text-align: center;">Reporte Activities</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="groupheader" let-data>
                  <tr pRowGroupHeader [ngClass]="{'row-categorias':true}">
                    <td colspan="2" pFrozenColumn>
                      <span class="font-bold ml-2 texto_categoria" *ngIf="data.clv_agrupado_categoria==1">SEMANAL</span>
                      <span class="font-bold ml-2 texto_categoria" *ngIf="data.clv_agrupado_categoria==2">SALARIO
                        DIARIO</span>
                      <span class="font-bold ml-2 texto_categoria"
                        *ngIf="data.clv_agrupado_categoria==3">QUINCENAL</span>
                      <span class="font-bold ml-2 texto_categoria" *ngIf="data.clv_agrupado_categoria==404">SIN
                        CATEGORÍA</span>
                    </td>
                    <td colspan="39">
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-data let-index>
                  <tr>
                    <td pFrozenColumn [frozen]="true" style="min-width:200px ;max-width: 200px; font-weight: bold;"
                      class="text-left" [ngClass]="{'ajustarcelda':true,'texto_sm':true}">
                      {{data.nom_chofer}}
                      <p *ngIf="data.clv_activo==0" style="color: red;" class="d-inline" pTooltip="Colaborador de Baja">
                        (BAJA)</p>
                    </td>
                    <td pFrozenColumn [frozen]="true"
                      [ngClass]="{'texto_chofer': data.id_puesto==1,'texto_auxiliar':data.id_puesto==2,'texto_bk':data.id_puesto==3}"
                      style="min-width:200px ;max-width: 200px;" class="text-center">
                      {{data.nom_puesto }}
                    </td>
                    <!--Dia 1-->
                    <td style="min-width:60px; width: 60px;" [ngClass]="{'ajustarcelda':true,'texto_sm':true}">
                      <div class="row g-0">
                        <div class="col-md-6 d-flex align-items-center justify-content-center">
                          <!--<p class="my-0"
                                                      (click)="mostrarServicios(data.jsondia1,data.nom_chofer)">
                                                      <u [ngClass]="{'texto_descuentos':data.clv_tabulado1==1}">{{data.servicios_dia01}}</u>
                                                  </p>-->
                          <span class="blue my-0" (click)="mostrarServicios(data.jsondia1,data.nom_chofer,data)"
                            *ngIf="data.clv_tabulado1==0" pTooltip="{{cols[0].header}}">{{data.servicios_dia01}}</span>
                          <span class="red my-0" (click)="mostrarServicios(data.jsondia1,data.nom_chofer,data)"
                            *ngIf="data.clv_tabulado1==1" pTooltip="{{cols[0].header}}">{{data.servicios_dia01}}</span>
                          <span class="orange my-0"
                            *ngIf="data.desc_progDescFalta1 !=''">{{data.clv_progDescFalta1}}</span>
                        </div>
                      </div>
                    </td>
                    <td style="min-width:100px; width: 100px;"
                      [ngClass]="{'ajustarcelda':true,'texto_sm':true,'texto_descuentos':data.clv_tabulado1==1}">
                      {{ data.imp_dia01 | currency}}
                    </td>
                    <td style="min-width:60px; width: 60px;"
                      [ngClass]="{'ajustarcelda':true,'texto_sm':true,'col-ayh':true}">
                      {{ data.imp_dia01_ayh | currency}}
                    </td>
                    <!--Dia 2-->
                    <td style="min-width:60px; width: 60px;" [ngClass]="{'ajustarcelda':true,'texto_sm':true}">
                      <div class="row g-0">
                        <div class="col-md-6 d-flex align-items-center justify-content-center">
                          <span class="blue my-0" (click)="mostrarServicios(data.jsondia2,data.nom_chofer,data)"
                            *ngIf="data.clv_tabulado2==0" pTooltip="{{cols[3].header}}">{{data.servicios_dia02}}
                          </span>
                          <span class="red my-0" (click)="mostrarServicios(data.jsondia2,data.nom_chofer,data)"
                            *ngIf="data.clv_tabulado2==1" pTooltip="{{cols[3].header}}">{{data.servicios_dia02}}
                          </span>
                          <span class="orange my-0"
                            *ngIf="data.desc_progDescFalta2 !=''">{{data.clv_progDescFalta2}}</span>
                        </div>
                      </div>
                    </td>
                    <td style="min-width:100px; width: 100px;"
                      [ngClass]="{'ajustarcelda':true,'texto_sm':true,'texto_descuentos':data.clv_tabulado2==1}">
                      {{ data.imp_dia02 | currency}}
                    </td>
                    <td style="min-width:60px; width: 60px;"
                      [ngClass]="{'ajustarcelda':true,'texto_sm':true,'col-ayh':true}">
                      {{ data.imp_dia02_ayh | currency}}
                    </td>
                    <!--Dia 3-->
                    <td style="min-width:60px; width: 60px;" [ngClass]="{'ajustarcelda':true,'texto_sm':true}">
                      <div class="row g-0">
                        <div class="col-md-6 d-flex align-items-center justify-content-center">
                          <span class="blue my-0" (click)="mostrarServicios(data.jsondia3,data.nom_chofer,data)"
                            *ngIf="data.clv_tabulado3==0" pTooltip="{{cols[6].header}}">{{data.servicios_dia03}}</span>
                          <span class="red my-0" (click)="mostrarServicios(data.jsondia3,data.nom_chofer,data)"
                            *ngIf="data.clv_tabulado3==1" pTooltip="{{cols[6].header}}">{{data.servicios_dia03}}</span>
                          <span class="orange my-0"
                            *ngIf="data.desc_progDescFalta3 !=''">{{data.clv_progDescFalta3}}</span>
                        </div>
                      </div>
                    </td>
                    <td style="min-width:60px; width: 60px;"
                      [ngClass]="{'ajustarcelda':true,'texto_sm':true,'texto_descuentos':data.clv_tabulado3==1}">
                      {{ data.imp_dia03 | currency}}
                    </td>
                    <td style="min-width:60px; width: 60px;"
                      [ngClass]="{'ajustarcelda':true,'texto_sm':true,'col-ayh':true}">
                      {{ data.imp_dia03_ayh | currency}}
                    </td>
                    <!--Dia 4-->
                    <td style="min-width:60px; width: 60px;" [ngClass]="{'ajustarcelda':true,'texto_sm':true}">
                      <div class="row g-0">
                        <div class="col-md-6 d-flex align-items-center justify-content-center">
                          <span class="blue my-0" (click)="mostrarServicios(data.jsondia4,data.nom_chofer,data)"
                            *ngIf="data.clv_tabulado4==0" pTooltip="{{cols[9].header}}">{{data.servicios_dia04}}</span>
                          <span class="red my-0" (click)="mostrarServicios(data.jsondia4,data.nom_chofer,data)"
                            *ngIf="data.clv_tabulado4==1" pTooltip="{{cols[9].header}}">{{data.servicios_dia04}}</span>
                          <span class="orange my-0"
                            *ngIf="data.desc_progDescFalta4 !=''">{{data.clv_progDescFalta4}}</span>
                        </div>
                      </div>
                    </td>
                    <td style="min-width:60px; width: 60px;"
                      [ngClass]="{'ajustarcelda':true,'texto_sm':true,'texto_descuentos':data.clv_tabulado4==1}">
                      {{ data.imp_dia04 | currency}}
                    </td>
                    <td style="min-width:100px; width: 100px;"
                      [ngClass]="{'ajustarcelda':true,'texto_sm':true,'col-ayh':true}">
                      {{ data.imp_dia04_ayh | currency}}
                    </td>
                    <!--Dia 5-->
                    <td style="min-width:60px; width: 60px;" [ngClass]="{'ajustarcelda':true,'texto_sm':true}">
                      <div class="row g-0">
                        <div class="col-md-6 d-flex align-items-center justify-content-center">
                          <span class="blue my-0" (click)="mostrarServicios(data.jsondia5,data.nom_chofer,data)"
                            *ngIf="data.clv_tabulado5==0" pTooltip="{{cols[12].header}}">{{data.servicios_dia05}}</span>
                          <span class="red my-0" (click)="mostrarServicios(data.jsondia5,data.nom_chofer,data)"
                            *ngIf="data.clv_tabulado5==1" pTooltip="{{cols[12].header}}">{{data.servicios_dia05}}</span>
                          <span class="orange my-0"
                            *ngIf="data.desc_progDescFalta5 !=''">{{data.clv_progDescFalta5}}</span>
                        </div>
                      </div>
                    </td>
                    <td style="min-width:100px; width: 100px;"
                      [ngClass]="{'ajustarcelda':true,'texto_sm':true,'texto_descuentos':data.clv_tabulado5==1}">
                      {{ data.imp_dia05 | currency}}
                    </td>
                    <td style="min-width:60px; width: 60px;"
                      [ngClass]="{'ajustarcelda':true,'texto_sm':true,'col-ayh':true}">
                      {{ data.imp_dia05_ayh | currency}}
                    </td>
                    <!--Dia 6-->
                    <td style="min-width:60px; width: 60px;" [ngClass]="{'ajustarcelda':true,'texto_sm':true}">
                      <div class="row g-0">
                        <div class="col-md-6 d-flex align-items-center justify-content-center">
                          <span class="blue my-0" (click)="mostrarServicios(data.jsondia6,data.nom_chofer,data)"
                            *ngIf="data.clv_tabulado6==0" pTooltip="{{cols[15].header}}">{{data.servicios_dia06}}</span>
                          <span class="red my-0" (click)="mostrarServicios(data.jsondia6,data.nom_chofer,data)"
                            *ngIf="data.clv_tabulado6==1" pTooltip="{{cols[15].header}}">{{data.servicios_dia06}}</span>
                          <span class="orange my-0"
                            *ngIf="data.clv_progDescFalta6 !=''">{{data.clv_progDescFalta6}}</span>
                        </div>
                      </div>
                    </td>
                    <td style="min-width:100px; width: 100px;"
                      [ngClass]="{'ajustarcelda':true,'texto_sm':true,'texto_descuentos':data.clv_tabulado6==1}">
                      {{ data.imp_dia06 | currency}}
                    </td>
                    <td style="min-width:60px; width: 60px;"
                      [ngClass]="{'ajustarcelda':true,'texto_sm':true,'col-ayh':true}">
                      {{ data.imp_dia06_ayh | currency}}
                    </td>
                    <!--Dia 7-->
                    <td style="min-width:60px; width: 60px;" [ngClass]="{'ajustarcelda':true,'texto_sm':true}">
                      <div class="row g-0">
                        <div class="col-md-6 d-flex align-items-center justify-content-center">
                          <span class="blue my-0" (click)="mostrarServicios(data.jsondia7,data.nom_chofer,data)"
                            *ngIf="data.clv_tabulado7==0" pTooltip="{{cols[18].header}}">{{data.servicios_dia07}}</span>
                          <span class="red my-0" (click)="mostrarServicios(data.jsondia7,data.nom_chofer,data)"
                            *ngIf="data.clv_tabulado7==1" pTooltip="{{cols[18].header}}">{{data.servicios_dia07}}</span>
                          <span class="orange my-0"
                            *ngIf="data.desc_progDescFalta7 !=''">{{data.clv_progDescFalta7}}</span>
                        </div>
                      </div>
                    </td>
                    <td style="min-width:60px; width: 60px;"
                      [ngClass]="{'ajustarcelda':true,'texto_sm':true,'texto_descuentos':data.clv_tabulado7==1 }">
                      {{ data.imp_dia07 | currency}}
                    </td>
                    <td style="min-width:60px; width: 60px;"
                      [ngClass]="{'ajustarcelda':true,'texto_sm':true,'col-ayh':true}">
                      {{ data.imp_dia07_ayh | currency}}
                    </td>
                    <!--FIN DIAS-->
                    <td [ngClass]="{'ajustarcelda':true,'texto_sm':true,'texto_principal':true}"
                      style="min-width:150px ;max-width: 150px;">
                      <p pTooltip="Total Normal" class="my-0">{{data.imp_total_normal
                        |currency}}</p>
                    </td>
                    <td style="min-width:200px ;max-width: 200px;" [ngClass]="{'ajustarcelda':true,'texto_sm':true}">
                      <p pTooltip="Descanso Laborado" class="my-0">{{data.imp_descanso_laborado
                        |currency}}
                      </p>
                    </td>
                    <td style="min-width:200px ;max-width: 200px;" [ngClass]="{'ajustarcelda':true,'texto_sm':true}">
                      <p  class="my-0">{{data.imp_otros_ingresos | currency}}
                        <span *ngIf="data.clv_tabulado_otros_ingresos==1" class="input-group-addon ml-2 my-0"
                          (click)="mostrarOtrosIngresos(data.jsonOtrosIngresos,data.nom_chofer,data)"
                          pTooltip="Desgloce Otros Ingresos">
                          <i class="pi pi-info-circle color-icon mr-3" style="font-size: 1rem; color: #3B82F6;">
                          </i>
                        </span>
                        <span *ngIf="data.clv_tabulado_otros_ingresos==0" class="input-group-addon ml-2 my-0"
                          (click)="mostrarOtrosIngresos(data.jsonOtrosIngresos,data.nom_chofer,data)"
                          pTooltip="Desgloce Otros Ingresos">
                          <i class="pi pi-info-circle color-icon mr-3" style="font-size: 1rem; color: red;">
                          </i>
                        </span>
                      </p>
                    </td>


                    <!-- viativos -->
                    <td style="min-width:200px ;max-width: 200px;"
                      [ngClass]="{'ajustarcelda':true,'texto_sm':true}">
                      <p pTooltip="Viaticos Otros" tooltipPosition="top" class="my-0">{{data.imp_otros_ingresos_viaticos | currency}}
                      </p>
                    </td>

                    <td style="min-width:200px ;max-width: 200px;" [ngClass]="{'ajustarcelda':true,'texto_sm':true}">
                      <p class="my-0"> {{data.imp_dia_festivo | currency}}
                        <span class="input-group-addon ml-2 my-0"
                          (click)="mostrarDiasFestivos(data.jsonDiaFestivo,data.nom_chofer,data)"
                          pTooltip="Desgloce dias festivos">
                          <i class="pi pi-info-circle color-icon mr-3" style="font-size: 1rem; color: #3B82F6;">
                          </i>
                        </span>
                      </p>
                    </td>
                    <td style="min-width:200px ;max-width: 200px;"
                      [ngClass]="{'ajustarcelda':true,'texto_sm':true,'texto_descuentos':data.imp_prestamo_viaticos>0}">
                      <p pTooltip="Prestamo viaticos" class="my-0">{{data.imp_prestamo_viaticos
                        |currency}}
                      </p>
                    </td>
                    <!-- <td [ngClass]="{'ajustarcelda':true,'texto_sm':true}" style="min-width:150px ;max-width: 150px;">
                      <p pTooltip="Festivo" class="my-0">
                        {{data.imp_dia_festivo
                        | currency}}
                      </p>
                    </td> -->
                    <!-- <td
                      [ngClass]="{'ajustarcelda':true,'texto_sm':true,'texto_descuentos':data.imp_descuento_varios>0}">
                      <p pTooltip="Descuentos" class="my-0">
                        {{data.imp_descuento_varios | currency}}
                      </p>
                    </td> -->
                    <td style="min-width:200px ;max-width: 200px;"
                      [ngClass]="{'ajustarcelda':true,'texto_sm':true,'texto_descuentos':data.imp_caja_ahorro>0}">
                      <p pTooltip="Ahorro" class="my-0">
                        {{data.imp_caja_ahorro | currency}}
                      </p>
                    </td>
                    <td style="min-width:200px ;max-width: 200px;"
                      [ngClass]="{'ajustarcelda':true,'texto_sm':true,'texto_ingreso':data.imp_bono_semanal>0 }">
                      <p pTooltip="Semanal" class="my-0">{{data.imp_bono_semanal | currency}}</p>
                    </td>
                    <td style="min-width:200px ;max-width: 200px;"
                      [ngClass]="{'ajustarcelda':true,'texto_sm':true,'texto_ingreso':data.imp_bono_permanencia>0}">
                      <p pTooltip="Permanencia" class="my-0">{{data.imp_bono_permanencia
                        | currency}}</p>
                    </td>
                    <td style="min-width:150px ;max-width: 150px;" [ngClass]="{'ajustarcelda':true,'texto_sm':true}">
                      <p pTooltip="Temporada" class="my-0">{{data.imp_bono_temporada | currency}}
                      </p>
                    </td>
                    <!-- <td style="min-width:200px ;max-width: 200px;"
                                      [ngClass]="{'ajustarcelda':true,'texto_sm':true}">
                                          <p pTooltip="Otros" class="my-0">{{data.imp_otros_ingresos | currency}}
                                              <span *ngIf="data.clv_tabulado_otros_ingresos==1"
                                                  class="input-group-addon ml-2 my-0"
                                                  (click)="mostrarOtrosIngresos(data.jsonOtrosIngresos,data.nom_chofer,data)"
                                                  pTooltip="Desgloce Otros Ingresos">
                                                  <i class="pi pi-info-circle color-icon mr-3"
                                                      style="font-size: 1rem; color: #3B82F6;">
                                                  </i>
                                              </span>
                                              <span *ngIf="data.clv_tabulado_otros_ingresos==0"
                                                  class="input-group-addon ml-2 my-0"
                                                  (click)="mostrarOtrosIngresos(data.jsonOtrosIngresos,data.nom_chofer,data)"
                                                  pTooltip="Desgloce Otros Ingresos">
                                                  <i class="pi pi-info-circle color-icon mr-3"
                                                      style="font-size: 1rem; color: red;">
                                                  </i>
                                              </span>
                                          </p>
                                      </td> -->
                    <td style="min-width:200px ;max-width: 200px;" [ngClass]="{'ajustarcelda':true,'texto_sm':true}">
                      <p class="my-0">{{data.imp_beneficios | currency}}
                        <span class="input-group-addon ml-2 my-0"
                          (click)="mostrarBeneficioss(data.jsonBeneficios,data.nom_chofer,data)"
                          pTooltip="Desgloce Beneficios">
                          <i class="pi pi-info-circle color-icon mr-3" style="font-size: 1rem; color: #3B82F6;">
                          </i>
                        </span>
                      </p>
                    </td>
                    <td style="min-width:200px ;max-width: 200px;" [ngClass]="{'ajustarcelda':true,'texto_sm':true}">
                      <p class="my-0">{{data.imp_prestaciones | currency}}
                        <span class="input-group-addon ml-2 my-0"
                          (click)="mostrarPrestaciones(data.jsonPrestaciones,data.nom_chofer,data)"
                          pTooltip="Desgloce Prestaciones">
                          <i class="pi pi-info-circle color-icon mr-3" style="font-size: 1rem; color: #3B82F6;">
                          </i>
                        </span>
                      </p>
                    </td>



                    <!-- itzael -->
                    <td style="min-width:200px ;max-width: 200px;" [ngClass]="{'ajustarcelda':true,'texto_sm':true}">
                      <p class="my-0"> {{data.imp_apoyos | currency}}
                        <span class="input-group-addon ml-2 my-0"
                          (click)="mostrarApoyos(data.jsonApoyos,data.nom_chofer,data)"
                          pTooltip="Desgloce Apoyos">
                          <i class="pi pi-info-circle color-icon mr-3" style="font-size: 1rem; color: #3B82F6;">
                          </i>
                        </span>
                      </p>
                    </td>

                        <!-- dias festivos -->



                <td style="min-width:200px ;max-width: 200px;" [ngClass]="{'ajustarcelda':true,'texto_sm':true}">
                  <p class="my-0"> {{data.imp_periodo | currency}}
                    <span class="input-group-addon ml-2 my-0"
                      (click)="mostrardesc(data.jsonDescVarios,data.nom_chofer,data)"
                      pTooltip="Desgloce descuentos">
                      <i class="pi pi-info-circle color-icon mr-3" style="font-size: 1rem; color: #3B82F6;">
                      </i>
                    </span>
                  </p>
                </td>







                    <td
                      [ngClass]="{'ajustarcelda':true,'texto_sm':true,'texto_principal':true,'texto_descuentos':data.clv_activo==0}"
                      class="text-right">
                      <p pTooltip="Viaticos" class="my-0">
                        {{data.imp_viaticos | currency}}
                      </p>
                    </td>
                    <td style="min-width:200px ;max-width: 200px;"
                      [ngClass]="{'ajustarcelda':true,'texto_sm':true,'texto_principal':true,'texto_descuentos':data.clv_activo==0}"
                      class="text-right">
                      <p class="my-0">
                        {{data.imp_total_dispersion | currency}}
                      </p>
                    </td>
                    <td>
                      <div class="d-flex justify-content-center">
                        <i class="pi pi-file-pdf  " style=" color: rgb(189, 12, 12);"
                          (click)="reciboPDF(data.clave_chofer, data.nom_chofer)" tooltipPosition="top"
                          pTooltip="Descargar Recibo"></i>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex justify-content-center">
                        <i class="pi pi-file-excel  " style=" color: rgb(12, 15, 189);"
                          (click)="reciboactivities(data.clave_chofer, data.nom_chofer)" tooltipPosition="top"
                          pTooltip="Descargar activities"></i>
                      </div>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="footer">
                  <tr>
                    <td colspan="40" class="text-right">Total Dispersión</td>
                    <td>{{ total_dispersion | currency: 'USD'}}</td>
                  </tr>
                  <tr>
                    <td colspan="40" class="text-right">Total Viaticos</td>
                    <td>{{ total_viaticos | currency: 'USD'}}</td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                  <div class="flex align-items-center justify-content-center mb-2 mt-2">
                    <div class="col-md-3">
                      <button class="btn btn-success w-100" pTooltip="Guardar Asignación de Nomina"
                        (click)="guardarTodo()">
                        <span class="text-white">Guardar Todo</span>
                        <i class="pi pi-save text-white"></i>
                      </button>
                    </div>
                  </div>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Dialog Reportes-->
    <!-- <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dbsReportes"
          [style]="{width: '70vw','height':'620px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
          (onHide)="cancelarModalReportes()">
          <ng-template pTemplate="header">
              Reportes
          </ng-template>
          <ng-template pTemplate="content">
              <div class="mt-5">
                  <hr class="style-two">
                  <div class="row">
                      <div class="col-md-3">
                          <p class="texto_principal">Tabulados Autorizados(Año)</p>
                      </div>
                      <div class="col-md-2">
                          <p-calendar [(ngModel)]="anioReporte1" [showIcon]="true" view="year" dateFormat="yy"
                              [style]="{'width':'100%'}" placeholder="Seleccione un año*"></p-calendar>
                          <hr class="my-0">
                      </div>
                      <div class="col-md-2">
                          <button type="button" class="btn btn-outline-success" style="width: 100%;"
                              (click)="generarReporte1()">
                              Exportar <i class="pi pi-file-export"></i>
                          </button>
                      </div>
                  </div>
                  <div class="row d-flex justify-content-center">
                      <div class="col-2 text-center">
                          <i class="pi pi-spin pi-spinner" *ngIf="loading_reporte1"></i>
                      </div>
                  </div>
              </div>
          </ng-template>
          <ng-template pTemplate="footer">
              <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                  (click)="cancelarModalReportes();"></button>
          </ng-template>
      </p-dialog> -->
    <!--DESCLOCE DE OPERACIONES-->
    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspDialogoServicios"
      [style]="{width: '70vw','height':'650px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
      (onHide)="cancelarModal()">
      <ng-template pTemplate="header">
        {{ texto_operacion }}
        <div *ngIf="" style="color:black;">
          {{nombre_registro }}
        </div>
      </ng-template>
      <ng-template pTemplate="content">
        <div class="mt-5">
          <span class="title-light mt-3">Información de Servicios <i class="pi pi-cog" style="size: 2rem;"></i>
          </span>
          <div>
            <p-table [value]="lstJsonPorDia" styleClass="p-datatable-sm p-datatable-striped"
              [tableStyle]="{'min-width': '50rem'}">
              <ng-template pTemplate="caption">
                <div class="flex justify-content-start">
                  <span class="p-input-icon-left p-ml-auto">
                    <p> Colaborador: {{ nombre_colaborador }} | {{fecha_servicio_dia | date:
                      'dd/MM/yyyy'}}</p>
                  </span>
                </div>
              </ng-template>
              <ng-template pTemplate="header">
                <tr>
                  <th>#</th>
                  <th class="text-center">Ruta</th>
                  <th class="text-center">Economico</th>
                  <th class="text-center">Config.Unidad</th>
                  <th class="text-center">Ciudad</th>
                  <th class="text-center">Puesto Presto</th>
                  <th class="text-center">Importe</th>
                  <th class="text-center">¿Tiene Tabulado?</th>
                  <th class="text-center">Viaticos</th>
                  <th class="text-center">Hospedaje</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
                <tr>
                  <td>{{ rowIndex +1 }}</td>
                  <td class="text-center">{{data.desc_rutaTool}}
                    <p *ngIf="data.clv_especial==1" style="color: red;">(ESPECIAL)</p>
                  </td>
                  <td class="texto_verde text-center">{{data.num_economico}}</td>
                  <td class="text-center">{{data.desc_conf_unidad }}</td>
                  <td class="text-center">{{data.desc_ciudad}}</td>
                  <td class="text-center">{{data.desc_puesto}}</td>
                  <td class="text-center texto_resaltado">{{data.imp_sueldo | currency}}
                    <p *ngIf="data.clv_retardo==1" style="color: orange;">(A)</p>
                    <p *ngIf="data.clv_cancelado==1" style="color: purple;">(C)</p>
                  </td>
                  <td class="text-center">
                    <p *ngIf="data.clv_tabulado==0" class="texto_descuentos">
                      <i class="pi pi-times"></i>
                    </p>
                    <p *ngIf="data.clv_tabulado==1" class="texto_ingreso">
                      <i class="pi pi-check"></i>
                    </p>
                  </td>
                  <td class="text-center texto_resaltado">{{data.imp_viaticos | currency}}</td>
                  <td class="text-center texto_resaltado">{{data.imp_viaticos_hospedaje | currency}}
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="summary">
                <div>
                  <p *ngIf="clv_sueldo_diario_general==1" class="texto_tinto mt-3">*El colaborador
                    tiene Sueldo Diario</p>
                  <p *ngIf="clv_sueldo_quincenal_general==1" class="texto_tinto mt-3">*El colaborador
                    tiene Nomina Quincenal</p>
                  <p class="color-operacion mt-3">*(A) = El colaborador tiene pago de Apoyo Auxiliar
                  </p>
                  <p class="mt-3" style="color: purple; font-weight: bold;">*(C) = El colaborador
                    tiene pago de Asistencia Pagada(Ruta Cancelada)</p>
                </div>
              </ng-template>
            </p-table>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
          (click)="cancelarModal();"></button>
      </ng-template>








    </p-dialog>


    <!--DESCLOCE DE OTROS INGRESOS-->
    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspDialogoOtros"
      [style]="{width: '70vw','height':'650px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
      (onHide)="cancelarOtrosServicios()">
      <ng-template pTemplate="header">
        {{ texto_operacion }}
        <div *ngIf="" style="color:black;">
          {{nombre_registro }}
        </div>
      </ng-template>
      <ng-template pTemplate="content">
        <div class="mt-5">
          <span class="title-light mt-3">Información de No Ordinarios <i class="pi pi-cog" style="size: 2rem;"></i>
          </span>
          <div>
            <p-table [value]="lstJsonOtrosIngresos" styleClass="p-datatable-sm p-datatable-striped"
              [tableStyle]="{'min-width': '50rem'}" rowGroupMode="subheader" groupRowsBy="id_servicio">
              <ng-template pTemplate="caption">
                <div class="flex justify-content-start">
                  <span class="p-input-icon-left p-ml-auto">
                    <p> Colaborador: {{ nombre_colaborador }}</p>
                  </span>
                </div>
              </ng-template>
              <ng-template pTemplate="header">
                <tr>
                  <th>#Servicio</th>
                  <th class="text-center">Puesto</th>
                  <th class="text-center">Ruta</th>
                  <th class="text-center">Número Economico</th>
                  <th class="text-center">Tipo De Unidad</th>
                  <th class="text-center">Fecha</th>
                  <th class="text-center">Detalle</th>
                  <th class="text-center">Importe</th>
                  <th class="text-center">Viaticos</th>
                  <th class="text-center">Hospedaje</th>
                  <th class="text-center">¿Tiene Tabulado?</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="groupheader" let-data>
                <tr pRowGroupHeader [ngClass]="{'row-categorias':true}">
                  <td colspan="3" pFrozenColumn>
                    <span class="font-bold ml-2 texto_categoria" *ngIf="data.id_servicio==1">ASISTENCIA PAGADA</span>
                    <span class="font-bold ml-2 texto_categoria" *ngIf="data.id_servicio==2">TRASLADO DE UNIDAD</span>
                    <span class="font-bold ml-2 texto_categoria" *ngIf="data.id_servicio==3">CAPACITACIÓN</span>
                    <span class="font-bold ml-2 texto_categoria" *ngIf="data.id_servicio==4">CERTIFICACIÓN</span>
                    <span class="font-bold ml-2 texto_categoria" *ngIf="data.id_servicio==5">CANCELADA</span>
                    <span class="font-bold ml-2 texto_categoria" *ngIf="data.id_servicio==6">RESCATE DE UNIDAD - APOYO</span>
                    <span class="font-bold ml-2 texto_categoria" *ngIf="data.id_servicio==7">DESCANSO LABORADO</span>
                    <span class="font-bold ml-2 texto_categoria" *ngIf="data.id_servicio==9">MANIOBRA</span>
                    <span class="font-bold ml-2 texto_categoria" *ngIf="data.id_servicio==10">EXTENSIÓN DE RUTA</span>
                    <span class="font-bold ml-2 texto_categoria" *ngIf="data.id_servicio==11">TIENDAS ADICIONALES</span>
                    <span class="font-bold ml-2 texto_categoria" *ngIf="data.id_servicio==12">EXTENSIÓN DE RUTA FUERA DE SEMANA</span>
                    <span class="font-bold ml-2 texto_categoria" *ngIf="data.id_servicio==13">RESCATE DE UNIDAD - SERVICIO</span>
                    <span class="font-bold ml-2 texto_categoria" *ngIf="data.id_servicio==14">EVENTUALIDAD</span>
                    <span class="font-bold ml-2 texto_categoria" *ngIf="data.id_servicio==404">SIN
                      CATEGORÍA</span>
                  </td>
                  <td colspan="8">
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
                <tr>
                  <td>{{ rowIndex +1 }}</td>
                  <!--puesto  ------------------------------------------------->
                  <td class="texto_lila text-center">{{data.desc_puesto}}</td>
                  <td class="texto_lila text-center">{{data.desc_ruta}}</td>
                  <td class="texto_verde text-center">{{data.num_economico }}</td>
                  <!--  tipo de unidad  ------------------------------------------------>
                  <td class="texto_lila text-center">{{data.desc_confUnidad}}</td>
                  <td class="text-center">{{data.fecha | date:'dd/MM/yyyy'}}</td>
                  <td class="texto_detalle, text-center">{{data.desc_servicio}} </td>
                  <td class="text-center texto_resaltado">{{data.imp_no_ordinario | currency}}</td>
                  <td class="text-center texto_resaltado">{{data.imp_no_ordinario_viaticos | currency}}</td>
                  <td class="text-center texto_resaltado">{{data.imp_no_ordinario_hospedaje | currency}}</td>
                  <td class="text-center">
                    <p *ngIf="data.clv_tabulado==0" class="texto_descuentos">
                      <i class="pi pi-times"></i>
                    </p>
                    <p *ngIf="data.clv_tabulado==1" class="texto_ingreso">
                      <i class="pi pi-check"></i>
                    </p>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
          (click)="cancelarOtrosServicios();"></button>
      </ng-template>
    </p-dialog>
    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspDialogoBeneficios"
      [style]="{width: '70vw','height':'650px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
      (onHide)="cancelarBeneficiosss()">
      <ng-template pTemplate="header">
        {{ texto_operacion }}
        <div *ngIf="" style="color:black;">
          {{nombre_registro }}
        </div>
      </ng-template>
      <ng-template pTemplate="content">
        <div class="mt-5">
          <span class="title-light mt-3">Información de Beneficios <i class="pi pi-cog" style="size: 2rem;"></i>
          </span>
          <div>
            <p-table [value]="lstJsonBeneficios" styleClass="p-datatable-sm p-datatable-striped"
              [tableStyle]="{'min-width': '50rem'}" rowGroupMode="subheader" groupRowsBy="id_beneficio">
              <ng-template pTemplate="caption">
                <div class="flex justify-content-start">
                  <span class="p-input-icon-left p-ml-auto">
                    <p> Colaborador: {{ nombre_colaborador }}</p>
                  </span>
                </div>
              </ng-template>
              <ng-template pTemplate="header">
                <tr>
                  <th>#Servicio</th>
                  <th class="text-center">Beneficio</th>
                  <th class="text-center">Importe al Dia</th>
                  <th class="text-center">Número de Dias</th>
                  <th class="text-center">Fecha Ini.</th>
                  <th class="text-center">Fecha Fin</th>
                  <th class="text-center">Importe Total</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="groupheader" let-data>
                <tr pRowGroupHeader [ngClass]="{'row-categorias':true}">
                  <td colspan="2" pFrozenColumn>
                    <span class="font-bold ml-2 texto_categoria" *ngIf="data.id_beneficio === 1">Apoyo Estatus
                      Foraneo</span>
                    <span class="font-bold ml-2 texto_categoria" *ngIf="data.id_beneficio === 2">Bono Por
                      Recomendacion</span>
                      <span class="font-bold ml-2 texto_categoria" *ngIf="data.id_beneficio === 3">
                        Vale de despensa</span>

                        <span class="font-bold ml-2 texto_categoria" *ngIf="data.id_beneficio==4">Bono Contratacion</span>

                    <!-- <span class="font-bold ml-2 texto_categoria"
                                        *ngIf="data.id_beneficio==1">TRASLADO DE UNIDAD</span>
                                    <span class="font-bold ml-2 texto_categoria"
                                        *ngIf="data.id_beneficio==2">CAPACITACIÓN</span> -->
                    <!--  <span class="font-bold ml-2 texto_categoria"
                                        *ngIf="data.id_servicio==4">CERTIFICACIÓN</span>
                                    <span class="font-bold ml-2 texto_categoria"
                                        *ngIf="data.id_servicio==5">CANCELADA</span>
                                    <span class="font-bold ml-2 texto_categoria"
                                        *ngIf="data.id_servicio==6">RESCATE DE UNIDAD</span>
                                    <span class="font-bold ml-2 texto_categoria"
                                        *ngIf="data.id_servicio==7">DESCANSO LABORADO</span>
                                    <span class="font-bold ml-2 texto_categoria" *ngIf="data.id_servicio==404">SIN
                                        CATEGORÍA</span> -->
                  </td>
                  <td colspan="6">
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
                <tr>
                  <td>{{ rowIndex +1 }}</td>
                  <td class="texto_bk text-center">{{data.desc_beneficio}}</td>
                  <td class="texto_azul text-center">{{data.imp_dia | currency}}</td>
                  <td class="text-center">{{data.num_dias }}</td>
                  <td class="texto_lila text-center">{{data.fec_inicio | date:'dd/MM/yyyy'}}</td>
                  <td class="texto_lila text-center">{{data.fec_fin | date:'dd/MM/yyyy'}}</td>
                  <td class="texto_verde text-center texto_resaltado">{{data.imp_total | currency}}</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
          (click)="cancelarBeneficiosss();"></button>
      </ng-template>
    </p-dialog>



    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspDialogoPrestaciones"
      [style]="{width: '70vw','height':'650px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
      (onHide)="cancelarPrestaciones()">
      <ng-template pTemplate="header">
        {{ texto_operacion }}
        <div *ngIf="" style="color:black;">
          {{nombre_registro }}
        </div>
      </ng-template>
      <ng-template pTemplate="content">
        <div class="mt-5">
          <span class="title-light mt-3">Información de Prestaciones<i class="pi pi-cog" style="size: 2rem;"></i>
          </span>
          <div>
            <p-table [value]="lstJsonPrestaciones" styleClass="p-datatable-sm p-datatable-striped"
              [tableStyle]="{'min-width': '50rem'}" rowGroupMode="subheader" groupRowsBy="id_prestacion">
              <ng-template pTemplate="caption">
                <div class="flex justify-content-start">
                  <span class="p-input-icon-left p-ml-auto">
                    <p> Colaborador: {{ nombre_colaborador }}</p>
                  </span>
                </div>
              </ng-template>
              <ng-template pTemplate="header">
                <tr>
                  <th>#Servicio</th>
                  <th class="text-center">Prestación</th>
                  <th class="text-center">Salario Base</th>
                  <th class="text-center">Número de Dias</th>
                  <th class="text-center">Fecha Inicial</th>
                  <th class="text-center">Fecha Fin</th>
                  <th class="text-center">Importe Total</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="groupheader" let-data>
                <tr pRowGroupHeader [ngClass]="{'row-categorias':true}">
                  <td colspan="2" pFrozenColumn>
                    <!-- <span class="font-bold ml-2 texto_categoria">Vacaciones</span> -->
                    <span class="font-bold ml-2 texto_categoria" *ngIf="data.id_prestacion==1">Vacaciones</span>
                    <span class="font-bold ml-2 texto_categoria" *ngIf="data.id_prestacion==2">Prima Vacacional</span>
                    <span class="font-bold ml-2 texto_categoria" *ngIf="data.id_prestacion==3">Incapacidad</span>

                    <!--   <span class="font-bold ml-2 texto_categoria"
                                          *ngIf="data.id_servicio==5">CANCELADA</span>
                                      <span class="font-bold ml-2 texto_categoria"
                                          *ngIf="data.id_servicio==6">RESCATE DE UNIDAD</span>
                                      <span class="font-bold ml-2 texto_categoria"
                                          *ngIf="data.id_servicio==7">DESCANSO LABORADO</span>
                                      <span class="font-bold ml-2 texto_categoria" *ngIf="data.id_servicio==404">SIN
                                          CATEGORÍA</span> -->
                  </td>
                  <td colspan="6">
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
                <tr>
                  <td>{{ rowIndex +1 }}</td>
                  <td class="texto_bk text-center">{{data.desc_prestacion}}</td>
                  <td class="texto_azul text-center">{{data.imp_prestacion | currency}}</td>
                  <td class="text-center">{{data.num_dias }}</td>
                  <td class="texto_lila text-center">{{data.fec_inicio | date:'dd/MM/yyyy'}}</td>
                  <td class="texto_lila text-center">{{data.fec_fin | date:'dd/MM/yyyy'}}</td>
                  <td class="texto_verde text-center texto_resaltado">{{data.imp_total | currency}}</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
          (click)="cancelarPrestaciones();"></button>
      </ng-template>
    </p-dialog>





   <!-- pdialog de apoyos -->
    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspDialogoApoyo"
    [style]="{width: '70vw','height':'650px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
    (onHide)="cancelarApoyos()">
    <ng-template pTemplate="header">
      {{ texto_operacion }}
      <div *ngIf="" style="color:black;">
        {{nombre_registro }}
      </div>
    </ng-template>
    <ng-template pTemplate="content">
      <div class="mt-5">
        <span class="title-light mt-3">Información del apoyo<i class="pi pi-cog" style="size: 2rem;"></i>
        </span>
        <div>
          <p-table [value]="lstJsonApoyo" styleClass="p-datatable-sm p-datatable-striped"
            [tableStyle]="{'min-width': '50rem'}" rowGroupMode="subheader" groupRowsBy="id_prestacion">
            <ng-template pTemplate="caption">
              <div class="flex justify-content-start">
                <span class="p-input-icon-left p-ml-auto">
                  <p> Colaborador: {{ nombre_colaborador }}</p>
                </span>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th>#Servicio</th>
                <th class="text-center">Descripción</th>
                <th class="text-center">Salario Base</th>
                <th class="text-center">Número de Dias</th>
                <th class="text-center">Fecha Inicial</th>
                <th class="text-center">Fecha Fin</th>
                <th class="text-center">Importe Total</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="groupheader" let-data>
              <tr pRowGroupHeader [ngClass]="{'row-categorias':true}">
                <td colspan="2" pFrozenColumn>
                  <!-- <span class="font-bold ml-2 texto_categoria">Vacaciones</span> -->
                  <span class="font-bold ml-2 texto_categoria" >Apoyos</span>


                </td>
                <td colspan="6">
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
              <tr>



                <td>{{ rowIndex +1 }}</td>
                <td class="texto_bk text-center">{{data.desc_apoyo}}</td>
                <td class="texto_azul text-center">{{data.imp_salario_base | currency}}</td>
                <td class="text-center">{{data.num_dias }}</td>
                <td class="texto_lila text-center">{{data.fec_inicio | date:'dd/MM/yyyy'}}</td>
                <td class="texto_lila text-center">{{data.fec_fin | date:'dd/MM/yyyy'}}</td>
                <td class="texto_verde text-center texto_resaltado">{{data.imp_corte | currency}}</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
        (click)="cancelarApoyos();"></button>
    </ng-template>
  </p-dialog>



<!-- pdialog de descuentois varios  -->
 <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspDialogodesc"
 [style]="{width: '70vw','height':'650px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
 (onHide)="cancelardesc()">
 <ng-template pTemplate="header">
   {{ texto_operacion }}
   <div *ngIf="" style="color:black;">
     {{nombre_registro }}
   </div>
 </ng-template>
 <ng-template pTemplate="content">
   <div class="mt-5">
     <span class="title-light mt-3">Información del descuento<i class="pi pi-cog" style="size: 2rem;"></i>
     </span>
     <div>
       <p-table [value]="lstJsonDesc" styleClass="p-datatable-sm p-datatable-striped"
         [tableStyle]="{'min-width': '50rem'}" rowGroupMode="subheader" groupRowsBy="id_prestacion">
         <ng-template pTemplate="caption">
           <div class="flex justify-content-start">
             <span class="p-input-icon-left p-ml-auto">
               <p> Colaborador: {{ nombre_colaborador }}</p>
             </span>
           </div>
         </ng-template>
         <ng-template pTemplate="header">
           <tr>
             <th>#Servicio</th>
             <th class="text-center">Descuento</th>
             <th class="text-center">#Semana</th>
             <th class="text-center">Fecha Inicial</th>
             <th class="text-center">Fecha Fin</th>
             <th class="text-center">Importe Total</th>
           </tr>
         </ng-template>
         <ng-template pTemplate="groupheader" let-data>
           <tr pRowGroupHeader [ngClass]="{'row-categorias':true}">
             <td colspan="2" pFrozenColumn>
               <!-- <span class="font-bold ml-2 texto_categoria">Vacaciones</span> -->
               <span class="font-bold ml-2 texto_categoria" >Descuentos</span>


             </td>
             <td colspan="6">
             </td>
           </tr>
         </ng-template>
         <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
           <tr>



             <td>{{ rowIndex +1 }}</td>
             <td class="texto_bk text-center">{{data.desc_descuento}}</td>
             <td class="text-center">{{data.id_semana }}</td>
             <td class="texto_lila text-center">{{data.fec_inicio | date:'dd/MM/yyyy'}}</td>
             <td class="texto_lila text-center">{{data.fec_fin | date:'dd/MM/yyyy'}}</td>
             <td class="texto_verde text-center texto_resaltado">{{data.imp_periodo | currency}}</td>
           </tr>
         </ng-template>
       </p-table>
     </div>
   </div>
 </ng-template>
 <ng-template pTemplate="footer">
   <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
     (click)="cancelardesc();"></button>
 </ng-template>
</p-dialog>




<!-- pdialog de descuentois varios  -->
<p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspDialogoDiasFestivos"
[style]="{width: '70vw','height':'650px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
(onHide)="cancelarDiasFestivos()">
<ng-template pTemplate="header">
  {{ texto_operacion }}
  <div *ngIf="" style="color:black;">
    {{nombre_registro }}
  </div>
</ng-template>
<ng-template pTemplate="content">
  <div class="mt-5">
    <span class="title-light mt-3">Información de dias festivos<i class="pi pi-cog" style="size: 2rem;"></i>
    </span>
    <div>
      <p-table [value]="lstJsonDiaFestivo" styleClass="p-datatable-sm p-datatable-striped"
        [tableStyle]="{'min-width': '50rem'}" rowGroupMode="subheader" groupRowsBy="id_prestacion">
        <ng-template pTemplate="caption">
          <div class="flex justify-content-start">
            <span class="p-input-icon-left p-ml-auto">
              <p> Colaborador: {{ nombre_colaborador }}</p>
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th>#Servicio</th>
            <th class="text-center">Servicio</th>
            <th class="text-center">Ruta</th>
            <th class="text-center">Puesto</th>
            <th class="text-center">echa</th>
            <th class="text-center">Nombre del Dia</th>
            <th class="text-center">Total</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="groupheader" let-data>
          <tr pRowGroupHeader [ngClass]="{'row-categorias':true}">
            <td colspan="2" pFrozenColumn>
              <span class="font-bold ml-2 texto_categoria" >Percepción</span>
            </td>
            <td colspan="6">
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
          <tr>
            <td>{{ rowIndex +1 }}</td>
            <td class="texto_bk text-center">
              <div *ngIf="data.id_servicio == 1 || data.id_servicio == 2 || data.id_servicio == 3 || data.id_servicio == 4">
                {{data.desc_servicio}} - {{data.desc_confUnidad}}
              </div>
              <div *ngIf="data.id_servicio != 1 && data.id_servicio != 2 && data.id_servicio != 3 && data.id_servicio != 4">
                {{data.desc_servicio}}
              </div>
            </td>
            <td class="text-justify">{{data.desc_ruta }}</td>
            <td class="texto_lila text-center"> {{data.desc_puesto}}   </td>
            <td class="texto_lila text-center">{{data.fecha | date:'dd/MM/yyyy'}}</td>
            <td class="texto_lila text-center">{{data.desc_dia_festivo}}</td>
            <td class="texto_verde text-center texto_resaltado">{{data.imp_dia_festivo | currency}}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</ng-template>
<ng-template pTemplate="footer">
  <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
    (click)="cancelarDiasFestivos();"></button>
</ng-template>
</p-dialog>





  </div>
</div>
