<p-toast position="top-right" key="msj"></p-toast>
<!--<p-messages></p-messages>-->
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="Aceptar" rejectLabel="Cancelar"
  rejectButtonStyleClass="p-button-text">
</p-confirmDialog>

<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
  rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar" [rejectVisible]="false">
</p-confirmDialog>
<div class="d-flex justify-content-center">
  <div class="container-fluid" style="width: 95%;">
      <div class="row mt-5">
          <div class="col-md-12">
              <div class="card card-shadow sinborde">
                  <div class="card-header sinborde" style="background: none; ">
                      <div class="cardicono rounded">
              <i class="pi pi-id-card mt-3" style="font-size: 2rem; color: #fff;"></i>
            </div>
            <header style="left: 11%; top: 5%;">
              <div style="display:inline"> Gestion de</div>

              <div *ngIf="dia.length > 0" style="color:rgb(22, 48, 220);display:inline;" class="ml-3">Prima Vacacional</div>
              <!-- - {{dia|date:'dd/MM/yyyy'}}
                 -->
            </header>
            <div class="row d-flex justify-content-around mt-5">
              <div class="col-md-1"></div>


                  <!-- filtro de semanas -->
                  <div class="col-md-3">
                    <p-dropdown [options]="lstSemanas" autoWidth="false" [style]="{'width':'100%'}"
                      placeholder="Seleccione Semana*" [(ngModel)]="selectedSemana" optionLabel="nom_semana"
                      [showClear]="false">
                      <ng-template let-target pTemplate="item">
                        <div class="ui-helper-clearfix" style="position: relative;height:25px;"
                          pTooltip="{{getTargetDescription(target)}}" tooltipPosition="top">
                          <span style="font-size:14px;float:left;margin-top:4px">{{target.nom_semana}}</span>
                        </div>
                      </ng-template>
                    </p-dropdown>
                    <div *ngIf="selectedSemana!=null">
                      <div class="row">
                        <div  style="color: green;" class="col-sm-6 text-left "><b>Inicio:
                          {{selectedSemana.fec_inicio | date: 'dd/MM/yyyy' }}</b></div>
                        <div style="color: green;"class="col-sm-6 text-right "><b>Fin:
                         {{selectedSemana.fec_fin | date: 'dd/MM/yyyy' }}</b></div>
                      </div>
                    </div>
                  </div>
                  <!-- filtro de ciudades -->
                  <div class="col-md-2  mt-2">
                    <p-dropdown [(ngModel)]="id_ciudad_hm" [options]="lstCiudad" optionLabel="nombre" optionValue="id"
                      placeholder="Filtrar por Ciudad" [showClear]="false" [disabled]="dsblstCiudades">
                    </p-dropdown>
                  </div>

                  <!-- ----------boton principal para el filtro  inicial------------------- -->
                  <div class="col-md-1 ">
                    <button pButton pRipple type="button" (click)="FiltrarFechaDiariaRuta();" label="Filtrar"
                      icon="pi pi-filter" iconPos="left"></button>
                  </div>
                  <!-- ----------boton principal de los reportes en excel------------------- -->


                    <div class=" col-md-3 d-flex justify-content-end ">
                      <div>
                        <div class="col-md-6">
                          <div class="row d-flex justify-content-around">
                            <div class="col-md-9">
                              <p-calendar [(ngModel)]="anio_filtro" view="year" [showIcon]="true" dateFormat="yy"
                                inputId="yearpicker" [style]="{'width':'100%','height':'80%'}"
                                pTooltip="Año de Semanas a Consultar" tooltipPosition="top"></p-calendar>
                            </div>
                            <div class="col-md-3">
                              <button pButton pRipple label="Cargar" class="custom-buttoncargar" icon="pi pi-filter"
                                (click)="cargarListadoDeSemanasAnio_cast(anio_filtro);"
                                pTooltip="Cargar Semanas del Año"></button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-1"></div>

                </div>


            </div>
            <div class="card-content">
              <div class="row mt-3">
                <!-- informacio  -->
                <p-table #dt1 [value]="lstPrimaVacacional" [tableStyle]="{'min-width': '50rem'}"
                  styleClass="p-datatable-sm p-datatable-striped" [scrollable]="true" scrollHeight="480px" scrollDirection="horizontal"
                responsiveLayout="scroll"
                  [globalFilterFields]="['nom_completo','desc_puesto','desc_ciudad_hm','fec_alta','desc_tipo_transporte']"
                  [paginator]="true" [rows]="30" [rowsPerPageOptions]="[10,15,20,30,50]" [showCurrentPageReport]="true"
                  [resizableColumns]="false" currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros"
                  [loading]="loading">
                  <ng-template pTemplate="caption">
                    <div class="row d-flex justify-content-end">

                      <div class="col-md-auto">
                        <span class="p-input-icon-left">
                          <i class="pi pi-search" style="margin-right: 5px;"></i>
                          <input pInputText pTooltip="Buscar por: Nombre del colaborador, Ciudad o Evento" type="text"
                            (input)="applyFilterGlobal($event, 'contains')" placeholder="Buscar"
                            style="margin-left: 5px;" />
                        </span>
                      </div>
                    </div>

                  </ng-template>

                  <!-- tabulado -->
                  <ng-template pTemplate="header">
                    <tr >
                      <th  class="text-center" pSortableColumn="nom_completo" >Colaborador<p-sortIcon field="nom_completo"></p-sortIcon></th>
                      <th  class="text-center" pSortableColumn="id_ciudad_hm" > Ciudad<p-sortIcon field="id_ciudad_hm"></p-sortIcon></th>
                      <th  class="text-center" pSortableColumn="desc_puesto">Puesto<p-sortIcon field="desc_puesto"></p-sortIcon></th>
                      <th  class="text-center" pSortableColumn=" desc_tipo_transporte"Transporte>Transporte<p-sortIcon field="desc_tipo_transporte"></p-sortIcon></th>
                      <th  class="text-center"pSortableColumn="fec_ingreso">Ingreso<p-sortIcon field="fec_ingreso"></p-sortIcon></th>
                      <th  class="text-center">Año</th>
                      <th  class="text-center">SueldoDiario</th>
                      <th  class="text-center">Dias de ley</th>
                      <th  class="text-center">porcentaje de prima</th>
                      <th   pSortableColumn="total_a_pagar" >Total<p-sortIcon field="total_a_pagar"></p-sortIcon></th>
                      <th class="text-center">Estatus</th>
                   <th></th>
                   <th></th>
                    </tr>

                  </ng-template>
                  <ng-template pTemplate="body" let-data let-index>
                    <tr>
                      <td  class="justify-content-left"><b>{{ data.nom_completo }}</b></td>
                      <td  class="text-center">{{ data.desc_ciudad_hm }}</td>
                      <td  class="text-left pl-3" [ngStyle]="{'color': data.desc_puesto ==='DRIVER'? 'rgba(60,  148, 224)' : 'rgba(60, 224, 122  )'}"><b>{{ data.desc_puesto }}</b></td>
                      <td  class="text-center" >{{ data.desc_tipo_transporte }}</td>
                      <td  class="text-center"><b>{{ data.fec_alta | date: 'dd/MM/yyyy' }}</b></td>
                      <td  class="text-center" >{{ data.num_anio }}</td>
                      <td  class=" text-center" style="color: black"><b>{{ data.imp_sueldo_diario | currency: 'USD' }}</b></td>
                      <td  class="text-center"><b>{{ data.num_dias_ley }}</b></td>
                      <td  class="text-center"><b>{{ data.porc_prima }}</b></td>
                      <td  class="text-center" style="color: rgb(22, 48, 220)"><b>{{ data.imp_total | currency: 'USD' }}</b></td>
                      <td class="text-center" [ngStyle]="{'color': data.clv_activo === 1 ? 'green' : 'orange'}">
                        <b>{{ data.clv_activo === 1 ? 'Prima vacacional aprobada' : 'Requiere aprobación' }}</b>
                      </td>
                      <td>
                        <div class="d-flex justify-content-around">
                          <div class="ml-2">
                            <button   [disabled]="data.clv_activo === 1"   class="btn btn-success" pTooltip="Aprobar Prima Vacacional"
                              (click)="AprobarPrima(data)">
                              <i class="pi pi-check"></i>
                            </button>
                          </div>

                        </div>
                      </td>
                      <td>
                        <div class="d-flex justify-content-around">
                          <div class="ml-2">
                            <button  *ngIf="data.clv_activo==1"  class="btn btn-danger" pTooltip="Eliminar Prima"
                              (click)="EliminarRegistro(data)">
                              <i class="pi pi-trash"></i>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="footer">
                    <tr>
                      <td colspan="2" class="text-right" ></td>
                        <td colspan="10" class="text-right" style="color: rgb(169, 12, 12);">Total prima vacacional semanal</td>
                        <td style="color: rgb(13, 164, 13);">{{ imp_total_genral | currency: 'USD'}}</td>
                  </tr>
                </ng-template>
                </p-table>

                <!-- pie de pagina -->
              </div>
              <div class=" mt-3 d-flex justify-content-center">
                <!-- <p class="text-left informativo" style="display: inline; padding-left: 10px; border-left: 5px solid rgb(49, 98, 189); font-weight: normal; font-size: 0.8rem;"><b>ACTIVO</b> el apoyo foráneo se encuentra en proceso</p>
                <p class="text-left informativo"  style="display: inline; padding-left: 10px; border-left: 5px solid rgb(226, 189, 39); font-weight: normal; font-size: 0.8rem;"><b>INACTIVO</b> el apoyo foráneo se ha cancelado, y no se contempla para nomina</p>
                <p class="text-left informativo"  style="display: inline; padding-left: 10px; border-left: 5px solid rgb(28, 163, 35); font-weight: normal; font-size: 0.8rem;">  <b>FINALIZADA</b> el apoyo foráneo se ha finalizado de forma correcta</p>
                <p class="text-left informativo"  style="display: inline; padding-left: 10px; border-left: 5px solid rgb(224, 82, 60); font-weight: normal; font-size: 0.8rem;">  <b>SIN FINALIZAR</b> el apoyo foráneo no ah finalizado</p> -->
                <!-- <p class="text-left informativo"  style="display: inline; padding-left: 10px; border-left: 5px solid rgb(224, 82, 60); font-weight: normal; font-size: 0.8rem;"><b>La pagina no cuenta con opciones de edicion</b> </p> -->

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>



    <!-- p-dialog de reportes excel ------------ -->
    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dbsReportes"
      [style]="{'width': '60vw','height':'620px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
      (onHide)="cancelarModalReportes()">
      <ng-template pTemplate="header">
        Generar Reporte De Excel
      </ng-template>
      <ng-template pTemplate="content">
        <div class="mt-5">
          <hr class="style-two">
          <div class="row">
            <div class="col-md-3">
              <p class="texto-pesado">Reporte De Prima Vacacional</p>
            </div>
            <div class="col-md-2">
              <select class="form-control" style="width: 100%;" [(ngModel)]="num_anio">
                <option *ngFor="let year of years" [value]="year">{{ year }}</option>
              </select>
            </div>
            <div class="col-md-3">
              <p-dropdown [(ngModel)]="id_ciudad_principal" [options]="lstCiudad" optionLabel="nombre" optionValue="id"
              placeholder="Filtrar por Ciudad" [showClear]="false" [disabled]="dsblstCiudades">
            </p-dropdown>
            </div>
            <div class="col-md-3 ml-6">
              <button type="button" class="btn btn-outline-success" style="width: 100%;"
                (click)="exportarExcel(num_anio, id_ciudad_principal)" [disabled]="ButtonExportExcel">
                Exportar <i class="pi pi-file-export"></i>
              </button>
            </div>
          </div>
          <div class="row d-flex justify-content-center">
            <div class="col-2 text-center">
              <i class="pi pi-spin pi-spinner" *ngIf="loading_reporte1"></i>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
          (click)="cancelarModalReportes();"></button>
      </ng-template>
    </p-dialog>
  </div>

