<p-toast position="top-center" key="confirm" (onClose)="cancelarMensajeConfirmacion()" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div class="flex flex-column" style="flex: 1">
      <div class="text-center">
        <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
        <h4>{{message.summary}}</h4>
        <p>{{message.detail}}</p>
      </div>
      <div class="grid p-fluid">
        <div class="col-6">
          <button type="button" pButton (click)="cancelarMensajeConfirmacion()" label="Cancelar"
            class="p-button-secondary"></button>
        </div>
        <div class="col-6">
          <button type="button" pButton (click)="confirmarMensaje()" label="Aceptar" class="p-button-success"></button>
        </div>
      </div>
    </div>
  </ng-template>
</p-toast>
<p-toast position="top-right" key="msj"></p-toast>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="Aceptar" rejectLabel="Cancelar"
  rejectButtonStyleClass="p-button-text">
</p-confirmDialog>
<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
  rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar"
  [rejectVisible]="false"></p-confirmDialog>
<div class="d-flex justify-content-center">
  <div class="container-fluid" style="width: 98%;">
    <div class="row mt-5">
      <div class="col-md-12">
        <div class="card card-shadow sinborde">
          <div class="card-header sinborde" style="background: none; ">
            <div class="cardicono rounded">
              <i class="pi pi-calendar-plus mt-3" style="font-size: 2rem; color: #fff;"></i>
            </div>
            <header style="left: 11%; top: 5%;">
              <div style="display:inline">Gestión de</div>
              <div style="color:rgb(128, 21, 228);display:inline;" class="ml-3">Bono Contratación</div>
            </header>
            <div class="row d-flex justify-content-around mt-4">
              <div class="col-md-8">
                <div class="row d-flex justify-content-around">
                  <div class="col-md-1"></div>
                  <div class="col-md-4">
                    <p-dropdown [options]="lstSemanas" autoWidth="false" [style]="{'width':'100%'}"
                      placeholder="Seleccione Semana*" [(ngModel)]="selectedSemana" optionLabel="nom_semana"
                      [showClear]="true">
                      <ng-template let-target pTemplate="item">
                        <div class="ui-helper-clearfix" style="position: relative;height:25px;"
                          pTooltip="{{getTargetDescription(target)}}" tooltipPosition="top">
                          <span style="font-size:14px;float:left;margin-top:4px">{{target.nom_semana}}</span>
                        </div>
                      </ng-template>
                    </p-dropdown>
                    <div *ngIf="selectedSemana!=null">
                      <div class="row">
                        <div class="col-sm-6 text-left texto_verde">Inicio:
                          {{selectedSemana.fec_inicio | date: 'dd/MM/yyyy' }}</div>
                        <div class="col-sm-6 text-right texto_verde">Fin:
                          {{selectedSemana.fec_fin | date: 'dd/MM/yyyy' }}</div>
                      </div>
                    </div>
                  </div>


                  <div class="row d-flex col-md-3">
                    <p-dropdown [options]="lstCiudadesPrincipales" autoWidth="false" [style]="{'width':'100%'}"
                      placeholder="Seleccione Ciudad*" [(ngModel)]="selectedCiudad" optionLabel="nombre"
                      [showClear]="true">
                    </p-dropdown>
                  </div>
                  <div class="col-md-2">
                    <button pButton pRipple label="Filtrar" class="btn btn-primary" icon="pi pi-filter"
                      (click)="cargarInformacionPersonalPorSemana();" pTooltip="Cargar Información"></button>
                  </div>




                  <div class="col-md-2">
                    <div class="row d-flex justify-content-around">
                      <div class="col-md-9">
                        <p-calendar [(ngModel)]="anio_filtro" view="year" [showIcon]="true" dateFormat="yy"
                          inputId="yearpicker" [style]="{'width':'100%','height':'80%'}"
                          pTooltip="Año de Semanas a Consultar" tooltipPosition="top"></p-calendar>
                      </div>
                      <div class="col-md-3">
                        <button pButton pRipple label="Cargar" class="custom-buttoncargar" icon="pi pi-filter"
                          (click)="cargarListadoDeSemanasAnio_cast(anio_filtro);"
                          pTooltip="Cargar Semanas del Año"></button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div class=" col-md-4 ">
                <div class=" row  d-flex justify-content-around">
                  <button pButton pRipple type="button" (click)="abrirModalReportes();" label="Exportar"
                    icon="pi pi-file-excel" iconPos="left" class="custom-button-excel" [style]="{'width':'20%','height':'100%'}"></button>
                </div>
                <div>

                </div>
              </div>
            </div>
          </div>
          <div class="card-content">
            <div class="row mt-3">
              <p-table #dt1 [value]="lstColaboradoresBono" [tableStyle]="{'min-width': '50rem'}"
                styleClass="p-datatable-sm p-datatable-striped" [scrollable]="true" scrollHeight="480px"
                responsiveLayout="scroll"
                [globalFilterFields]="['nombres','apellido_paterno','apellido_materno','desc_puesto']"
                [paginator]="true" [rows]="10" [rowsPerPageOptions]="[10,15,20,30,50]" [showCurrentPageReport]="true"
                [resizableColumns]="false" currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros"
                [loading]="loading">
                <ng-template pTemplate="caption">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="row d-flex justify-content-start mt-2 mb-3">
                        <div class="col-md-6 ml-3">
                          <button
                            *ngIf="(habilitar_agregar && validarClvOperacion()) || lstColaboradoresBono.length == 0"
                            type="button" class="btn btn-primary form-control" (click)="agregarColaborador();"
                            pTooltip="Agregar colaborador" tooltipPosition="top">
                            Agregar Bono Por Contratacion TA <i class="pi pi-plus-circle"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2"></div>

                    <!-- Aseguramos que el input se ajuste dentro del contenedor flexible -->
                    <div class="col-md-4 d-flex justify-content-end">
                      <span class="p-input-icon-left p-ml-auto">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" size="35" (input)="applyFilterGlobal($event, 'contains')"
                          placeholder="Buscar Nombre, Puesto" class="form-control" />
                      </span>
                    </div>
                  </div>
                </ng-template>

                <ng-template pTemplate="header">
                  <tr>
                    <th pSortableColumn="idx" class="text-center"># Chofer<p-sortIcon field="idx"></p-sortIcon></th>
                    <th pSortableColumn="apellido_paterno">Apellido Paterno<p-sortIcon field="apellido_paterno"></p-sortIcon></th>
                    <th pSortableColumn="apellido_materno">Apellido Materno<p-sortIcon field="apellido_materno"></p-sortIcon></th>
                    <th pSortableColumn="nombres">Nombres<p-sortIcon field="nombres"></p-sortIcon></th>
                    <th pSortableColumn="desc_puesto">Puesto<p-sortIcon field="desc_puesto"></p-sortIcon></th>
                    <th class="text-center" pSortableColumn="nombre_ciudad_hm">Ciudad<p-sortIcon field="nombre_ciudad_hm"></p-sortIcon></th>
                    <th class="text-center" pSortableColumn="nombre_ciudad_hm">Fecha Ingreso<p-sortIcon field="nombre_ciudad_hm"></p-sortIcon></th>
                    <th class="text-center" style="width: 200px; min-width: 200px;">¿Beneficio?</th>
                    <th class="text-center" style="width: 350px; min-width: 350px;">Comentarios por cancelacion</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-data let-index>
                  <tr>
                    <td class="text-center" style="color: rgb(37, 152, 20);"><b> {{data.cod_chofer}}</b></td>
                    <td class="texto_resaltado"> {{data.apellido_paterno}}</td>
                    <td class="texto_resaltado"> {{data.apellido_materno}}</td>
                    <td class="texto_resaltado"> {{data.nombres }}  </td>
                    <td [ngClass]="{'texto_chofer': data.id_puesto==1,'texto_auxiliar':data.id_puesto==2,'texto_bk':data.id_puesto==3}">
                      {{ data.desc_puesto}} </td>
                    <td class="text-center texto_bk"> {{data.nombre_ciudad_hm}}</td>
                    <td class="text-center" style="color: rgb(28, 99, 230);"> <b>{{data.fec_alta}}</b></td>
                    <td style="width: 450 px; min-width: 450px;">
                      <div class="d-flex justify-content-around">
                        <div class="col-4">
                          <p-radioButton name="pizza" value=1 [(ngModel)]="data.id_bono" inputId="Bono"
                            [style]="{'color':'green'}"
                            (ngModelChange)="seleccionarBono(1, data.cod_chofer)"></p-radioButton>
                          <label for="ingredient1" class="ml-2">Agregar Bono</label>
                        </div>
                        <div class="col-4 radio-container">
                          <p-radioButton name="pizza" value="0" [(ngModel)]="data.id_bono" inputId="Ninguno"
                            (ngModelChange)="seleccionarBono(0, data.cod_chofer)"></p-radioButton>
                          <label for="Ninguno" class="ml-2">Ninguno</label>
                        </div>

                      </div>
                    </td>
                    <td class=" align-items-end"> <button type="button" class="btn p-button-text"
                        (click)="verMasPost(data.desc_motivoCambio,data.nom_ult_mod);" pTooltip="ver mas"
                        tooltipPosition="top">
                        <i class="pi pi-envelope texto_chofer"></i>
                      </button> {{data.desc_motivoCambio | slice : 0:80}} </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                  <div class="flex align-items-center justify-content-center mb-2 mt-2">
                    <div class="col-md-3">
                      <button class="custom-button-excel w-100" pTooltip="Guardar Asignación de Bono"
                        (click)="guardarTodo()" >
                        <span class="text-white">Guardar Todo</span>
                        <i class="pi pi-save text-white"></i>
                      </button>
                    </div>
                  </div>
                </ng-template>


              </p-table>
              <!-- pdialog de agregar  -->
              <p-dialog [resizable]="false" [draggable]="false" [scrollTop]="" [(visible)]="dspCliente"
                [style]="{width: '50vw','height':'550px'}" [transitionOptions]="'0ms'"
                (onHide)="cancelarVentanaModal()">
                <ng-template pTemplate="header">
                  {{ texto_operacion}}
                </ng-template>
                <ng-template pTemplate="content">
                  <div class="card-content ">
                    <form class="form_cliente" [formGroup]="agregarBonoContratacionForm"
                      (ngSubmit)="guardarInformacionDeColaborador()">
                      <div class="ml-1">
                        <div class="row mt-1 d-flex justify-content-start">
                          <div class="col-sm-6">
                            <span class="title-light">Agrege la Informacion del Colaborador
                              <i class="pi pi-cog" style="size: 2rem;">
                              </i>
                            </span>
                          </div>
                        </div>
                        <div class="row justify-content-md-around">
                          <div class="col-12">
                            <!-- colaborador  -->
                            <div class="row mt-2">
                              <div class="col-md-4 mt-2 text-center">
                                <label class="col-form-label color-font">
                                  <span class="input-group-addon">
                                    <i class="pi pi-user color-icon mr-3" style="  font-size: 2rem"></i>
                                  </span>
                                  Colaborador
                                </label>
                              </div>
                              <div class="col-md-8">
                                <p-dropdown [options]="lstgetColaboradorCiudad" optionLabel="nom_completo"
                                  formControlName="chofer" autoWidth="false" [showClear]="false"
                                  placeholder="Seleccione Colaborador" [style]="{'width':'80%' , 'margin-top': '20px'}"
                                  optionValue="cod_chofer">
                                </p-dropdown>
                              </div>
                            </div>

                            <!-- Bono -->

                          </div>
                        </div>
                        <!-- --------------fin cambios------------- -->
                        <div class="row d-flex ">
                          <div class="col-md-5  mt-8 justify-content-start">
                            <!-- <p><span style="font-weight: bold; ">Última&nbsp;Modificación:</span></p>
                              <p style="margin-top: 0px;  color: rgb(181, 17, 50);">
                                <b>{{bonoPermaneciaForm.get('nom_usuario_mod')?.value
                                  }}</b>
                              </p> -->
                          </div>
                          <div class="col-md-3 mt-7 justify-content-center">
                            <button class="btn btn-primary form-control" type="submit"
                              [disabled]="!agregarBonoContratacionForm.controls['chofer'].valid" pTooltip="Almacenar informacion"
                              tooltipPosition="right">
                              <span class="text-white">Agregar Bono</span>
                              <i class="pi pi-save text-white"></i>
                            </button>

                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </ng-template>
                <ng-template pTemplate="footer">
                  <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                    (click)="cancelarVentanaModal()"></button>
                </ng-template>
              </p-dialog>
              <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspComentario"
                [style]="{width: '50vw','height':'550px'}" [transitionOptions]="'0ms'"
                (onHide)="cancelarVentanaModal()">
                <ng-template pTemplate="header">
                  {{ texto_operacion }}
                </ng-template>
                <ng-template pTemplate="content">
                  <div class="card-content">
                    <form class="form_cliente" [formGroup]="agregarTextoForm" (ngSubmit)="guardartexto()">
                      <div class="ml-1">
                        <div class="row mt-1 d-flex justify-content-start">
                          <div class="col-sm-6">
                            <span class="title-light">Conteste a detalle las preguntas
                              <i class="pi pi-cog" style="size: 2rem;"></i>
                            </span>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <p><span style="font-weight: bold; ">¿Cual es el motivo del cambio?</span></p>
                        </div>

                        <div class=" col-md-12 mt-3 justify-content-center text-center align-items-center">
                          <textarea formControlName="respuesta" class="form-control" rows="5"
                            placeholder="Escribe tu respuesta aquí" [style]="{'width':'80%', 'margin-top': '20px'}"
                            pTooltip="Escribe tu respuesta" tooltipPosition="top"></textarea>
                        </div>
                        <!-- Información de la última modificación -->
                        <div class="row d-flex ">
                          <div class="col-md-5  mt-6 justify-content-start">
                            <!-- <p><span style="font-weight: bold; ">Última Modificación:</span></p>
                              <p style="margin-top: 0px;  color: rgb(181, 17, 50);">
                                <b>{{ bonoPermaneciaForm.get('nom_usuario_mod')?.value }}</b>
                              </p> -->
                          </div>
                          <div class="col-md-3 mt-7 justify-content-center">
                            <button class="btn btn-primary form-control" type="submit" pTooltip="Almacenar información"
                              tooltipPosition="right">
                              <span class="text-white">Guardar</span>
                              <i class="pi pi-save text-white"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </ng-template>
                <ng-template pTemplate="footer">
                  <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                    (click)="cancelarVentanaModal()"></button>
                </ng-template>
              </p-dialog>

              <!-- fin del imoutn de texto  -->


              <!-- pdialod QUE MUESTRA MENSAJE  -->
              <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspVerComentario"
                [style]="{width: '50vw','height':'550px'}" [transitionOptions]="'0ms'"
                (onHide)="cancelarVentanaModal()">
                <ng-template pTemplate="header">
                  Motivo del Cambio Manual
                </ng-template>
                <ng-template pTemplate="content">
                  <div class="card-content">
                    <form class="form_cliente" [formGroup]="agregarTextoForm">
                      <div class="ml-1">
                        <div class="row mt-1 d-flex justify-content-start">
                          <div class="col-sm-6">
                            <span class="title-light">Esta Pestaña solo es de Visualizacion*
                              <i style="size: 2rem;"></i>
                            </span>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <p><span style="font-weight: bold; ">El motivo del cambio fue que:</span></p>
                        </div>

                        <div class=" col-md-12 mt-3 justify-content-center text-center align-items-center">
                          <textarea formControlName="respuesta" class="form-control" rows="5"
                            [style]="{'width':'80%', 'margin-top': '20px'}" pTooltip="Escribe tu respuesta"
                            tooltipPosition="top"> {{agregarTextoForm.get('desc_motivoCambio')?.value }}</textarea>
                        </div>
                        <!-- Información de la última modificación -->
                        <div class="row d-flex ">
                          <div class="col-md-5  mt-6 justify-content-start">
                            <p><span style="font-weight: bold; ">Última Modificación:</span></p>
                            <p style="margin-top: 0px;  color: rgb(181, 17, 50);">
                              <b>{{ agregarTextoForm.get('nom_ult_mod')?.value }}</b>
                            </p>
                          </div>
                          <div class="col-md-3 mt-7 justify-content-center">

                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </ng-template>
                <ng-template pTemplate="footer">
                  <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                    (click)="cancelarVentanaModal()"></button>
                </ng-template>
              </p-dialog>



      <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dbsReportes"
      [style]="{'width': '60vw','height':'620px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
      (onHide)="cancelarModalReportes()">
      <ng-template pTemplate="header">
        Generar Reporte De Excel
      </ng-template>
      <ng-template pTemplate="content">
        <div class="mt-5">
          <hr class="style-two">
          <div class="row">
            <div style="color: #3161C4" class="col-md-3 mt-4">
              <p  class="texto-pesado"><b>Reporte de bono por contratacion</b></p>
            </div>
            <div class="col-md-3">
              <p-dropdown [options]="lstSemanas" autoWidth="false" [style]="{'width':'100%'}"
                placeholder="Seleccione Semana*" [(ngModel)]="selectedSemanaReporte" optionLabel="nom_semana"
                [showClear]="false">
                <ng-template let-target pTemplate="item">
                  <div class="ui-helper-clearfix" style="position: relative;height:25px;"
                    pTooltip="{{getTargetDescription(target)}}" tooltipPosition="top">
                    <span style="font-size:14px;float:left;margin-top:4px">{{target.nom_semana}}</span>
                  </div>
                </ng-template>
              </p-dropdown>
              <div *ngIf="selectedSemanaReporte!=null">
                <div class="row">
                  <div style="color: #2fbd2f" class="col-sm-6 text-left "><b>Inicio:
                      {{selectedSemanaReporte.fec_inicio | date: 'dd/MM/yyyy' }}</b></div>
                  <div style="color:  #2fbd2f" class="col-sm-6 text-right "><b>Fin:
                      {{selectedSemanaReporte.fec_fin | date: 'dd/MM/yyyy' }}</b></div>
                </div>
              </div>
            </div>

            <div class="col-md-3">
              <p-dropdown [(ngModel)]="id_ciudad_principalrpt" [options]="lstCiudadesPrincipales" optionLabel="nombre"  [disabled]="dsblstCiudades" optionValue="id"
                placeholder="Filtrar por Ciudad" [showClear]="false" >
              </p-dropdown>
            </div>

            <div class="col-md-2 ml-6">
              <button type="button" [disabled]="deshabilitarbtnexportar" class="custom-button-excel"
                style="width: 100%;" (click)="exportarExcel()" [disabled]="ButtonExportExcel">
                Exportar <i class="pi pi-file-export"></i>
              </button>
            </div>
          </div>
          <div class="row d-flex justify-content-center">
            <div class="col-2 text-center">
              <i class="pi pi-spin pi-spinner" *ngIf="loading_reporte1"></i>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
          (click)="cancelarModalReportes();"></button>
      </ng-template>
    </p-dialog>


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
