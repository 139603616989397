

import { Component, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { ConfirmEventType, ConfirmationService, MessageService } from 'primeng/api';
import { IChoferDisponible } from '../models/choferdisponible';
import { IMes } from '../models/mes';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ICiudadHM } from '../models/ciudadhm';
import ICredencialUsuario from '../models/credencial';
import { AuthService } from '../services/auth.service';
import { ISemanasHappyMile } from '../models/semanas';
import { SemanaService } from '../services/semana.service';
import { ICiudadPrincipalHM } from '../models/ciudadprincipal';
import { ApoyoService } from '../services/apoyo.service';
import { IApoyo } from '../models/apoyo';
import { ICatApoyo } from '../models/catapoyos';
import { InombreCompletoChoferciudadprincipal } from '../models/nombrecompletociudadprincipal';
import { IDiasApoyo } from '../models/diasapoyo';
import { IPostApoyo } from '../models/postapoyo';
import * as saveAs from 'file-saver';
import { SalarioPromedioService } from '../services/salariopromedio.service';
import { IsalarioPromedio } from '../models/salariopromedio';
import { CiudadService } from '../services/ciudad.service';
import { CiudadhmService } from '../services/ciudadhm.service';
@Component({
  selector: 'app-salariopromediosemanal',
  templateUrl: './salariopromediosemanal.component.html',
  styleUrls: ['./salariopromediosemanal.component.scss']
})
export class SalariopromediosemanalComponent {



  habilitaragregar: boolean = false;
  dspAgregar: boolean = false;
  loading: boolean = false;
  dspVerMas: boolean = false;

  num_anio: number = 0;
  years: number[] = [];
  id_ciudad_hm: number = 0;
  id_semana: number = 0;
  dsblstCiudades: boolean = true;
  imp_total: number = 0;
  num_dias: number = 0;
  des_puesto: string = '';

  nombre_chofer: string = '';
  imp_salario_base: number = 0;
  imp_salario_ims: number = 0;

  porcentaje: number = 0;

  mostrarbtnLimpiarFiltro: boolean = false;
  dsblstColaboradores: boolean = false;
  dspDesgloce: boolean = false;
  dbsReportes: boolean = false;
  porcentaje_capturado: number = 0;
  clv_activo: number = 0;
  id_editar: number = 0;


  anio_filtro: any;

  meses: IMes[] = [
    { num_mes: 0, nombre: "TODOS LOS MESES" },
    { num_mes: 1, nombre: "Enero" },
    { num_mes: 2, nombre: "Febrero" },
    { num_mes: 3, nombre: "Marzo" },
    { num_mes: 4, nombre: "Abril" },
    { num_mes: 5, nombre: "Mayo" },
    { num_mes: 6, nombre: "Junio" },
    { num_mes: 7, nombre: "Julio" },
    { num_mes: 8, nombre: "Agosto" },
    { num_mes: 9, nombre: "Septiembre" },
    { num_mes: 10, nombre: "Octubre" },
    { num_mes: 11, nombre: "Noviembre" },
    { num_mes: 12, nombre: "Diciembre" }];



  reactiveApoyos: FormGroup = new FormGroup({
    idx: new FormControl(0),
    cod_chofer: new FormControl(0, [Validators.min(1),Validators.required]),
    id_apoyo: new FormControl(0, [Validators.min(1), Validators.required]),
    fec_inicio: new FormControl(null, [Validators.required]),
    fec_fin: new FormControl(null, [Validators.required]),
    num_duracion: new FormControl(0),
    id_ciudad_hm: new FormControl(0),
    imp_fijo: new FormControl(0),
    desc_motivo_apoyo: new FormControl('', [Validators.maxLength(500), Validators.required]),
    clv_activo: new FormControl(0),
    id_acotecimiento: new FormControl(0),
    selectedSemana: new FormControl([], [Validators.min(1), Validators.required]),
  });


  user: ICredencialUsuario = {};
  @ViewChild('dt1') dt!: Table;
  lstCiudad: ICiudadHM[] = [];
  lstCiudadrpt: ICiudadHM[] = [];
  lstChoferes: InombreCompletoChoferciudadprincipal[] = [];
  lstsalarioPromedio: IsalarioPromedio[] = [];
  lstChoferesDisponibles: IChoferDisponible[] = [];
  lstSemanas: ISemanasHappyMile[] = [];

  uploadedFiles: any[] = [];
  selectedSemana: ISemanasHappyMile = {
    idx: 0,
    id_semana: 0,
    nom_semana: '',
    anio: 0,
    fec_inicio: '',
    fec_fin: '',
    clv_activo: 0
  };
  selectedSemanaReporte: ISemanasHappyMile = {
    idx: 0,
    id_semana: 0,
    nom_semana: '',
    anio: 0,
    fec_inicio: '',
    fec_fin: '',
    clv_activo: 0
  };




  constructor(private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private auth: AuthService,
    private semanaService: SemanaService,
    private apoyoservice: ApoyoService,
    private CiudadhmService: CiudadhmService,
    private salariopromedioservice : SalarioPromedioService,


  ) {
    this.initializeYears();
    this.num_anio = new Date().getFullYear();
    this.cargarInformacionUsuario();
  }
  ngOnInit(): void {

      this.cargaListaChoferes();
      this.cargarListadoDeSemanasAnio();
      this.cargarCiudades();
      this.inicializarFechas();



  }

  cargarInformacionUsuario() {
    this.user = this.auth.user;
  }

  //iniciliza los años para el filtro
  initializeYears(): void {
    const currentYear = new Date().getFullYear();
    for (let year = currentYear; year >= currentYear - 5; year--) {
      this.years.push(year);
    }
  }
  cargarListadoDeSemanasAnio_cast(num_anio: any) {
    this.loading=true;
    let anio_cast: any = 0;
    if (num_anio instanceof Date) {
      // will execute
      anio_cast = num_anio.getFullYear();
    } else {
      anio_cast = num_anio;
    }
    this.lstsalarioPromedio=[];
    this.lstSemanas = [];
    this.semanaService.listarSemanas(anio_cast).subscribe((resp) => {
      this.lstSemanas = resp;
      this.loading=false
      setTimeout(() => {
      },
        600);
    },
      (error) => {
        this.loading=false;
        this.mostrarDialogoInformativoSinInformacion('<b>Error al cargar Semanas HM</b>. Contacte al Administrador.');
      });
  }
  inicializarFechas() {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    // this.anio = getYear;
    this.anio_filtro = getYear;
  }

  cargarListadoDeSemanasAnio() {
    this.lstSemanas = [];
    this.semanaService.listarSemanas(this.num_anio).subscribe((resp) => {
      this.lstSemanas = resp;
      setTimeout(() => {
      },
        600);
    },
      (error) => {
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> al cargar Información. Contacte al Administrador.');
      });
  }
  getMesNombre(numMes: number): string {
    const mes = this.meses.find(m => m.num_mes === numMes);
    return mes ? mes.nombre : 'Mes no encontrado';
  }
  // metodos para eñ filtro para fechas
  getTargetDescription(ruta: ISemanasHappyMile) {
    let fec_inicio = new Date(ruta.fec_inicio);
    let fec_fin = new Date(ruta.fec_fin);
    return this.formatearFechas(fec_inicio) + ' | ' + this.formatearFechas(fec_fin);
  }
  formatearFechas(fecha: Date): string {
    let fecha_Cast = '';
    var getYear = new Date(fecha).toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date(fecha).toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date(fecha).toLocaleDateString('es-MX', { day: '2-digit' });
    fecha_Cast = getDay + '/' + getMonth + '/' + getYear;
    return fecha_Cast;
  }








  cargarCiudades() {
    this.lstCiudad =[];
    this.dsblstCiudades = true;
    this.loading = true;
    this.CiudadhmService.getListadoCiudades().subscribe((resp) => {
      this.lstCiudad =[...resp] ;
      this.lstCiudadrpt =[...resp];
      setTimeout(() => {
        this.loading = false;
        this.dsblstCiudades = false;
      }, 600);
    },
    (error) => {
      this.loading = false;
      this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Ciudades');
    });
  }

  //carga la lista incial de choferes
  cargaListaChoferes() {
    this.dsblstColaboradores = true;
    this.lstChoferes = [];
    this.apoyoservice.getListadoChoferesGeneral().subscribe((resp) => {
      this.lstChoferes = resp;
      setTimeout(() => {
        this.dsblstColaboradores = false;
      }, 100);
    },
      (error) => {
        this.dsblstColaboradores = false;
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Colaboradores');
      });
  }


  // -----------filtra y carga la informacion ----
  FiltrarInformacion() {
    this.habilitaragregar = true;
    this.loading = true;
    if (this.id_ciudad_hm == null || typeof this.id_ciudad_hm === 'undefined' || !this.selectedSemana || this.selectedSemana.id_semana == null || typeof this.selectedSemana.id_semana === 'undefined') {
      this.mostrarDialogoInformativoSinInformacion('<b>Informativo</b> Debe de seleccionar una semana para poder filtrar');
      this.loading = false;
    } else {
      // this.mostraragregar= true;
      this.salariopromedioservice.getListadoSalarioPromedio(this.selectedSemana.anio, this.selectedSemana.id_semana, this.id_ciudad_hm).subscribe(
        (resp) => {
          this.lstsalarioPromedio = resp;
          if (this.lstsalarioPromedio.length === 0) {
            this.loading = false;
            this.mostrarDialogoInformativoSinInformacionsinadmi('<b>No Existe</b> Registros para la fecha seleccionado');
          } else {

            setTimeout(() => {
              this.loading = false;
            }, 800);

          }
        },
        (error) => {
          this.mostrarDialogoInformativoSinInformacionsinadmi('<b>ERROR</b> en Cargar Movimientos ');
          this.loading = false;
        }
      );
    }
  }















  mostrarDialogoInformativoSinInformacionsinadmi(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario;
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }

  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario;
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }





  abrirModalReportes() {
    this.dbsReportes = true;

  }

  deshabilitarbtnexportar: boolean = false;
  ButtonExportExcel: boolean = false;
  loading_reporte1: boolean = false;
  id_ciudad_hmrpt: ICiudadHM= { id: 0,
    nombre: '',
    clave_estado: '',
    id_ciudad_principal: 0,
    clave_tipoTransporte: 0};

  exportarExcel() {
    this.deshabilitarbtnexportar = true;

    this.salariopromedioservice.getrptSalarioPromedio(this.selectedSemanaReporte.anio, this.selectedSemanaReporte.id_semana, this.id_ciudad_hmrpt.id,)
      .subscribe((resp: any[]) => {
        if (resp.length === 0 || typeof resp === 'undefined') {
          this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> no existen registros para los parametros enviados');

          this.deshabilitarbtnexportar = false;
        } else {
          import("xlsx").then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(resp);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
            const excelBuffer: any = xlsx.write(workbook, {
              bookType: "xlsx",
              type: "array"
            });
            // Reporte_salario_promedio_monterreytp_semana_48_año_2024_exportar_2024-12-06.xlsx

            this.saveAsExcelFile(excelBuffer, "Reporte_salario_promedio" + '_' + this.id_ciudad_hmrpt.nombre + '_'+ 'semana' + '_' + this.selectedSemanaReporte.id_semana + '_' + 'año' + '_' + this.selectedSemanaReporte.anio);
          });
          setTimeout(() => {
            this.ButtonExportExcel = false;
            this.deshabilitarbtnexportar = false;
            this.cancelarModalReportes()
          }, 1000);
        }
      },
        (error) => {
          this.ButtonExportExcel = false;
          this.deshabilitarbtnexportar = false;

          this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: 'Error en emitir el reporte de Estatus de Facturación.' });
        });
    //}


  }




  cancelarModalReportes() {
    this.id_ciudad_hmrpt = { id: 0,
      nombre: '',
      clave_estado: '',
      id_ciudad_principal: 0,
      clave_tipoTransporte: 0};;
    this.dbsReportes=false;
    this.selectedSemanaReporte = {
      idx: 0,
      id_semana: 0,
      nom_semana: '',
      anio: 0,
      fec_inicio: '',
      fec_fin: '',
      clv_activo: 0
    };
  }



  saveAsExcelFile(buffer: any, fileName: string): void {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName + "_exportar_" + dateFormat + EXCEL_EXTENSION);
  }


  /*Filtro General para la informacion de tabla*/
  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  onUpload(event: { files: any; }) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }

    this.messageService.add({ severity: 'info', summary: 'File Uploaded', detail: '' });
  }
}
