<p-toast position="top-right" key="msj"></p-toast>

<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="Aceptar" rejectLabel="Cancelar"
  rejectButtonStyleClass="p-button-text">
</p-confirmDialog>

<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
  rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar" [rejectVisible]="false">

</p-confirmDialog>
<div class="container-fluid" style="border-color: #1e5a9c; border: 5px ; width: 95%;">
  <div class="row">
    <div class="col-md-12 mt-2">
      <div class="card card-shadow sinborde">
        <div class="card-header sinborde" style="background: none; ">
          <div class="cardicono rounded">
            <i class="pi pi-chart-bar mt-3" style="font-size: 2rem; color: #fff;"></i>
          </div>
          <header style="left: 11%; top: 5%;">Salario
            <div style="color:#1e5a9c; display: inline;">Promedio Semanal</div>
          </header>
          <div class="row d-flex justify-content-end">
            <div class="col-md-12">

              <!-- -------------fecha del filtro---------------- -->
              <div class="form-group row mt-5">


                <div class="row col-md-4 mt-2">
                  <div class="col-md-4">
                  </div>
                  <div class="col-md-8 mt-1">
                    <p-dropdown [options]="lstSemanas" autoWidth="false" [style]="{'width':'100%'}"
                      placeholder="Seleccione Semana*" [(ngModel)]="selectedSemana" optionLabel="nom_semana"
                      [showClear]="false">
                      <ng-template let-target pTemplate="item">
                        <div class="ui-helper-clearfix" style="position: relative; height: 25px;"
                          pTooltip="{{getTargetDescription(target)}}" tooltipPosition="top">
                          <span style="font-size: 14px; float: left; margin-top: 4px">{{target.nom_semana}}</span>
                        </div>
                      </ng-template>
                    </p-dropdown>
                    <div *ngIf="selectedSemana != null">
                      <div class="row">
                        <div style="color: #4fbe4f;" class="col-sm-6 text-left"><b>Inicio:
                            {{selectedSemana.fec_inicio | date: 'dd/MM/yyyy'}}</b></div>
                        <div style="color: #4fbe4f;" class="col-sm-6 text-right"><b>Fin:
                            {{selectedSemana.fec_fin | date: 'dd/MM/yyyy'}}</b></div>
                      </div>
                    </div>
                  </div>
                </div>






                <!-- filtro de ciudades -->
                <div class="col-md-2 mr-3 mt-2">
                  <p-dropdown [options]="lstCiudadrpt" [(ngModel)]="id_ciudad_hm"  optionLabel="nombre"
                    optionValue="id" placeholder="Filtrar por Ciudad" [showClear]="false">
                  </p-dropdown>
                </div>

                <!-- ----------boton principal para el filtro  inicial------------------- -->
                <div class="col-md-1">
                  <button pButton pRipple type="button" (click)="FiltrarInformacion();" label="Filtrar"
                    icon="pi pi-filter" iconPos="left" class="custom-button"></button>
                </div>
                <!-- ----------boton principal de los reportes en excel------------------- -->

                <div class=" ml-4 col-md-3 d-flex justify-content-end">
                  <div>
                    <div class="col-md-6">
                      <div class="row d-flex justify-content-around">
                        <div class="col-md-9">
                          <p-calendar [(ngModel)]="anio_filtro" view="year" [showIcon]="true" dateFormat="yy"
                            inputId="yearpicker" [style]="{'width':'100%','height':'80%'}"
                            pTooltip="Año de Semanas a Consultar" tooltipPosition="top"></p-calendar>
                        </div>
                        <div class="col-md-3">
                          <button pButton pRipple label="Cargar" class="custom-buttoncargar"icon="pi pi-filter"
                            (click)="cargarListadoDeSemanasAnio_cast(anio_filtro);"
                            pTooltip="Cargar Semanas del Año"></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-1">
                  <button pButton pRipple type="button" (click)="abrirModalReportes();" label="Exportar"
                    icon="pi pi-file-excel" iconPos="left" class="custom-button-excel"></button>
                </div>

              </div>

            </div>
          </div>

        </div>
        <div class="card-body">
          <p-table #dt1 [value]="lstsalarioPromedio" [scrollable]="true" scrollHeight="flex" scrollDirection="both"
         [paginator]="true" [rows]="150" [rowsPerPageOptions]="[50,75,100,200]" [showCurrentPageReport]="true"
         currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros"
         selectionMode="single"
         [globalFilterFields]="['nom_chofer','desc_ciudad_hm','desc_puesto']" [loading]="loading">


          <ng-template pTemplate="caption">
              <div class="flex justify-content-between">
                  <div>
                      <!-- Opcionalmente puedes agregar el botón para nuevo -->
                  </div>
                  <div class="p-input-icon-left p-ml-auto" *ngIf="habilitaragregar">
                      <i class="pi pi-search"></i>
                      <input pInputText type="text" size="40" (input)="applyFilterGlobal($event, 'contains')" placeholder="Buscar" />
                  </div>
                  <div class="p-input-icon-left p-ml-auto" *ngIf="!habilitaragregar">
                      <i class="pi pi-search"></i>
                      <input pInputText type="text" size="40" (input)="applyFilterGlobal($event, 'contains')" placeholder="Buscar" />
                  </div>
              </div>
          </ng-template>

          <ng-template pTemplate="header">
              <tr>
                  <!-- Columnas congeladas -->
                  <th pFrozenColumn class="text-center" style="min-width:100px; max-width: 100px;">#Chofer</th>
                  <th pFrozenColumn class="text-start" pSortableColumn="nom_chofer" style="min-width:300px; max-width: 300px;">
                      Colaborador <p-sortIcon field="nom_chofer"></p-sortIcon>
                  </th>
                  <th pFrozenColumn class="text-center" pSortableColumn="desc_puesto" style="min-width:100px; max-width: 100px;">
                      Puesto <p-sortIcon field="desc_puesto"></p-sortIcon>
                  </th>
                  <th pFrozenColumn class="text-center" pSortableColumn="id_ciudad_hm" style="min-width:100px; max-width: 100px;">
                      Ciudad <p-sortIcon field="id_ciudad_hm"></p-sortIcon>
                  </th>

                  <!-- Otras columnas no congeladas -->
                  <th class="text-center" pSortableColumn="id_semana">#Semana<p-sortIcon field="id_semana"></p-sortIcon></th>
                  <th class="text-center" pSortableColumn="fec_alta">Ingreso<p-sortIcon field="fec_alta"></p-sortIcon></th>
                  <th class="text-center" pSortableColumn="fec_alta">Antigüedad<p-sortIcon field="fec_alta"></p-sortIcon></th>
                  <th class="text-center" pSortableColumn="num_rutas_promedio_local">Num.Rutas Locales <p-sortIcon field="num_rutas_promedio_local"></p-sortIcon></th>
                  <th class="text-center" pSortableColumn="imp_promedio_local">Imp.Rutas Locales <p-sortIcon field="imp_promedio_local"></p-sortIcon></th>
                  <th class="text-center" pSortableColumn="imp_extension_ruta_local">Extensión local<p-sortIcon field="imp_extension_ruta_local"></p-sortIcon></th>
                  <th class="text-center" pSortableColumn="imp_viaticos_locales">Viáticos locales<p-sortIcon field="imp_viaticos_locales"></p-sortIcon></th>
                  <!-- <th class="text-center">hospedaje local</th> -->
                  <th class="text-center" pSortableColumn="num_rutas_promedio_foraneo">Num.Rutas Foraneas <p-sortIcon field="num_rutas_promedio_foraneo"></p-sortIcon></th>
                  <th class="text-center" pSortableColumn="imp_promedio_foraneo">Imp.Rutas Foraneas <p-sortIcon field="imp_promedio_foraneo"></p-sortIcon></th>
                  <th class="text-center"  pSortableColumn="imp_extension_ruta_foranea">Extensión foranea<p-sortIcon field="imp_extension_ruta_foranea"></p-sortIcon></th>
                  <th class="text-center"  pSortableColumn="imp_viaticos_foraneos">Viáticos foraneo<p-sortIcon field="imp_viaticos_foraneos"></p-sortIcon></th>
                  <!-- <th class="text-center">hospedaje foraneo</th> -->
                  <th class="text-center"  pSortableColumn="num_asistencias_pagadas">Asistencia Pagada<p-sortIcon field="num_asistencias_pagadas"></p-sortIcon></th>
                  <th class="text-center"  pSortableColumn="imp_asistencia_pagada">Imp.Asistencia<p-sortIcon field="imp_asistencia_pagada"></p-sortIcon></th>
                  <th class="text-center" pSortableColumn="total_rutas_semana"  style="min-width:150px; max-width: 150px;">Total de Rutas Semana<p-sortIcon field="total_rutas_semana"></p-sortIcon></th>
                  <th class="text-center" pSortableColumn="total_importe" >Total Importe<p-sortIcon field="total_importe"></p-sortIcon></th>

                  <th class="text-center"  pSortableColumn="total_viaticos">Total Viáticos<p-sortIcon field="total_viaticos"></p-sortIcon></th>
                  <th class="text-center"  >Comentario</th>
                </tr>
          </ng-template>

          <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
              <tr>
                  <!-- Primer columna congelada -->
                  <td class="text-center" pFrozenColumn [frozen]="true" style="min-width:100px ;max-width: 100px; ">
                      <b>{{data.cod_chofer}}</b>
                  </td>
                  <td class="text-left"  pFrozenColumn [frozen]="true" style="min-width:300px ;max-width: 300px; ">
                      <b>{{data.nom_chofer}}</b>
                  </td>
                  <td class="text-center"  pFrozenColumn [frozen]="true" style="min-width:100px ;max-width: 100px; ">
                      <b>{{data.desc_puesto}}</b>
                  </td>
                  <td class="text-center" pFrozenColumn [frozen]="true" style="min-width:100px ;max-width: 100px; ">
                      {{data.desc_ciudad_hm}}
                  </td>

                  <!-- Otras columnas -->
                  <td class="text-center">{{data.id_semana}}</td>

                  <td class="text-center">{{data.fec_alta | date: 'dd/MM/yyyy'}}</td>
                  <td class="text-center" style="color: #70c0ec;"><b>{{data.antiguedad_en_anios}}</b></td>
                  <td class="text-center" style="color: #FF6F61;"><b>{{data.num_rutas_promedio_local}}</b></td>
                  <td class="text-center" style="color: #1e5a9c"><b>{{data.imp_promedio_local | currency: 'USD'}}</b></td>
                  <td class="text-center">{{data.imp_extension_ruta_local | currency: 'USD'}}</td>
                  <td class="text-center">{{data.imp_viaticos_locales | currency: 'USD'}}</td>
                  <!-- <td class="text-center">{{data.hospedaje_extension_ruta_local}}</td> -->

                  <td class="text-center" style="color: #FF6F61;"><b>{{data.num_rutas_promedio_foraneo}}</b></td>
                  <td class="text-center" style="color: #1e5a9c"><b>{{data.imp_promedio_foraneo | currency: 'USD'}}</b></td>
                  <td class="text-center">{{data.imp_extension_ruta_foranea | currency: 'USD'}}</td>
                  <td class="text-center">{{data.imp_viaticos_foraneos | currency: 'USD'}}</td>
                  <!-- <td class="text-center">{{data.hospedaje_extension_ruta_foranea | currency: 'USD'}}</td> -->

                  <td class="text-center" style="color: #FF6F61;"><b>{{data.num_asistencias_pagadas }}</b></td>
                  <td class="text-center" style="color: #1e5a9c"><b>{{data.imp_asistencia_pagada | currency: 'USD'}}</b></td>
                  <td class="text-center" style="color: #FF6F61; min-width:150px; max-width: 150px;" ><b>{{data.total_rutas_semana}}</b></td>
                  <td class="text-center" style="color: #000000;"> <b>{{data.total_importe| currency: 'USD'}}</b></td>
                  <td class="text-center" style="color: #000000;"><b>{{data.total_viaticos | currency: 'USD'}}</b></td>
                  <td class="text-center">{{data.desc_comentario}}</td>

              </tr>
          </ng-template>

      </p-table>



        </div>
      </div>
    </div>
  </div>





<!--
  pdialog de reporte -->

  <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dbsReportes"
      [style]="{'width': '60vw','height':'620px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
      (onHide)="cancelarModalReportes()">
      <ng-template pTemplate="header">
        Generar Reporte De Excel
      </ng-template>
      <ng-template pTemplate="content">
        <div class="mt-5">
          <hr class="style-two">
          <div class="row">
            <div style="color: #1e5a9c" class="col-md-3 mt-4">
              <p  class="texto-pesado"><b>Reporte de salario</b></p>
            </div>
            <div class="col-md-3">
              <p-dropdown [options]="lstSemanas" autoWidth="false" [style]="{'width':'100%'}"
                placeholder="Seleccione Semana*" [(ngModel)]="selectedSemanaReporte" optionLabel="nom_semana"
                [showClear]="false">
                <ng-template let-target pTemplate="item">
                  <div class="ui-helper-clearfix" style="position: relative;height:25px;"
                    pTooltip="{{getTargetDescription(target)}}" tooltipPosition="top">
                    <span style="font-size:14px;float:left;margin-top:4px">{{target.nom_semana}}</span>
                  </div>
                </ng-template>
              </p-dropdown>
              <div *ngIf="selectedSemanaReporte!=null">
                <div class="row">
                  <div style="color: #4fbe4f;" class="col-sm-6 text-left "><b>Inicio:
                      {{selectedSemanaReporte.fec_inicio | date: 'dd/MM/yyyy' }}</b></div>
                  <div style="color:  #4fbe4f;" class="col-sm-6 text-right "><b>Fin:
                      {{selectedSemanaReporte.fec_fin | date: 'dd/MM/yyyy' }}</b></div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <p-dropdown  [options]="lstCiudad" [(ngModel)]="id_ciudad_hmrpt" optionLabel="nombre"
                placeholder="Filtrar por Ciudad" [showClear]="false" [disabled]="dsblstCiudades">
              </p-dropdown>
            </div>

            <div class="col-md-2 ml-6">
              <button type="button" [disabled]="deshabilitarbtnexportar" class="custom-button-excel"
                style="width: 100%;" (click)="exportarExcel()" [disabled]="ButtonExportExcel">
                Exportar <i class="pi pi-file-export"></i>
              </button>
            </div>
          </div>
          <div class="row d-flex justify-content-center">
            <div class="col-2 text-center">
              <i class="pi pi-spin pi-spinner" *ngIf="loading_reporte1"></i>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
          (click)="cancelarModalReportes();"></button>
      </ng-template>
    </p-dialog>


</div>

